import { TransactionService as ConvercusTransactionService, Configuration, TransactionData, } from "shared/lib/v2/apis/transactions";
import { HttpClient } from "@angular/common/http";
import { UtilsService, ConfigService, EnvironmentService } from "shared/lib/common/services";
import * as i0 from "@angular/core";
import * as i1 from "../../../common/services/config/config.service";
import * as i2 from "../../../common/services/utils/utils.service";
import * as i3 from "@angular/common/http";
import * as i4 from "../../apis/transactions/variables";
import * as i5 from "../../apis/transactions/configuration";
import * as i6 from "../../../common/services/environment/environment.service";
export class TransactionService extends ConvercusTransactionService {
    constructor(config, utils, httpClient, basePath, configuration, environmentService) {
        super(httpClient, environmentService.getVariable("API_V2"), configuration);
        this.config = config;
        this.utils = utils;
        this.httpClient = httpClient;
        this.environmentService = environmentService;
    }
    createTransaction(interactionId, body) {
        const store = this.config.getMountingConfig().store;
        return super.createTransaction(interactionId, Object.assign({ externalId: `${Number(body.amount).toFixed(2)}_${store ? store.vatId : store}_${this.utils.getReceiptFormatDate(new Date())}` }, body));
    }
}
TransactionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TransactionService_Factory() { return new TransactionService(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.UtilsService), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.BASE_PATH, 8), i0.ɵɵinject(i5.Configuration, 8), i0.ɵɵinject(i6.EnvironmentService)); }, token: TransactionService, providedIn: "root" });

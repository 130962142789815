import { SharedRoutes } from "shared/lib/common/enums";
import { RoutingService } from "../../services";
import * as i0 from "@angular/core";
import * as i1 from "../../services/routing/routing.service";
export class ComesFromLoginGuard {
    constructor(routing) {
        this.routing = routing;
    }
    canActivate(next, state) {
        if (this.routing.isActiveUrl(SharedRoutes.mounting) || this.routing.isActiveUrl(SharedRoutes.login) || this.routing.isActiveUrl("")) {
            return true;
        }
        localStorage.clear();
        this.routing.goForward([SharedRoutes.login]);
        return false;
    }
}
ComesFromLoginGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ComesFromLoginGuard_Factory() { return new ComesFromLoginGuard(i0.ɵɵinject(i1.RoutingService)); }, token: ComesFromLoginGuard, providedIn: "root" });

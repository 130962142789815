<ion-content scroll-y="false">
  <div class="page-wrapper admin">
    <shared-close (tap)="goToScreenSaver()"></shared-close>
    <div class="admin__content">
      <shared-revenue-module
        [revenueMode]="revenueMode"
        [revenueRounding]="revenueRounding"
        [pointsRounding]="pointsRounding"
        [promotions]="promotions"
        [mode]="'extended'"
        (backEvent)="goToQrScanner($event)"
      ></shared-revenue-module>
      <p class="admin__content__version">
        {{ releaseVersion }}
        <br />
      </p>
    </div>

    <ion-button class="button--dark admin__button-configurations" color="text" fill="clear" (tap)="goToConfigurations()">
      <i-settings class="admin__button-configurations__icon"></i-settings>
      {{ translate._("SETTINGS") }}
    </ion-button>
  </div>
</ion-content>

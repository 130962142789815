var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { TranslateService, ToastService } from "shared/lib/common/services";
import { ModalController, NavParams } from "@ionic/angular";
export class CheckPinModalPage {
    constructor(navParams, modal, translate, toast) {
        this.navParams = navParams;
        this.modal = modal;
        this.translate = translate;
        this.toast = toast;
    }
    ngOnInit() {
        this.params = this.navParams.data;
    }
    checkPin(pin) {
        return __awaiter(this, void 0, void 0, function* () {
            const isCorrectPin = pin === this.params.pin;
            this.dismiss(isCorrectPin);
            if (!isCorrectPin)
                this.toast.show(this.translate._("INVALID_PIN"), "error");
        });
    }
    dismiss(correctPin = false) {
        this.modal.dismiss(correctPin);
    }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { SharedRoutes } from "shared/lib/common/enums";
import { AdminService, EnvironmentService, RoutingService, ToastService, TranslateService, UserService } from "shared/lib/common/services";
import { Platform } from "@ionic/angular";
import { ConfigService } from "shared/lib/common/services";
export class AdminPage {
    constructor(translate, platform, admin, user, config, toast, routing, environment) {
        this.translate = translate;
        this.platform = platform;
        this.admin = admin;
        this.user = user;
        this.config = config;
        this.toast = toast;
        this.routing = routing;
        this.environment = environment;
    }
    ngOnInit() {
        this.releaseVersion = this.environment.getVariable("RELEASE");
    }
    ionViewDidEnter() {
        return __awaiter(this, void 0, void 0, function* () {
            this.user.removeUser();
            yield this.getAndParseActionsDefinition();
            yield this.getRoundingFactors();
            this.getRevenueMode();
        });
    }
    goToConfigurations() {
        this.routing.goForward([SharedRoutes.configurations]);
    }
    goToScreenSaver() {
        this.routing.goForward([SharedRoutes.screenSaver]);
    }
    goToQrScanner(queryParams) {
        this.routing.goForward([SharedRoutes.qrScanner], {
            queryParams,
        });
    }
    getAndParseActionsDefinition() {
        return __awaiter(this, void 0, void 0, function* () {
            const promotions = yield this.admin.getPromotions();
            this.promotions = Object.keys(promotions)
                .filter(key => promotions[key])
                .map(key => promotions[key]);
        });
    }
    getRoundingFactors() {
        return __awaiter(this, void 0, void 0, function* () {
            const resp = yield this.admin.getAllClients();
            if (resp.ok === true) {
                const client = resp.response.find(c => String(c.clientId) === this.config.getMountingConfig().clientId);
                this.pointsRounding = client.pointsRoundingCode;
                this.revenueRounding = client.revenueRoundingCode;
            }
            else {
                this.toast.show(this.translate._(resp.error.message), "error");
            }
        });
    }
    getRevenueMode() {
        const resp = this.admin.getClientStore();
        if (resp.ok === true) {
            this.revenueMode = resp.response.revenueModuleMode;
        }
        else {
            this.toast.show(this.translate._(resp.error.message), "error");
        }
    }
}

import { Injectable, Inject, Optional } from "@angular/core";
import {
  AccountService as ConvercusAccountService,
  Configuration,
  BASE_PATH,
  TransactionData,
  LineItemData,
} from "shared/lib/v2/apis/accounts";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Revenue } from "shared/lib/v2/pages";
import { IIdentifierType } from "shared/lib/common/interfaces";
import { UtilsService, ConfigService, EnvironmentService } from "shared/lib/common/services";

@Injectable({ providedIn: "root" })
export class AccountService extends ConvercusAccountService {
  constructor(
    private config: ConfigService,
    private utils: UtilsService,
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    protected environmentService: EnvironmentService,
  ) {
    super(httpClient, environmentService.getVariable("API_V2"), configuration);
  }

  public incentivateTransaction(
    accountId: string,
    interactionId: string,
    transactionId: string,
    idType?: IIdentifierType,
  ): Observable<any> {
    return super.incentivateTransaction(accountId, interactionId, transactionId, idType || "CARDCODE");
  }

  public createTransaction(
    accountId: string,
    interactionId: string,
    externalId?: string,
    idType?: IIdentifierType,
    body?: TransactionData,
  ): Observable<any> {
    const store = this.config.getMountingConfig().store;
    return super.addTransaction(accountId, interactionId, idType || "CARDCODE", {
      externalId:
        externalId || `${Number(body.amount).toFixed(2)}_${store ? store.vatId : store}_${this.utils.getReceiptFormatDate(new Date())}`,
      ...body,
    });
  }

  public createTransactionPrev(
    accountId: string,
    interactionId: string,
    idType?: IIdentifierType,
    body?: TransactionData,
  ): Observable<any> {
    return super.createTransactionPreview(accountId, interactionId, idType || "CARDCODE", body);
  }

  public getTransactionData(revenues: Revenue[], type: TransactionData.TransactionTypeEnum): TransactionData {
    return {
      amount: this.get(revenues, "money"),
      transactionType: type,
      lineItems: revenues.map((r, i) => {
        return {
          extendedAmount: r.money,
          itemID: r.id,
          merchandiseGroupCode: r.groupCode || undefined,
          merchandiseSubGroupCode: r.subGroupCode || undefined,
          description: r.name,
          sequenceNumber: i + 1,
          type: r.money > 0 ? LineItemData.TypeEnum.SALE : LineItemData.TypeEnum.RETURN,
        };
      }),
    };
  }

  public filterRevenues(revenues: Revenue[], type: TransactionData.TransactionTypeEnum): Revenue[] {
    return revenues.filter(r => (type === "EARNTRANSACTION" ? !r.unitType : r.unitType === type));
  }

  public get(revenues: Revenue[], type: "money" | "points"): number {
    let total = 0;
    (revenues || []).forEach(r => {
      total += r[type];
    });
    return total;
  }
}

<ion-header class="ion-no-border text-input-modal__header">
  <ion-toolbar class="text-input-modal__header__toolbar">
    <ion-title class="text-input-modal__header__toolbar__title">{{ titleText }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="text-input-modal__content">
  <form [formGroup]="form" class="text-input-modal__content__form">
    <ion-input autocomplete="off" formControlName="text" type="text" class="text-input-modal__content__form__input">
    </ion-input>
  </form>
</ion-content>
<ion-footer class="ion-no-border text-input-modal__footer">
  <div class="text-input-modal__footer__toolbar">
    <shared-button (clickEvent)="modal.dismiss()" color="dark" fill="clear">{{ cancelText }}</shared-button>
    <shared-button (clickEvent)="modal.dismiss(form.value.text)" color="primary" fill="solid" [disabled]="form.invalid">
      {{ confirmText }}</shared-button>
  </div>
</ion-footer>
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";

export interface TextInputModalPageInputs {
  titleText: string;
  cancelText: string;
  confirmText: string;
}

@Component({
  selector: "text-input-modal-page",
  templateUrl: "./text-input-modal.page.html",
  styleUrls: ["./text-input-modal.page.scss"],
})
export class TextInputModalPage implements OnInit {
  @Input() public titleText: string;
  @Input() public cancelText: string;
  @Input() public confirmText: string;

  public form: FormGroup;

  constructor(public modal: ModalController) {}

  public ngOnInit(): void {
    this.form = new FormGroup({
      text: new FormControl(null, [Validators.required]),
    });
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./summary.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "../summary-footer/summary-footer.component.ngfactory";
import * as i6 from "../summary-footer/summary-footer.component";
import * as i7 from "../../../common/services/translate/translate.service";
import * as i8 from "./summary.component";
var styles_SummaryComponent = [i0.styles];
var RenderType_SummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SummaryComponent, data: {} });
export { RenderType_SummaryComponent as RenderType_SummaryComponent };
function View_SummaryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [["class", "summary__empty"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.t._("NO_COUPONS"); _ck(_v, 0, 0, currVal_0); }); }
function View_SummaryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "h1", [["class", "summary__list__header"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.t._("ITEMS"); _ck(_v, 0, 0, currVal_0); }); }
function View_SummaryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "summary__list__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "summary__list__item__info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "h3", [["class", "summary__list__item__info__title"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "p", [["class", "summary__list__item__info__text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "ion-icon", [["class", "summary__list__item__delete"], ["name", "trash"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeItem(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(5, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_2 = "trash"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((((((_v.context.$implicit == null) ? null : _v.context.$implicit.money) + " \u20AC | ") + ((_v.context.$implicit == null) ? null : _v.context.$implicit.points)) + " ") + _co.t._("POINTS_TO_EXCHANGE")); _ck(_v, 3, 0, currVal_1); }); }
function View_SummaryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "summary__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SummaryComponent_4)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.revenues; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "summary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "h1", [["class", "summary__header"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SummaryComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SummaryComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SummaryComponent_3)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "shared-v2-summary-footer", [["class", "summary__footer"]], null, [[null, "clickEvent"], [null, "checkOutEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = (_co.clickEvent.emit() !== false);
        ad = (pd_0 && ad);
    } if (("checkOutEvent" === en)) {
        var pd_1 = (_co.checkOutEvent.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_SummaryFooterComponent_0, i5.RenderType_SummaryFooterComponent)), i1.ɵdid(9, 49152, null, 0, i6.SummaryFooterComponent, [i7.TranslateService], { revenue: [0, "revenue"], points: [1, "points"], buttonDirection: [2, "buttonDirection"], decimalRevenue: [3, "decimalRevenue"] }, { clickEvent: "clickEvent", checkOutEvent: "checkOutEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = !((_co.revenues == null) ? null : _co.revenues.length); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_co.revenues == null) ? null : _co.revenues.length); _ck(_v, 5, 0, currVal_2); var currVal_3 = ((_co.revenues == null) ? null : _co.revenues.length); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.revenue; var currVal_5 = _co.points; var currVal_6 = _co.buttonDirection; var currVal_7 = _co.decimalRevenue; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.t._("SUMMARY"); _ck(_v, 1, 0, currVal_0); }); }
export function View_SummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-v2-summary", [], null, null, null, View_SummaryComponent_0, RenderType_SummaryComponent)), i1.ɵdid(1, 49152, null, 0, i8.SummaryComponent, [i7.TranslateService], null, null)], null, null); }
var SummaryComponentNgFactory = i1.ɵccf("shared-v2-summary", i8.SummaryComponent, View_SummaryComponent_Host_0, { revenue: "revenue", points: "points", revenues: "revenues", buttonDirection: "buttonDirection", decimalRevenue: "decimalRevenue" }, { clickEvent: "clickEvent", checkOutEvent: "checkOutEvent", removeItemEvent: "removeItemEvent" }, []);
export { SummaryComponentNgFactory as SummaryComponentNgFactory };

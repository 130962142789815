var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Subject } from "rxjs";
import * as io from "socket.io-client";
import { AdminService } from "shared/lib/common/services/admin/admin.service";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { EnvironmentService } from "../environment/environment.service";
import * as i0 from "@angular/core";
import * as i1 from "../admin/admin.service";
import * as i2 from "../config/config.service";
import * as i3 from "../environment/environment.service";
export class PosConnectionService {
    constructor(admin, config, environmentService) {
        this.admin = admin;
        this.config = config;
        this.environmentService = environmentService;
        this.subject = new Subject();
    }
    connectToSocket(deviceKey) {
        return __awaiter(this, void 0, void 0, function* () {
            if (deviceKey && !this.socket) {
                const response = yield this.admin.reLogin();
                if (response.ok === true) {
                    const mountingConfig = this.config.getMountingConfig();
                    this.socket = io(this.environmentService.getVariable("API_SOCKET"), {
                        transports: ["websocket"],
                        query: {
                            deviceKey,
                            token: response.response,
                            deviceInformation: mountingConfig.deviceInformation,
                            workstationId: mountingConfig.workstationId,
                            version: this.environmentService.getVariable("RELEASE"),
                            ak: this.admin.getApiKey(),
                            ac: this.config.getOrganization(),
                            appInteractionId: mountingConfig.appInteractionId,
                            cashInteractionId: mountingConfig.cashInteractionId,
                        },
                    });
                    this.socket.on("reconnect_attempt", () => (this.socket.io.opts.transports = ["websocket", "polling"]));
                    this.socket.on("event", (data) => this.subject.next({ type: "REVENUE", data }));
                    this.socket.on("revenue", (data) => this.subject.next({ type: "REVENUE", data }));
                    this.socket.on("unmount", () => this.subject.next({ type: "UNMOUNT" }));
                    this.socket.on("connect", () => this.subject.next({ type: "CONNECT" }));
                    this.socket.on("disconnect", (reason) => {
                        this.subject.next({ type: "DISCONNECT" });
                        if (reason === "io server disconnect")
                            this.socket.connect();
                    });
                }
            }
        });
    }
    closeSocketConnection() {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
    }
    getObservable() {
        return this.subject.asObservable();
    }
}
PosConnectionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PosConnectionService_Factory() { return new PosConnectionService(i0.ɵɵinject(i1.AdminService), i0.ɵɵinject(i2.ConfigService), i0.ɵɵinject(i3.EnvironmentService)); }, token: PosConnectionService, providedIn: "root" });

import { ILanguage } from "shared/lib/common/i18n/translate";

export type IPosMode = "STD" | "STD_REG" | "REG" | "CAS" | "SER" | "INF";

export type ILanguageKey = "German" | "English";

export type APIS = "ARTS" | "API" | "NEW_API" | "MOUNTING_API" | "SOCKET_API" | "SELFSERVICE" | "REGISTER" | "API_V2";

export type IPosModeKey = "Standard" | "Standard register always" | "Register only" | "Cashier screen" | "Service station" | "Info station";

export type ICameraPosition = "RC" | "BC" | "TR";

export const posModesMap: { [key: string]: IPosMode } = {
  Standard: "STD",
  "Standard register always": "STD_REG",
  "Register only": "REG",
  "Cashier screen": "CAS",
  "Service station": "SER",
  "Info station": "INF",
};

export const posModes = ["Standard", "Service station", "Cashier screen"];

export const languagesMap: { [key: string]: ILanguage } = {
  German: "de",
  English: "en",
};

export const languages = ["German", "English"];

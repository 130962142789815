import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { VERSION } from "shared/lib/common/enums";
import {
  IBookPointsParams,
  IGetCouponsResponse,
  IGetCouponsParams,
  IGetRewardsResponse,
  IGetRewardsParams,
  IGetUserDetailsResponse,
  IGetUserDetailsParams,
  IGetUserHistoryParams,
  IGetUserHistoryResponse,
  ILoginParams,
  IRedeemCouponParams,
  IRedeemRewardParams,
  IResponseP4M,
  IRevenuePointsParams,
  IRevenuePointsResponse,
  IReadBalanceParams,
  IBalance,
  ICheckInParams,
  ICheckInResponse,
  IReadUserParams,
  IUserV2,
} from "shared/lib/common/interfaces";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { P4mV1Service } from "shared/lib/common/services/p4m/p4m-v1/p4m-v1.service";
import { P4mV2Service } from "shared/lib/common/services/p4m/p4m-v2/p4m-v2.service";
import { SentryService } from "shared/lib/common/services/sentry/sentry.service";
import { TranslateService } from "shared/lib/common/services/translate/translate.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { IResponse } from "shared/lib/common/interfaces";
import { EnvironmentService } from "../environment/environment.service";

@Injectable({ providedIn: "root" })
export class P4mService {
  // tslint:disable: typedef
  public assignCoupon = this.v1.assignCoupon;
  public deviceManagementLogin = this.v1.deviceManagementLogin;
  public getAllClients = this.v1.getAllClients;
  public getStoresOfClient = this.v1.getStoresOfClient;
  public downloadDisplayAds = this.v1.downloadDisplayAds;
  public unMountDevice = this.v1.unMountDevice;
  public loginToGetApiKey = this.v1.loginToGetApiKey;
  public getPermissionUser = this.v1.getPermissionUser;
  public extendedRevenuePointsManually = this.v1.extendedRevenuePointsManually;
  public getActionDefinitions = this.v1.getActionDefinitions;
  public register = this.v1.register;
  public updateProfileExtendedRegistration = this.v1.updateProfileExtendedRegistration;
  public readProgramPartners = this.v2.readProgramPartners;
  public readPartnerStores = this.v2.readPartnerStores;
  public parseResponseV2 = this.v2.parseResponseV2;
  public mountDevice = this.v1.mountDevice;
  public getPassword = this.v1.getPassword;
  public setPassword = this.v1.setPassword;
  public getUser = this.v1.getUser;
  public setUser = this.v1.setUser;
  public getToken = this.v1.getToken;
  public setToken = this.v1.setToken;
  public arts = this.v1.arts;
  public getArtsXml = this.v1.getArtsXml;
  public parseResponseV1 = this.v1.parseResponseV1;
  public parseError = this.v1.parseError;
  public parseBalance = this.v1.parseBalance;
  public ensureValidTimestamp = this.v1.ensureValidTimestamp;
  public parseCoupons = this.v2.parseCoupons;
  public getCouponi18nField = this.v2.getCouponi18nField;
  public parseFiles = this.v2.parseFiles;
  public isUnlocked = this.v2.isUnlocked;
  public getApiV1Url = this.v1.getApiV1Url;
  public getCouponKeys = this.v1.getCouponKeys;

  constructor(
    private http: HttpClient,
    private sentry: SentryService,
    private config: ConfigService,
    private utils: UtilsService,
    private translate: TranslateService,
    private environment: EnvironmentService,
    private v1: P4mV1Service,
    private v2: P4mV2Service,
  ) {}

  public getUserDetails(params: IGetUserDetailsParams): Promise<IResponse<IGetUserDetailsResponse>> {
    return this.config.getVersion("getUserDetails") === VERSION.v1 ? this.v1.getUserDetails(params) : this.v2.getUserDetails(params);
  }
  public checkIn(params: ICheckInParams): Promise<IResponse<ICheckInResponse>> {
    return this.config.getVersion("checkIn") === VERSION.v1 ? this.v1.checkIn(params) : this.v2.checkIn(params);
  }
  public getUserHistory(params: IGetUserHistoryParams): Promise<IResponse<IGetUserHistoryResponse>> {
    return this.config.getVersion("getUserHistory") === VERSION.v1 ? this.v1.getUserHistory(params) : this.v2.getUserHistory(params);
  }
  public getCoupons(params: IGetCouponsParams): Promise<IResponse<IGetCouponsResponse>> {
    return this.config.getVersion("getCoupons") === VERSION.v1 ? this.v1.getCoupons(params) : this.v2.getCoupons(params);
  }
  public getRewards(params: IGetRewardsParams): Promise<IResponse<IGetRewardsResponse>> {
    return this.config.getVersion("getRewards") === VERSION.v1 ? this.v1.getRewards(params) : this.v2.getRewards(params);
  }
  public bookPoints(params: IBookPointsParams): Promise<IResponse<any>> {
    return this.config.getVersion("bookPoints") === VERSION.v1 ? this.v1.bookPoints(params) : this.v2.bookPoints(params);
  }
  public revenuePoints(params: IRevenuePointsParams): Promise<IResponse<IRevenuePointsResponse>> {
    return this.config.getVersion("revenuePoints") === VERSION.v1 ? this.v1.revenuePoints(params) : this.v2.revenuePoints(params);
  }
  public redeemCoupon(params: IRedeemCouponParams): Promise<IResponse<IResponseP4M>> {
    return this.config.getVersion("redeemCoupon") === VERSION.v1 ? this.v1.redeemCoupon(params) : this.v2.redeemCoupon(params);
  }
  public redeemReward(params: IRedeemRewardParams): Promise<IResponse<IResponseP4M>> {
    return this.config.getVersion("redeemReward") === VERSION.v1 ? this.v1.redeemReward(params) : this.v2.redeemReward(params);
  }
  public login(params: ILoginParams): Promise<IResponse<string>> {
    return this.config.getVersion("login") === VERSION.v1 ? this.v1.login(params) : this.v2.login(params);
  }
  public readAccountBalance(params: IReadBalanceParams): Promise<IResponse<IBalance>> {
    return this.config.getVersion("readAccountBalance") === VERSION.v1
      ? this.v1.readAccountBalance(params)
      : this.v2.readAccountBalance(params);
  }
  public async readUser(params: IReadUserParams): Promise<IResponse<IUserV2>> {
    return this.config.getVersion("readUser") === VERSION.v1 ? this.v1.readUser(params) : this.v2.readUser(params);
  }
}

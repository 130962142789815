<ion-content scroll-y="false">
  <div class="page-wrapper calculator revenue-calculator">
    <shared-close class="calculator__desktop-close" (tap)="modal.dismiss()"></shared-close>
    <h2 class="calculator__title" [innerHTML]="t._('CALCULATOR_TITLE')"></h2>
    <div class="calculator__revenue-item">
      <span class="calculator__revenue-item__text" [innerHTML]="item?.name"></span>
    </div>
    <div class="calculator__content">
      <div class="calculator__content__top">
        <h1 class="calculator__content__top__value" [innerHTML]="value | number: decimalRevenue ? '1.2-2' : '1.0-0'"></h1>
        <p class=" calculator__content__top__text" [innerHTML]="t._('CALCULATOR_TOTAL')"></p>
      </div>
      <shared-keypad
        [buttons]="['7', '8', '9', '4', '5', '6', '1', '2', '3', '0', '00', 'plusMinus']"
        (event)="press($event)"
      ></shared-keypad>
      <div class="calculator__content__extra">
        <div class="calculator__content__extra__value">
          <h1 class="calculator__content__extra__value__value" [innerHTML]="value | number: decimalRevenue ? '1.2-2' : '1.0-0'"></h1>
          <p class=" calculator__content__extra__value__text" [innerHTML]="t._('CALCULATOR_TOTAL')"></p>
        </div>
        <ion-button (click)="press('delete')" class="calculator__content__extra__delete">
          <i-delete></i-delete>
        </ion-button>
        <ion-button (click)="press('c')" class="calculator__content__extra__reset" [innerHTML]="'C'"> </ion-button>
        <ion-button
          [disabled]="!value"
          (click)="modal.dismiss(value)"
          class="calculator__content__extra__submit"
          [innerHTML]="t._('CALCULATOR_ADD')"
        ></ion-button>
      </div>
    </div>
    <div (tap)="modal.dismiss()" class="calculator__mobile-close">
      <p class="calculator__mobile-close__text" [innerHTML]="t._('CANCEL')"></p>
    </div>
  </div>
</ion-content>

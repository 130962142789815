import { Subject } from "rxjs";
import { ConfigService } from "../config/config.service";
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
export class InactivityService {
    constructor(config) {
        this.config = config;
        this.timeout = this.config.inactivityTime;
        this.timeout$ = new Subject();
        this.incrementTime = this.incrementTime.bind(this);
        window.addEventListener("keyup", () => this.resetTimer());
        window.addEventListener("click", () => this.resetTimer());
        window.addEventListener("wheel", () => this.resetTimer());
    }
    startWatching() {
        this.resetTimer();
        if (this.idleInterval)
            this.stopWatch();
        this.idleTime = 0;
        this.incrementTime();
        this.idleInterval = setInterval(this.incrementTime, 1000);
    }
    resetTimer() {
        this.idleTime = 0;
    }
    stopWatch() {
        clearInterval(this.idleInterval);
    }
    onTimeout() {
        return this.timeout$;
    }
    incrementTime() {
        this.idleTime = this.idleTime + 1;
        if (this.idleTime >= this.timeout) {
            this.resetTimer();
            this.timeout$.next();
        }
    }
}
InactivityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InactivityService_Factory() { return new InactivityService(i0.ɵɵinject(i1.ConfigService)); }, token: InactivityService, providedIn: "root" });

<div class="select" [class.select--disable]="disabled" [class.select--error]="error">
  <ion-select
    [value]="value"
    [disabled]="disabled"
    class="select--item"
    [class.select--item--disable]="disabled"
    [interfaceOptions]="{ header: translate._('SELECT_AN_OPTION') }"
    interface="action-sheet"
    [placeholder]="'-- ' + translate._('SELECT_AN_OPTION') + ' --'"
    (ionChange)="select($event)"
  >
    <ion-select-option
      *ngFor="let option of options; trackBy: trackId"
      [value]="option[optionId] || option"
      [innerHTML]="option[optionDisplay] || option"
    >
    </ion-select-option>
  </ion-select>
</div>

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as i0 from "@angular/core";
export var EVENT_LISTENERS;
(function (EVENT_LISTENERS) {
    EVENT_LISTENERS["ON_LOAD_EPSON_SCRIPT"] = "ON_LOAD_EPSON_SCRIPT";
    EVENT_LISTENERS["ON_LOAD_EPSON_JSZUTILS"] = "ON_LOAD_EPSON_JSZUTILS";
})(EVENT_LISTENERS || (EVENT_LISTENERS = {}));
const THIRD_PARTY_SCRIPTS = [
    { path: "/assets/epos-2.12.0.js", async: true, onloadEvent: new CustomEvent(EVENT_LISTENERS.ON_LOAD_EPSON_SCRIPT) },
    { path: "/assets/jszip-utils.js", async: false, onloadEvent: new CustomEvent(EVENT_LISTENERS.ON_LOAD_EPSON_JSZUTILS) },
];
export class ThirdPartyScriptsService {
    constructor() { }
    addThirdPartyScripts() {
        return __awaiter(this, void 0, void 0, function* () {
            for (const script of THIRD_PARTY_SCRIPTS) {
                yield this.loadScript(script);
            }
        });
    }
    loadScript({ path, async, onloadEvent }) {
        return new Promise(resolve => {
            const script = document.createElement("script");
            script.setAttribute("src", path);
            script.setAttribute("type", "text/javascript");
            script.onload = () => {
                document.dispatchEvent(onloadEvent);
                resolve();
            };
            document.head.appendChild(script);
            if (async)
                resolve();
        });
    }
}
ThirdPartyScriptsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ThirdPartyScriptsService_Factory() { return new ThirdPartyScriptsService(); }, token: ThirdPartyScriptsService, providedIn: "root" });

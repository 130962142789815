import { SharedRoutes } from "shared/lib/common/enums";
import { RoutingService, TranslateService, UserService } from "shared/lib/common/services";
import * as i0 from "@angular/core";
import * as i1 from "../../services/user/user.service";
import * as i2 from "../../services/translate/translate.service";
import * as i3 from "../../services/routing/routing.service";
export class IsUserScannedGuard {
    constructor(user, translate, routing) {
        this.user = user;
        this.translate = translate;
        this.routing = routing;
    }
    canActivate(next, state) {
        if (this.user.getUser()) {
            return true;
        }
        else {
            this.routing.goForward([SharedRoutes.screenSaver]);
            return false;
        }
    }
}
IsUserScannedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IsUserScannedGuard_Factory() { return new IsUserScannedGuard(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.RoutingService)); }, token: IsUserScannedGuard, providedIn: "root" });

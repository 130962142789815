<ion-content class="qr-scan page-wrapper" [class.--hide-background]="scannerEnabled && isOnCapacitor"
    [class.--capacitor]="isOnCapacitor" scroll-y="false">
    <div class="qr-scan__content --{{ cameraPosition }}">
        <div class="qr-scan__content__scanner-wrapper --{{ cameraPosition }}">
            <shared-qrscanner *ngIf="!isOnCapacitor && showCamera" [scannerEnabled]="scannerEnabled"
                [device]="cameraSelected" (torchCompatible)="logs('torchCompatible', $event)"
                (hasDevices)="logs('hasDevices', $event)" (scanError)="logs('scanError', $event)"
                (scanComplete)="logs('scanComplete', $event)" (permissionResponse)="logs('permissionResponse', $event)"
                (camerasFound)="cameraFounds($event)" (scanSuccess)="scannerCode($event, false)"
                (scanFailure)="scannerCode($event, true)" [cameraPosition]="cameraPosition"
                class="qr-scan__content__scanner-wrapper__scanner"></shared-qrscanner>
            <div class="qr-scan__content__scanner-wrapper__frame --{{ cameraPosition }}">
                <shared-scan-frame [loading]="loading"></shared-scan-frame>
            </div>
        </div>
        <div class="qr-scan__content__wrapper --{{ cameraPosition }}">
            <h1 class="qr-scan__content__wrapper__title">{{ translate._("LOYALTY_CARD") }}</h1>
            <shared-devices *ngIf="params?.type === 'burn'"
                class="qr-scan__content__wrapper__image --{{ cameraPosition }}">
            </shared-devices>
            <div *ngIf="params?.type === 'earn'" class="qr-scan__content__wrapper__earn --{{ cameraPosition }}">
                <p class="qr-scan__content__wrapper__earn__points">
                    {{ params["totalPoints"] || params["manualPoints"] || params["acumulateParams"] | number: "":translate.getSessionLanguage() }}
                </p>
                <p class="qr-scan__content__wrapper__earn__text">{{ translate._("ACCUMULATE_POINTS") }}</p>
            </div>
        </div>
    </div>
    <shared-close (tap)="back()"></shared-close>
</ion-content>

import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";

export interface ShowAlertOptions {
  message: string;
  buttons?: { role?: string | "cancel"; text: string; handler?: () => void }[];
  backdropDismiss?: boolean;
  cssClass?: string | string[];
}

@Injectable({ providedIn: "root" })
export class AlertService {
  constructor(private readonly alert: AlertController) {}

  public async showAlert(opts: ShowAlertOptions): Promise<boolean> {
    const { message, buttons, backdropDismiss = false, cssClass } = opts;
    const alert: HTMLIonAlertElement = await this.alert.create({ message, buttons, backdropDismiss, cssClass });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role ? role.toLowerCase() !== "cancel" : true;
  }

  public async dismissAlert(): Promise<boolean> {
    const alert = await this.alert.getTop();
    return alert ? alert.dismiss() : false;
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IResponse } from "shared/lib/common/interfaces";
import { SharedRoutes, LOCAL_STORAGE } from "shared/lib/common/enums";
import { IMountingConfig } from "shared/lib/common/interfaces";
import { UtilsService, ConfigService, EnvironmentService, SentryService } from "shared/lib/common/services";
import { UAParser } from "ua-parser-js";
import * as uuid from "uuid";
import { Image, Mounting, Profile } from "shared/lib/v2/apis/mounting-service";
import { Observable } from "rxjs";
import { Store } from "../../apis/programs";
import { ProgramService } from "../program/program.service";
import { MountingsService } from "../mounting/mounting.service";
import { DevicesService } from "../devices/devices.service";
import { Severity } from "@sentry/browser";

@Injectable({ providedIn: "root" })
export class DeviceMountingService {
  private mounting: Mounting;
  private deviceImages: Image[];

  constructor(
    private programService: ProgramService,
    private mountingService: MountingsService,
    private deviceService: DevicesService,
    private utils: UtilsService,
    private httpClient: HttpClient,
    private config: ConfigService,
    private environment: EnvironmentService,
    private sentry: SentryService,
  ) {}

  get profile(): Profile {
    if (!this.mounting) this.unmount();
    return this.mounting ? this.mounting.profile : null;
  }

  get images(): Image[] {
    return this.deviceImages;
  }

  set mountingId(mountingId: string) {
    localStorage.setItem(LOCAL_STORAGE.MOUNTING_ID, this.utils.encrypt(mountingId.toString()));
  }

  get mountingId(): string {
    return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MOUNTING_ID));
  }

  set deviceKey(deviceKey: string) {
    localStorage.setItem(LOCAL_STORAGE.DEVICE_KEY, this.utils.encrypt(deviceKey));
  }

  get deviceKey(): string {
    return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.DEVICE_KEY));
  }

  public mountingControllerCreateOneBase(mounting: Mounting): Observable<any> {
    return this.mountingService.mountingControllerCreateOneBase(mounting);
  }

  public async unmount(): Promise<void> {
    try {
      await this.mountingService
        .mountingControllerUpdateOne(this.mountingId, {
          deviceKey: null,
          organization: this.config.getOrganization(),
        })
        .toPromise();
      await this.deviceService.deviceControllerDeleteOne(this.deviceKey).toPromise();
      this.sentry.handleError(
        {
          status: 200,
          message: JSON.stringify({
            ...this.config.getMountingConfig(),
            messageType: "",
            messageData: "",
            function: "unmount",
            file: "shared/lib/v2/services/device-mounting",
            unmountDatetime: new Date().toISOString(),
          }),
        },
        { level: Severity.Log },
      );
      localStorage.clear();
      window.location.href = SharedRoutes.login;
    } catch (error) {}
  }

  public async mount(appInteractionId: string, deviceInformation: string = ""): Promise<IResponse<Mounting, any>> {
    try {
      const mounting = await this.getMountingFromApi(appInteractionId);
      if (mounting) {
        if (!mounting.deviceKey) {
          const parser = new UAParser();
          const { deviceKey } = await this.deviceService
            .deviceControllerCreateOneBase({
              os: parser.getOS().name,
              osVersion: parser.getOS().version,
              browser: `${parser.getBrowser().name} ${parser.getBrowser().version}`,
              deviceInformation,
              connected: false,
              version: this.environment.getVariable("RELEASE"),
              deviceKey: uuid.v1(),
              organization: this.config.getOrganization(),
            })
            .toPromise();
          const resp = await this.mountingService
            .mountingControllerUpdateOne(mounting.id, { deviceKey, organization: this.config.getOrganization() })
            .toPromise();
          this.mountingId = mounting.id;
          this.mounting = mounting;
          this.deviceKey = deviceKey;
          return {
            ok: true,
            response: { ...mounting, deviceKey, appInteractionId: resp.appInteractionId, cashInteractionId: resp.cashInteractionId },
          };
        } else return { ok: false, error: "MOUNTING_IN_USE" };
      } else return { ok: false, error: "MOUNTING_NOT_FOUND" };
    } catch (error) {
      return { ok: false, error: error };
    }
  }

  public async getMounting(): Promise<IResponse<Mounting, string>> {
    try {
      const mounting = await this.getMountingFromApi();
      if (mounting) {
        this.mountingId = mounting.id;
        this.mounting = mounting;
        this.deviceKey = mounting.deviceKey;
        return { ok: true, response: mounting };
      } else return { ok: false, error: "MOUNTING_NOT_FOUND" };
    } catch (error) {
      return { ok: false, error: error.message || error };
    }
  }

  public async parseMountingConfigV1ToV2(mounting: Mounting): Promise<IMountingConfig> {
    let store: Store;
    if (mounting.store) {
      store = await this.programService.getStore(mounting.partnerId, mounting.organization, mounting.store).toPromise();
    }
    return {
      workstationId: mounting.appInteractionId,
      deviceId: null,
      deviceKey: mounting.deviceKey,
      cameraPosition: mounting.profile.cameraPosition,
      clientId: "1234",
      clientName: null,
      posMode: mounting.profile.mode,
      storeId: Number(mounting.store),
      storeName: null,
      deviceInformation: null,
      partner: { id: mounting.partnerId, name: "", status: "" },
      store,
      legalName: null,
      appInteractionId: mounting.appInteractionId,
      cashInteractionId: mounting.cashInteractionId,
    };
  }

  private async getMountingFromApi(appInteractionId?: string): Promise<Mounting> {
    let baseUrl = `${this.environment.getVariable("API_MOUNTING")}/mounting-service/mountings`;
    let params = null;
    let mounting: Mounting;

    if (this.mountingId) {
      baseUrl += `/${this.mountingId}`;
    }

    if (appInteractionId) {
      params = { filter: `appInteractionId||$eq||${appInteractionId}` };
    }

    mounting = appInteractionId
      ? (await this.httpClient.get<Mounting[]>(baseUrl, { params }).toPromise())[0]
      : await this.httpClient.get<Mounting>(baseUrl, { params }).toPromise();

    if (mounting && mounting.profileId) mounting.profile = await this.getMountingProfile(mounting.profileId);
    if (mounting) this.deviceImages = await this.getDeviceImages(mounting);
    return mounting;
  }

  private getMountingProfile(profileId: string): Promise<Profile> {
    return this.httpClient
      .get<Profile>(`${this.environment.getVariable("API_MOUNTING")}/mounting-service/profiles/${profileId}`)
      .toPromise();
  }

  private getDeviceImages(mounting: Mounting): Promise<Image[]> {
    const or = [];
    if (mounting.organization) or.push(`programs.id||$eq||${mounting.organization}`);
    if (mounting.partnerId) or.push(`partners.id||$eq||${mounting.partnerId}`);
    if (mounting.store) or.push(`stores.id||$eq||${mounting.store}`);
    if (mounting.profileId) or.push(`profiles.id||$eq||${mounting.profileId}`);
    return this.httpClient
      .get<Image[]>(`${this.environment.getVariable("API_MOUNTING")}/mounting-service/images`, {
        params: { join: ["programs", "partners", "stores", "profiles"], or },
      })
      .toPromise();
  }
}

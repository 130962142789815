var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as JSZip from "jszip";
import { CONFIGURATION } from "shared/lib/common/constants";
import { LOCAL_STORAGE, VERSION } from "shared/lib/common/enums";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { TranslateService } from "shared/lib/common/services/translate/translate.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../utils/utils.service";
import * as i3 from "../translate/translate.service";
export class ConfigService {
    constructor(document, utils, translate) {
        this.document = document;
        this.utils = utils;
        this.translate = translate;
        this.organization = "";
    }
    setOrganization(organization) {
        this.organization = organization;
    }
    getOrganization() {
        return this.organization;
    }
    setConfig(programConfig) {
        return __awaiter(this, void 0, void 0, function* () {
            if (programConfig) {
                this.applyProgramConfig(programConfig);
            }
            else {
                let config;
                const clientId = this.getMountingConfig().clientId;
                if (clientId && clientId !== "1234") {
                    config = yield this.getZipConfig(clientId);
                    if (!config.ok)
                        config = yield this.getZipConfig(this.organization);
                }
                else {
                    config = yield this.getZipConfig(this.organization);
                }
                if (config.ok === true)
                    this.applyConfig(config.response);
                else
                    this.applyConfig(CONFIGURATION);
            }
        });
    }
    setMountingConfig(config) {
        localStorage.setItem(LOCAL_STORAGE.MOUNTING_CONFIG, this.utils.encrypt(JSON.stringify(config)));
    }
    getMountingConfig() {
        const mountingConfig = {
            workstationId: null,
            deviceId: null,
            cameraPosition: null,
            clientId: null,
            clientName: null,
            deviceKey: null,
            posMode: null,
            storeId: null,
            storeName: null,
            deviceInformation: null,
            partner: null,
            store: null,
            legalName: null,
            appInteractionId: null,
            cashInteractionId: null,
        };
        try {
            return JSON.parse(this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MOUNTING_CONFIG))) || mountingConfig;
        }
        catch (error) {
            return mountingConfig;
        }
    }
    havePrinter() {
        return this.getMountingConfig().posMode ? this.getMountingConfig().posMode === "SER" : false;
    }
    haveBurnPage() {
        return this.getMountingConfig().posMode ? this.getMountingConfig().posMode !== "CAS" : false;
    }
    setAllowedManualPoints(allowed) {
        localStorage.setItem(LOCAL_STORAGE.MANUAL_ALLOWED, this.utils.encrypt(JSON.stringify(allowed)));
    }
    getAllowedManualPoints() {
        try {
            return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MANUAL_ALLOWED)) === "true";
        }
        catch (error) {
            return false;
        }
    }
    setPrinterLogo(src) {
        this.printerLogo = src;
    }
    getPrinterLogo() {
        return this.printerLogo;
    }
    getVersion(request) {
        return this.version ? this.version[request] || this.version.default : VERSION.v1;
    }
    getZipConfig(configName) {
        return new Promise(resolve => {
            if (window.JSZipUtils) {
                window.JSZipUtils.getBinaryContent(`/assets/${configName}.zip`, (err, response) => __awaiter(this, void 0, void 0, function* () {
                    if (err) {
                        resolve({ ok: false, error: { message: err } });
                    }
                    else {
                        resolve(yield this.parseZipData(response));
                    }
                }));
            }
            else {
                resolve({ ok: false, error: { message: "UNHANDLED_ERROR" } });
            }
        });
    }
    parseZipData(data) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                let config = CONFIGURATION;
                const zip = yield JSZip.loadAsync(data);
                for (const filename of Object.keys(zip.files)) {
                    const [name, type] = filename.split(".");
                    switch (name) {
                        case "logo":
                        case `${this.organization}/logo`:
                            switch (type) {
                                case "svg":
                                    config.logo_url = `data:image/svg+xml;base64,${yield zip.files[filename].async("base64")}`;
                                    break;
                                case "png":
                                    config.logo_url = `data:image/png;base64,${yield zip.files[filename].async("base64")}`;
                                    break;
                            }
                            break;
                        case "config":
                        case `${this.organization}/config`:
                            const configData = JSON.parse(yield zip.files[filename].async("text"));
                            config = Object.assign({}, config, configData);
                    }
                }
                return { ok: true, response: config };
            }
            catch (error) {
                return { ok: false, error: { message: error } };
            }
        });
    }
    applyConfig(config) {
        this.translate.setTranslations(config.translations);
        this.logo = config.logo_url;
        this.registerFormUrl = config.register_form;
        this.screenSaver = config.screen_saver;
        this.version = config.p4m_api;
        this.showSelfServiceButton = config.showSelfServiceButton;
        this.inactivityTime = config.inactivity_time;
        this.manuallyEnableCamera = !!config.manuallyEnableCamera;
        this.displayRevenue = config.display_revenue_amount_payable_with_points;
        for (const key in config.theme) {
            if (config.theme.hasOwnProperty(key)) {
                this.document.documentElement.style.setProperty(key, config.theme[key]);
            }
        }
    }
    applyProgramConfig(programConfig) {
        this.translate.setTranslations(programConfig.posapp.translations);
        this.logo = programConfig.posapp.logo || programConfig.logo;
        this.screenSaver = programConfig.posapp.screenSaver;
        this.version = programConfig.posapp.p4mApi;
        this.showSelfServiceButton = programConfig.posapp.showSelfServiceButton;
        this.inactivityTime = programConfig.posapp.inactivityTime;
        if (programConfig.theme) {
            if (programConfig.theme.colors)
                this.applyTheme(programConfig.theme.colors);
            Object.entries(programConfig.theme).forEach(([name, value]) => {
                if (value && typeof value === "string") {
                    this.document.documentElement.style.setProperty(name, value);
                }
            });
        }
    }
    applyTheme(colors) {
        this.setVariables("dark", colors.dark);
        this.setVariables("light", colors.light);
        Object.entries(colors).forEach(([name, value]) => {
            if (!value)
                return;
            switch (name) {
                case "primary":
                case "secondary":
                case "tertiary":
                case "success":
                case "warning":
                case "danger":
                case "medium":
                case "background":
                case "banner":
                case "border":
                case "dark":
                case "light":
                    this.setVariables(name, value);
                    break;
                case "primary-text":
                    this.setVariables("text", value);
                    break;
            }
        });
    }
    setVariables(name, color) {
        if (!color)
            return;
        this.document.documentElement.style.setProperty(`--color-${name}`, color);
        const colorShade = this.getShadeColor(color, -20);
        this.document.documentElement.style.setProperty(`--color-${name}-shade`, colorShade);
        const colorTint = this.getShadeColor(color, 20);
        this.document.documentElement.style.setProperty(`--color-${name}-tint`, colorTint);
        const colorRgb = this.hexToRgb(color);
        this.document.documentElement.style.setProperty(`--color-${name}-rgb`, colorRgb);
        const colorShadeRgb = this.hexToRgb(colorShade);
        this.document.documentElement.style.setProperty(`--color-${name}-shade-rgb`, colorShadeRgb);
        const colorTintRgb = this.hexToRgb(colorTint);
        this.document.documentElement.style.setProperty(`--color-${name}-tint-rgb`, colorTintRgb);
        const colorDark = this.document.documentElement.style.getPropertyValue("--color-dark") || "#000000";
        const colorLight = this.document.documentElement.style.getPropertyValue("--color-light") || "#ffffff";
        let colorContrast;
        if (this.lightOrDark(color) === "dark")
            colorContrast = colorLight;
        else
            colorContrast = colorDark;
        this.document.documentElement.style.setProperty(`--color-${name}-contrast`, colorContrast);
        const colorContrastRgb = this.hexToRgb(colorContrast);
        this.document.documentElement.style.setProperty(`--color-${name}-contrast-rgb`, colorContrastRgb);
    }
    getShadeColor(color, percent) {
        let usePound = false;
        if (color[0] === "#") {
            color = color.slice(1);
            usePound = true;
        }
        let R = parseInt(color.substring(0, 2), 16);
        let G = parseInt(color.substring(2, 4), 16);
        let B = parseInt(color.substring(4, 6), 16);
        R = R + percent;
        G = G + percent;
        B = B + percent;
        if (R > 255)
            R = 255;
        else if (R < 0)
            R = 0;
        if (G > 255)
            G = 255;
        else if (G < 0)
            G = 0;
        if (B > 255)
            B = 255;
        else if (B < 0)
            B = 0;
        const RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
        const GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
        const BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);
        return (usePound ? "#" : "") + RR + GG + BB;
    }
    hexToRgb(color) {
        if (color[0] === "#")
            color = color.slice(1);
        const R = parseInt(color.substring(0, 2), 16);
        const G = parseInt(color.substring(2, 4), 16);
        const B = parseInt(color.substring(4, 6), 16);
        return `${R}, ${G}, ${B}`;
    }
    lightOrDark(color) {
        if (color[0] === "#")
            color = color.slice(1);
        const R = parseInt(color.substring(0, 2), 16);
        const G = parseInt(color.substring(2, 4), 16);
        const B = parseInt(color.substring(4, 6), 16);
        // HSP (Highly Sensitive Poo)
        const hsp = Math.sqrt(0.299 * (R * R) + 0.587 * (G * G) + 0.114 * (B * B));
        if (hsp > 127.5)
            return "light";
        else
            return "dark";
    }
}
ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.ɵɵinject(i1.DOCUMENT), i0.ɵɵinject(i2.UtilsService), i0.ɵɵinject(i3.TranslateService)); }, token: ConfigService, providedIn: "root" });

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AlertController } from "@ionic/angular";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export class AlertService {
    constructor(alert) {
        this.alert = alert;
    }
    showAlert(opts) {
        return __awaiter(this, void 0, void 0, function* () {
            const { message, buttons, backdropDismiss = false, cssClass } = opts;
            const alert = yield this.alert.create({ message, buttons, backdropDismiss, cssClass });
            yield alert.present();
            const { role } = yield alert.onDidDismiss();
            return role ? role.toLowerCase() !== "cancel" : true;
        });
    }
    dismissAlert() {
        return __awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alert.getTop();
            return alert ? alert.dismiss() : false;
        });
    }
}
AlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertService_Factory() { return new AlertService(i0.ɵɵinject(i1.AlertController)); }, token: AlertService, providedIn: "root" });

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, OnDestroy } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { SharedRoutes } from "shared/lib/common/enums";
import { TranslateService, UtilsService, P4mService, AdminService, RoutingService } from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";
export class SuccessPage {
    constructor(routing, modal, p4m, admin, config, utils, translate) {
        this.routing = routing;
        this.modal = modal;
        this.p4m = p4m;
        this.admin = admin;
        this.config = config;
        this.utils = utils;
        this.translate = translate;
    }
    ngOnInit() {
        this.loading = true;
        this.setTimeout();
    }
    ngOnDestroy() {
        clearTimeout(this.timeout);
    }
    ionViewDidEnter() {
        return __awaiter(this, void 0, void 0, function* () {
            const resp = yield this.p4m.readAccountBalance({
                keyCode: this.keyCode,
                idType: this.idType,
                apiKey: this.admin.getApiKey(),
                clientId: this.config.getMountingConfig().clientId,
            });
            if (resp.ok === true)
                this.totalPoints = resp.response.points;
            this.loading = false;
        });
    }
    success() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.modal.dismiss();
            this.routing.goForward([SharedRoutes.screenSaver]);
        });
    }
    setTimeout() {
        this.timeout = setTimeout(() => {
            clearTimeout(this.timeout);
            this.success();
        }, 20000);
    }
}

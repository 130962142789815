var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ModalController } from "@ionic/angular";
import { CheckPinModalPage } from "shared/lib/common/pages";
import { AdminService, ToastService, TranslateService } from "shared/lib/common/services";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "../../../common/services/admin/admin.service";
import * as i3 from "../../../common/services/toast/toast.service";
import * as i4 from "../../../common/services/translate/translate.service";
export class IsPinAddedGuard {
    constructor(modal, admin, toast, translate) {
        this.modal = modal;
        this.admin = admin;
        this.toast = toast;
        this.translate = translate;
    }
    canActivate(next, state) {
        return __awaiter(this, void 0, void 0, function* () {
            const resp = this.admin.getClientStore();
            if (resp.ok === true) {
                if (resp.response && resp.response.usesPin) {
                    return yield this.showCheckPinModal(resp.response.pins[0] ? resp.response.pins[0].pin : "");
                }
                else {
                    return true;
                }
            }
            else {
                this.toast.show(this.translate._(resp.error.message), "error");
                return false;
            }
        });
    }
    showCheckPinModal(pin) {
        return __awaiter(this, void 0, void 0, function* () {
            const componentProps = {
                pin,
            };
            const checkPinModal = yield this.modal.create({
                component: CheckPinModalPage,
                cssClass: "modal modal--full-screen",
                componentProps,
            });
            yield checkPinModal.present();
            const { data } = (yield checkPinModal.onDidDismiss());
            return data;
        });
    }
}
IsPinAddedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IsPinAddedGuard_Factory() { return new IsPinAddedGuard(i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i2.AdminService), i0.ɵɵinject(i3.ToastService), i0.ɵɵinject(i4.TranslateService)); }, token: IsPinAddedGuard, providedIn: "root" });

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./coupon-card.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../v1/components/close/close.component.ngfactory";
import * as i5 from "../../../v1/components/close/close.component";
import * as i6 from "../../components/coupon-card/coupon-card.component.ngfactory";
import * as i7 from "../../components/coupon-card/coupon-card.component";
import * as i8 from "../../../common/services/translate/translate.service";
import * as i9 from "../../../common/services/utils/utils.service";
import * as i10 from "./coupon-card.page";
import * as i11 from "../../../common/services/user/user.service";
var styles_CouponCardPage = [i0.styles];
var RenderType_CouponCardPage = i1.ɵcrt({ encapsulation: 0, styles: styles_CouponCardPage, data: {} });
export { RenderType_CouponCardPage as RenderType_CouponCardPage };
export function View_CouponCardPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-header", [["class", "coupon-card-modal__header"]], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "shared-close", [], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.modal.dismiss(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CloseComponent_0, i4.RenderType_CloseComponent)), i1.ɵdid(3, 49152, null, 0, i5.CloseComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "ion-content", [["padding", ""]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(5, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "shared-v2-coupon-card", [], null, [[null, "activateEvent"], [null, "redeemEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activateEvent" === en)) {
        var pd_0 = (_co.activate($event) !== false);
        ad = (pd_0 && ad);
    } if (("redeemEvent" === en)) {
        var pd_1 = (_co.redeem($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_CouponCardComponent_0, i6.RenderType_CouponCardComponent)), i1.ɵdid(7, 114688, null, 0, i7.CouponCardComponent, [i8.TranslateService, i9.UtilsService], { ticketSelected: [0, "ticketSelected"], showRegisterButton: [1, "showRegisterButton"], isCouponRegisterMandatory: [2, "isCouponRegisterMandatory"], isCouponLocked: [3, "isCouponLocked"] }, { redeemEvent: "redeemEvent", activateEvent: "activateEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ticketSelected; var tmp_1_0 = null; var currVal_1 = (!((_co.user == null) ? null : (((tmp_1_0 = _co.user.getUser()) == null) ? null : tmp_1_0.isRegistered)) && _co.showRegisterButton); var currVal_2 = _co.registerMandatory; var currVal_3 = !((_co.ticketSelected == null) ? null : _co.ticketSelected.unlocked); _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_CouponCardPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-v2-coupon-card-page", [], null, null, null, View_CouponCardPage_0, RenderType_CouponCardPage)), i1.ɵdid(1, 49152, null, 0, i10.CouponCardPage, [i3.ModalController, i11.UserService], null, null)], null, null); }
var CouponCardPageNgFactory = i1.ɵccf("shared-v2-coupon-card-page", i10.CouponCardPage, View_CouponCardPage_Host_0, { ticketSelected: "ticketSelected", showRegisterButton: "showRegisterButton", registerMandatory: "registerMandatory" }, {}, []);
export { CouponCardPageNgFactory as CouponCardPageNgFactory };

import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import {
  ICameraPosition,
  ILanguageKey,
  IPosMode,
  IPosModeKey,
  languages,
  languagesMap,
  posModes,
  posModesMap,
} from "shared/lib/common/constants";
import { SharedRoutes } from "shared/lib/common/enums";
import { ILanguage } from "shared/lib/common/i18n/translate";
import { IClientComplet, IStore } from "shared/lib/common/interfaces";
import {
  AdminService,
  PosConnectionService,
  RoutingService,
  ToastService,
  TranslateService,
  UtilsService,
} from "shared/lib/common/services";
import { QRScanner } from "@ionic-native/qr-scanner/ngx";
import { ConfigService } from "shared/lib/common/services";

@Component({
  selector: "shared-mounting-settings",
  templateUrl: "./mounting-settings.page.html",
  styleUrls: ["./mounting-settings.page.scss"],
})
export class MountingSettingsPage {
  public canShowModal: boolean;
  public authorizedCamera: boolean;
  public workstationId: string;
  public cameraPosition: ICameraPosition;
  public posMode: IPosMode;
  public language: ILanguage;
  public languageOptions: string[];
  public posOptions: string[];
  public clients: IClientComplet[];
  public client: IClientComplet;
  public stores: IStore[];
  public store: IStore;
  public deviceInformation: string;
  public invalidClient: boolean;
  public invalidStore: boolean;
  public invalidCameraPosition: boolean;
  public invalidPosMode: boolean;
  public invalidLanguage: boolean;
  public invaliddeviceInformation: boolean;
  public loading: boolean;

  constructor(
    public routing: RoutingService,
    public admin: AdminService,
    public toast: ToastService,
    public location: Location,
    public translate: TranslateService,
    public config: ConfigService,
    public posConnectionService: PosConnectionService,
    public modal: ModalController,
    public utils: UtilsService,
    public platform: Platform,
    public qrScanner: QRScanner,
  ) {}

  public async ionViewWillEnter(): Promise<void> {
    this.clients = [];
    this.stores = [];
    this.canShowModal = true;
    this.posOptions = posModes;
    this.languageOptions = languages;
    const resp = await this.admin.getAllClients();
    if (resp.ok === true) {
      this.clients = resp.response;
      if (this.clients && this.clients.length === 1) this.client = this.clients[0];
    } else {
      this.toast.show(this.translate._(resp.error.message as any), "error");
    }
  }

  public back(): void {
    this.location.back();
  }

  public async setClient(id: number): Promise<void> {
    this.invalidClient = false;
    this.client = this.clients.find(c => c.clientId === id);
    const resp = await this.admin.fetchStoresOfClient(String(this.client.clientId));
    if (resp.ok === true) {
      this.stores = resp.response;
      if (this.stores.length === 1) this.store = this.stores[0];
      else this.store = null;
    } else {
      this.toast.show(this.translate._(resp.error.message as any), "error");
    }
  }

  public setStore(id: string): void {
    this.invalidStore = false;
    this.store = this.stores.find(s => s.storeId === +id);
  }

  public async setCameraPosition(position: ICameraPosition): Promise<void> {
    if (this.platform.is("capacitor")) {
      this.cameraPosition = position;
      try {
        if (await this.platform.ready()) {
          await this.qrScanner.prepare();
          this.authorizedCamera = true;
        }
      } catch (error) {
        this.toast.show(this.translate._(error._message), "error");
        this.authorizedCamera = false;
      }
    } else {
      this.invalidCameraPosition = false;
      this.cameraPosition = position;
      try {
        if (!this.authorizedCamera) {
          await navigator.mediaDevices.getUserMedia({ video: true });
          this.authorizedCamera = true;
        }
      } catch (error) {
        this.toast.show("Camera access denied", "error");
        this.authorizedCamera = false;
      }
    }
  }

  public setPosMode(posMode: IPosModeKey): void {
    this.invalidPosMode = false;
    this.posMode = posModesMap[posMode];
  }

  public setLanguage(language: ILanguageKey): void {
    this.invalidLanguage = false;
    this.language = languagesMap[language];
    this.translate.setSessionLanguage(languagesMap[language]);
  }

  public async mountDevice(): Promise<void> {
    this.loading = true;
    const workstationId = this.workstationId || `anonymous-${this.utils.generateRandom()}`;
    if (this.validParams()) {
      const resp = await this.admin.mountDevice({
        workstationId,
        authenticationKey: this.admin.getMountingApiKey(),
        cameraPositionCode: this.cameraPosition,
        clientId: String(this.client.clientId),
        deviceInformation: this.deviceInformation,
        deviceModel: "PAD",
        language: this.language,
        osCode: "SCK",
        posModeCode: this.posMode,
        storeId: this.store.storeId,
      });
      if (resp.ok === true) {
        this.config.setMountingConfig({
          workstationId: workstationId,
          deviceId: resp.response.deviceId,
          deviceKey: resp.response.deviceKey,
          cameraPosition: this.cameraPosition,
          clientId: String(this.client.clientId),
          clientName: this.client.clientName,
          posMode: this.posMode,
          storeId: resp.response.store.storeId,
          storeName: this.store.storeName,
          deviceInformation: this.deviceInformation,
          partner: null,
          store: null,
          legalName: this.client.legalName,
          appInteractionId: null,
          cashInteractionId: null,
        });
        this.config.setConfig();
        this.posConnectionService.connectToSocket(this.config.getMountingConfig().deviceKey);
        this.translate.setLanguage(this.language);
        this.routing.goForward([SharedRoutes.screenSaver]);
      } else {
        this.toast.show(this.translate._(resp.error.message as any), "error");
      }
    } else {
      this.toast.show(this.translate._("EMPTY_FIELDS"), "info");
    }
    this.loading = false;
  }

  public validParams(): boolean {
    let resp = true;
    if (!this.client) {
      this.invalidClient = true;
      resp = false;
    }
    if (!this.store) {
      this.invalidStore = true;
      resp = false;
    }
    if (!this.deviceInformation) {
      this.invaliddeviceInformation = true;
      resp = false;
    }
    if (!this.cameraPosition) {
      this.invalidCameraPosition = true;
      resp = false;
    }
    if (!this.posMode) {
      this.invalidPosMode = true;
      resp = false;
    }
    if (!this.language) {
      this.invalidLanguage = true;
      resp = false;
    }
    return resp;
  }
}

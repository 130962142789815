var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Router, NavigationEnd, Event } from "@angular/router";
import { NavController } from "@ionic/angular";
import * as qs from "querystring";
import { CONFIGURATION } from "shared/lib/common/constants";
import { ConfigService } from "../config/config.service";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/router";
import * as i3 from "../config/config.service";
export class RoutingService {
    constructor(navCtrl, router, config) {
        this.navCtrl = navCtrl;
        this.router = router;
        this.config = config;
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            }
        });
    }
    getPreviousUrl() {
        return this.previousUrl;
    }
    goBack(url, options) {
        return __awaiter(this, void 0, void 0, function* () {
            this.navCtrl.navigateBack(CONFIGURATION.is_custom_app ? url : [this.config.getOrganization(), ...url], options);
        });
    }
    goForward(url, options = { queryParams: null }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { relative } = options, restOptions = __rest(options, ["relative"]);
            switch (relative) {
                case "parent":
                    url = [...this.getUrlSegments().slice(0, -1), ...url];
                    break;
                case "current":
                    url = [...this.getUrlSegments(), ...url];
                    break;
                default:
                    if (!CONFIGURATION.is_custom_app) {
                        url = [this.config.getOrganization(), ...url];
                    }
            }
            return this.navCtrl.navigateForward(url, restOptions);
        });
    }
    goRoot(url, options) {
        return __awaiter(this, void 0, void 0, function* () {
            this.navCtrl.navigateRoot(CONFIGURATION.is_custom_app ? url : [this.config.getOrganization(), ...url], options);
        });
    }
    getUrlParams() {
        return Object.assign({}, this.router.routerState.snapshot.root.queryParams);
    }
    getUrlSegments() {
        const primary = this.router.parseUrl(this.router.url).root.children.primary;
        return primary ? primary.segments.map(({ path }) => path) : [];
    }
    getParentHref() {
        const parts = this.getUrlSegments();
        return parts.slice(0, -1).join("/");
    }
    isActiveUrl(url) {
        const parts = this.getUrlSegments();
        return CONFIGURATION.is_custom_app ? parts.length === 1 && url === parts[0] : parts.length === 2 && url === parts[1];
    }
    parseUrl(url) {
        const [path, query] = url.split("?");
        const segments = path.split("/");
        let key = null;
        let value = null;
        if (query)
            [key, value] = query.split("=");
        return {
            segments,
            queryParams: query && { [key]: value },
        };
    }
    createUrl(segments, queryParams) {
        return `${segments.join("/")}${queryParams ? "?" + qs.stringify(queryParams) : ""}`;
    }
    resetConfig() {
        this.router.resetConfig(this.router.config);
    }
    getRouterEvents() {
        return this.router.events;
    }
}
RoutingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoutingService_Factory() { return new RoutingService(i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.ConfigService)); }, token: RoutingService, providedIn: "root" });

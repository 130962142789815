/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./select.component";
import * as i7 from "../../../common/services/translate/translate.service";
var styles_SelectComponent = [i0.styles];
var RenderType_SelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectComponent, data: {} });
export { RenderType_SelectComponent as RenderType_SelectComponent };
function View_SelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-select-option", [], [[8, "innerHTML", 1]], null, null, i2.View_IonSelectOption_0, i2.RenderType_IonSelectOption)), i1.ɵdid(1, 49152, null, 0, i3.IonSelectOption, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { value: [0, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_v.context.$implicit[_co.optionId] || _v.context.$implicit); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit[_co.optionDisplay] || _v.context.$implicit); _ck(_v, 0, 0, currVal_0); }); }
export function View_SelectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "select"]], [[2, "select--disable", null], [2, "select--error", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "ion-select", [["class", "select--item"], ["interface", "action-sheet"]], [[2, "select--item--disable", null]], [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._handleChangeEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.select($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonSelect_0, i2.RenderType_IonSelect)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.SelectValueAccessor]), i1.ɵdid(3, 49152, null, 0, i3.IonSelect, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { disabled: [0, "disabled"], interface: [1, "interface"], interfaceOptions: [2, "interfaceOptions"], placeholder: [3, "placeholder"], value: [4, "value"] }, null), i1.ɵpod(4, { header: 0 }), i1.ɵdid(5, 16384, null, 0, i3.SelectValueAccessor, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SelectComponent_1)), i1.ɵdid(7, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.disabled; var currVal_4 = "action-sheet"; var currVal_5 = _ck(_v, 4, 0, _co.translate._("SELECT_AN_OPTION")); var currVal_6 = (("-- " + _co.translate._("SELECT_AN_OPTION")) + " --"); var currVal_7 = _co.value; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.options; var currVal_9 = _co.trackId; _ck(_v, 7, 0, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = _co.error; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.disabled; _ck(_v, 1, 0, currVal_2); }); }
export function View_SelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-select", [], null, null, null, View_SelectComponent_0, RenderType_SelectComponent)), i1.ɵdid(1, 114688, null, 0, i6.SelectComponent, [i7.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectComponentNgFactory = i1.ɵccf("shared-select", i6.SelectComponent, View_SelectComponent_Host_0, { options: "options", optionId: "optionId", optionDisplay: "optionDisplay", value: "value", disabled: "disabled", error: "error" }, { valueChange: "valueChange" }, []);
export { SelectComponentNgFactory as SelectComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./print.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i3 from "angular-feather";
import * as i4 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i5 from "@ionic/angular";
import * as i6 from "@angular/common";
import * as i7 from "./print.page";
import * as i8 from "../../../common/services/translate/translate.service";
import * as i9 from "../../../common/services/print/print.service";
import * as i10 from "../../../common/services/utils/utils.service";
var styles_PrintPage = [i0.styles];
var RenderType_PrintPage = i1.ɵcrt({ encapsulation: 0, styles: styles_PrintPage, data: {} });
export { RenderType_PrintPage as RenderType_PrintPage };
function View_PrintPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "print__content__printing"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i-printer", [["class", "print__content__printing__icon"]], null, null, null, i2.View_ɵhh_0, i2.RenderType_ɵhh)), i1.ɵdid(2, 49152, null, 0, i3.ɵhh, [], null, null)], null, null); }
function View_PrintPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "print__content__no-paper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "print__content__no-paper__icons-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i-map", [["class", "print__content__no-paper__icons-wrapper__paper-icon"]], null, null, null, i2.View_ɵfo_0, i2.RenderType_ɵfo)), i1.ɵdid(3, 49152, null, 0, i3.ɵfo, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "i-x", [["class", "print__content__no-paper__icons-wrapper__close-icon"]], null, null, null, i2.View_ɵkk_0, i2.RenderType_ɵkk)), i1.ɵdid(5, 49152, null, 0, i3.ɵkk, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "p", [["class", "print__content__no-paper__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("NO_PAPER_TEXT1"); _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.translate._("NO_PAPER_TEXT2"); _ck(_v, 9, 0, currVal_1); }); }
function View_PrintPage_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "print__content__no-connection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "print__content__no-connection__icons-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i-printer", [["class", "print__content__no-connection__icons-wrapper__printer-icon"]], null, null, null, i2.View_ɵhh_0, i2.RenderType_ɵhh)), i1.ɵdid(3, 49152, null, 0, i3.ɵhh, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "i-x", [["class", "print__content__no-connection__icons-wrapper__close-icon"]], null, null, null, i2.View_ɵkk_0, i2.RenderType_ɵkk)), i1.ɵdid(5, 49152, null, 0, i3.ɵkk, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "p", [["class", "print__content__no-connection__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("NO_CONNECTION_TEXT1"); _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.translate._("NO_CONNECTION_TEXT2"); _ck(_v, 9, 0, currVal_1); }); }
function View_PrintPage_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "print__content__success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "print__content__success__icons-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i-printer", [["class", "print__content__success__icons-wrapper__printer-icon"]], null, null, null, i2.View_ɵhh_0, i2.RenderType_ɵhh)), i1.ɵdid(3, 49152, null, 0, i3.ɵhh, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "i-check", [["class", "print__content__success__icons-wrapper__success-icon"]], null, null, null, i2.View_ɵbt_0, i2.RenderType_ɵbt)), i1.ɵdid(5, 49152, null, 0, i3.ɵbt, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "p", [["class", "print__content__success__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("SUCCESS_TEXT1"); _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.translate._("SUCCESS_TEXT2"); _ck(_v, 9, 0, currVal_1); }); }
function View_PrintPage_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("OK"); _ck(_v, 1, 0, currVal_0); }); }
function View_PrintPage_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-spinner", [["class", "print__content__button__spinner"], ["name", "dots"]], null, null, null, i4.View_IonSpinner_0, i4.RenderType_IonSpinner)), i1.ɵdid(1, 49152, null, 0, i5.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "dots"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PrintPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "ion-content", [["scroll-y", "false"]], null, null, null, i4.View_IonContent_0, i4.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i5.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 17, "div", [["class", "page-wrapper print"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 16, "div", [["class", "print__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [["class", "print__content__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrintPage_1)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrintPage_2)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrintPage_3)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrintPage_4)), i1.ɵdid(13, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 5, "ion-button", [["class", "button button--secondary print__content__button"], ["color", "secondary"], ["fill", "solid"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (((_co.printerStatus !== "printing") ? _co.dismiss() : null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(15, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], fill: [1, "fill"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PrintPage_5)), i1.ɵdid(17, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PrintPage_6)), i1.ɵdid(19, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.printerStatus === "printing"); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.printerStatus === "no_paper"); _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.printerStatus === "no_conection"); _ck(_v, 11, 0, currVal_3); var currVal_4 = (_co.printerStatus === "success"); _ck(_v, 13, 0, currVal_4); var currVal_5 = "secondary"; var currVal_6 = "solid"; _ck(_v, 15, 0, currVal_5, currVal_6); var currVal_7 = (_co.printerStatus !== "printing"); _ck(_v, 17, 0, currVal_7); var currVal_8 = (_co.printerStatus === "printing"); _ck(_v, 19, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("PRINT_PAGE_TITLE"); _ck(_v, 5, 0, currVal_0); }); }
export function View_PrintPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "generic-print", [], null, null, null, View_PrintPage_0, RenderType_PrintPage)), i1.ɵdid(1, 114688, null, 0, i7.PrintPage, [i8.TranslateService, i5.ModalController, i9.PrintService, i5.NavParams, i10.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrintPageNgFactory = i1.ɵccf("generic-print", i7.PrintPage, View_PrintPage_Host_0, {}, {}, []);
export { PrintPageNgFactory as PrintPageNgFactory };

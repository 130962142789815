var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter, OnInit } from "@angular/core";
import { TranslateService, UtilsService } from "shared/lib/common/services";
export class CouponCardComponent {
    constructor(translate, utils) {
        this.translate = translate;
        this.utils = utils;
        this.redeemEvent = new EventEmitter();
        this.registerEvent = new EventEmitter();
    }
    ngOnInit() {
        this.buttonDisabled = false;
    }
    redeem(coupon) {
        return __awaiter(this, void 0, void 0, function* () {
            this.buttonDisabled = true;
            this.redeemEvent.emit(coupon);
            yield this.utils.waiting(1000);
            this.buttonDisabled = false;
        });
    }
    goToRegister() {
        this.registerEvent.emit();
    }
}

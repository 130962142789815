var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { LOCAL_STORAGE, SharedRoutes } from "shared/lib/common/enums";
import { InactivityService, PosConnectionService, RoutingService, SentryService, ThirdPartyScriptsService, TranslateService, UserService, UtilsService, } from "shared/lib/common/services";
import { SuccessPage } from "shared/lib/common/pages";
import { ConfigService } from "shared/lib/common/services";
import { BLACK_LIST, CONFIGURATION } from "shared/lib/common/constants";
export class AppComponent {
    constructor(inactivity, modalCtrl, user, routing, config, posConnectionService, thirdParty, utils, sentry, translate, platform) {
        this.inactivity = inactivity;
        this.modalCtrl = modalCtrl;
        this.user = user;
        this.routing = routing;
        this.config = config;
        this.posConnectionService = posConnectionService;
        this.thirdParty = thirdParty;
        this.utils = utils;
        this.sentry = sentry;
        this.translate = translate;
        this.platform = platform;
    }
    ngOnInit() {
        this.initializeApp();
    }
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    initializeApp() {
        return __awaiter(this, void 0, void 0, function* () {
            const organization = this.getOrganization(window.location.href);
            this.config.setOrganization(organization);
            yield this.thirdParty.addThirdPartyScripts();
            this.isDeviceMounted = !!this.config.getMountingConfig().clientId;
            yield this.config.setConfig();
            this.checkConnection();
            this.connectToSocket();
            this.inactivityWatch();
            this.addSentryTags();
        });
    }
    inactivityWatch() {
        this.inactivity.startWatching();
        this.inactivity.onTimeout().subscribe(() => __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.modalCtrl.dismiss();
            }
            catch (error) { }
            this.user.removeUser();
            yield this.routing.goForward([SharedRoutes.screenSaver]);
        }));
    }
    checkConnection() {
        this.connection = navigator.onLine;
        window.addEventListener("offline", () => (this.connection = false));
        window.addEventListener("online", () => (this.connection = true));
    }
    connectToSocket(force = false) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.connection = true;
            this.socketConnection = true;
            if (force)
                this.posConnectionService.closeSocketConnection();
            if (this.subscription)
                this.subscription.unsubscribe();
            this.subscription = this.posConnectionService.getObservable().subscribe(this.manageSocket.bind(this));
            yield this.posConnectionService.connectToSocket(this.config.getMountingConfig().deviceKey);
            setTimeout(() => (this.connection = navigator.onLine), 4000);
            this.loading = false;
        });
    }
    openSuccessModal(keyCode, points, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            const componentProps = {
                idType,
                keyCode,
                points,
                title: points > 0 ? this.translate._("POINTS_ACUMULATED") : this.translate._("POINTS_SPENT"),
            };
            const successModal = yield this.modalCtrl.create({
                component: SuccessPage,
                componentProps,
            });
            yield successModal.present();
            yield successModal.onDidDismiss();
        });
    }
    onSocketRevenue(data) {
        return __awaiter(this, void 0, void 0, function* () {
            this.inactivity.resetTimer();
            const modal = yield this.modalCtrl.getTop();
            if (modal)
                modal.dismiss();
            const queryParams = {
                type: "earn",
                totalRevenue: data.totalRevenue,
                totalPoints: data.totalPoints,
                extendedRevenueFactsId: data.extendedRevenueFactsId,
            };
            if (data.keyCode)
                yield this.openSuccessModal(data.keyCode, data.totalPoints, data.idType || "APPCODE");
            else {
                yield this.routing.goForward([SharedRoutes.screenSaver]);
                yield this.routing.goForward([SharedRoutes.qrScanner], { queryParams });
            }
        });
    }
    addSentryTags() {
        const mountingConfig = JSON.parse(this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MOUNTING_CONFIG)));
        if (mountingConfig)
            this.sentry.addTag("device-id", String(mountingConfig.deviceId));
    }
    manageSocket(message) {
        this.isDeviceMounted = !!this.config.getMountingConfig().clientId;
        switch (message.type) {
            case "CONNECT":
                this.socketConnection = true;
                break;
            case "DISCONNECT":
                this.socketConnection = false;
                break;
            case "REVENUE":
                this.onSocketRevenue(message.data);
                break;
            case "UNMOUNT":
                localStorage.clear();
                window.location.href = SharedRoutes.login;
                break;
        }
    }
    getOrganization(url) {
        let org;
        if (this.platform.is("capacitor")) {
            org = localStorage.getItem(LOCAL_STORAGE.ORGANIZATION);
        }
        else {
            const subdomain = /:\/\/([^\/]+)/.exec(url)[1].split(".")[0];
            const isBlackListDomain = BLACK_LIST.some(host => subdomain.includes(host));
            org = !isBlackListDomain && subdomain;
        }
        return org || CONFIGURATION.organization;
    }
}

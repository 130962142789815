import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IIdentifierType } from "shared/lib/common/interfaces";
import * as io from "socket.io-client";
import { AdminService } from "shared/lib/common/services/admin/admin.service";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { EnvironmentService } from "../environment/environment.service";

export interface IPosRevenue {
  totalRevenue: number;
  totalPoints: number;
  extendedRevenueFactsId: number;
  keyCode: string;
  idType: IIdentifierType;
  payload: any;
}

export interface IPOSObservableOutput {
  type: "CONNECT" | "DISCONNECT" | "REVENUE" | "UNMOUNT";
  data?: IPosRevenue | null;
}

interface ISocketConnectionQuery {
  token: string;
  deviceKey: string;
  deviceInformation: string;
  workstationId: string;
  version: string;
  ak: string;
  ac: string;
  appInteractionId: string;
  cashInteractionId: string;
}

@Injectable({
  providedIn: "root",
})
export class PosConnectionService {
  private socket: SocketIOClient.Socket;
  private subject: Subject<IPOSObservableOutput> = new Subject<IPOSObservableOutput>();

  constructor(private admin: AdminService, private config: ConfigService, private environmentService: EnvironmentService) {}

  public async connectToSocket(deviceKey: string): Promise<void> {
    if (deviceKey && !this.socket) {
      const response = await this.admin.reLogin();
      if (response.ok === true) {
        const mountingConfig = this.config.getMountingConfig();
        this.socket = io(this.environmentService.getVariable("API_SOCKET"), {
          transports: ["websocket"],
          query: {
            deviceKey,
            token: response.response,
            deviceInformation: mountingConfig.deviceInformation,
            workstationId: mountingConfig.workstationId,
            version: this.environmentService.getVariable("RELEASE"),
            ak: this.admin.getApiKey(),
            ac: this.config.getOrganization(),
            appInteractionId: mountingConfig.appInteractionId,
            cashInteractionId: mountingConfig.cashInteractionId,
          } as ISocketConnectionQuery,
        });
        this.socket.on("reconnect_attempt", () => (this.socket.io.opts.transports = ["websocket", "polling"]));
        this.socket.on("event", (data: IPosRevenue) => this.subject.next({ type: "REVENUE", data }));
        this.socket.on("revenue", (data: IPosRevenue) => this.subject.next({ type: "REVENUE", data }));
        this.socket.on("unmount", () => this.subject.next({ type: "UNMOUNT" }));
        this.socket.on("connect", () => this.subject.next({ type: "CONNECT" }));
        this.socket.on("disconnect", (reason: string) => {
          this.subject.next({ type: "DISCONNECT" });
          if (reason === "io server disconnect") this.socket.connect();
        });
      }
    }
  }

  public closeSocketConnection(): void {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  public getObservable(): Observable<IPOSObservableOutput> {
    return this.subject.asObservable();
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-input-modal.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/forms";
import * as i5 from "../../../v1/components/button/button.component.ngfactory";
import * as i6 from "../../../v1/components/button/button.component";
import * as i7 from "./text-input-modal.page";
var styles_TextInputModalPage = [i0.styles];
var RenderType_TextInputModalPage = i1.ɵcrt({ encapsulation: 0, styles: styles_TextInputModalPage, data: {} });
export { RenderType_TextInputModalPage as RenderType_TextInputModalPage };
export function View_TextInputModalPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ion-header", [["class", "ion-no-border text-input-modal__header"]], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "ion-toolbar", [["class", "text-input-modal__header__toolbar"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-title", [["class", "text-input-modal__header__toolbar__title"]], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(6, 0, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 13, "ion-content", [["class", "text-input-modal__content"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(8, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 11, "form", [["class", "text-input-modal__content__form"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i4.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(11, 540672, null, 0, i4.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i4.ControlContainer, null, [i4.FormGroupDirective]), i1.ɵdid(13, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 6, "ion-input", [["autocomplete", "off"], ["class", "text-input-modal__content__form__input"], ["formControlName", "text"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ionBlur"], [null, "ionChange"]], function (_v, en, $event) { var ad = true; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15)._handleInputEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_IonInput_0, i2.RenderType_IonInput)), i1.ɵdid(15, 16384, null, 0, i3.TextValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.TextValueAccessor]), i1.ɵdid(17, 671744, null, 0, i4.FormControlName, [[3, i4.ControlContainer], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR], [2, i4.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i4.NgControl, null, [i4.FormControlName]), i1.ɵdid(19, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), i1.ɵdid(20, 49152, null, 0, i3.IonInput, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { autocomplete: [0, "autocomplete"], type: [1, "type"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 8, "ion-footer", [["class", "ion-no-border text-input-modal__footer"]], null, null, null, i2.View_IonFooter_0, i2.RenderType_IonFooter)), i1.ɵdid(22, 49152, null, 0, i3.IonFooter, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(23, 0, null, 0, 6, "div", [["class", "text-input-modal__footer__toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 2, "shared-button", [["color", "dark"], ["fill", "clear"]], null, [[null, "clickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = (_co.modal.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(25, 114688, null, 0, i6.ButtonComponent, [], { color: [0, "color"], fill: [1, "fill"] }, { clickEvent: "clickEvent" }), (_l()(), i1.ɵted(26, 0, ["", ""])), (_l()(), i1.ɵeld(27, 0, null, null, 2, "shared-button", [["color", "primary"], ["fill", "solid"]], null, [[null, "clickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = (_co.modal.dismiss(_co.form.value.text) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(28, 114688, null, 0, i6.ButtonComponent, [], { disabled: [0, "disabled"], color: [1, "color"], fill: [2, "fill"] }, { clickEvent: "clickEvent" }), (_l()(), i1.ɵted(29, 0, [" ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.form; _ck(_v, 11, 0, currVal_8); var currVal_16 = "text"; _ck(_v, 17, 0, currVal_16); var currVal_17 = "off"; var currVal_18 = "text"; _ck(_v, 20, 0, currVal_17, currVal_18); var currVal_19 = "dark"; var currVal_20 = "clear"; _ck(_v, 25, 0, currVal_19, currVal_20); var currVal_22 = _co.form.invalid; var currVal_23 = "primary"; var currVal_24 = "solid"; _ck(_v, 28, 0, currVal_22, currVal_23, currVal_24); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleText; _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 13).ngClassValid; var currVal_6 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 19).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 19).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 19).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 19).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 19).ngClassValid; var currVal_14 = i1.ɵnov(_v, 19).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 19).ngClassPending; _ck(_v, 14, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_21 = _co.cancelText; _ck(_v, 26, 0, currVal_21); var currVal_25 = _co.confirmText; _ck(_v, 29, 0, currVal_25); }); }
export function View_TextInputModalPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "text-input-modal-page", [], null, null, null, View_TextInputModalPage_0, RenderType_TextInputModalPage)), i1.ɵdid(1, 114688, null, 0, i7.TextInputModalPage, [i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextInputModalPageNgFactory = i1.ɵccf("text-input-modal-page", i7.TextInputModalPage, View_TextInputModalPage_Host_0, { titleText: "titleText", cancelText: "cancelText", confirmText: "confirmText" }, {}, []);
export { TextInputModalPageNgFactory as TextInputModalPageNgFactory };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ActionType, } from "shared/lib/common/interfaces";
import { AdminService as AdminServiceV1, P4mService, SentryService, UtilsService, ConfigService, TranslateService, } from "shared/lib/common/services";
import { DeviceMountingService } from "shared/lib/v2/services/device-mounting/device-mounting.service";
import { LOCAL_STORAGE } from "shared/lib/common/enums";
import { TransactionService } from "shared/lib/v2/services/transaction/transaction.service";
import { AccountService } from "../account/account.service";
import { UserService } from "../user/user.service";
import { Severity } from "@sentry/browser";
import * as i0 from "@angular/core";
import * as i1 from "../../../common/services/translate/translate.service";
import * as i2 from "../device-mounting/device-mounting.service";
import * as i3 from "../../../common/services/p4m/p4m.service";
import * as i4 from "../../../common/services/config/config.service";
import * as i5 from "../../../common/services/sentry/sentry.service";
import * as i6 from "../../../common/services/utils/utils.service";
import * as i7 from "../transaction/transaction.service";
import * as i8 from "../account/account.service";
import * as i9 from "../user/user.service";
export class AdminService extends AdminServiceV1 {
    constructor(translate, deviceMountingService, p4m, configService, sentry, utils, transaction, account, user) {
        super(translate, p4m, utils, configService, sentry);
        this.translate = translate;
        this.deviceMountingService = deviceMountingService;
        this.p4m = p4m;
        this.configService = configService;
        this.sentry = sentry;
        this.utils = utils;
        this.transaction = transaction;
        this.account = account;
        this.user = user;
    }
    getRegistrationType() {
        return Promise.resolve({ ok: true, response: this.deviceMountingService.profile.registrationType });
    }
    arts(transactionTime, amount, keyCode, idType) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const store = this.configService.getMountingConfig().store;
                yield this.account
                    .createTransaction(keyCode, this.configService.getMountingConfig().workstationId, undefined, idType || "CARDCODE", {
                    amount,
                    transactionType: "EARNTRANSACTION",
                    transactionTime,
                    externalId: `${amount.toFixed(2)}_${store ? store.vatId : store}_${this.utils.getReceiptFormatDate(new Date(transactionTime))}`,
                })
                    .toPromise();
                return { ok: true, response: null };
            }
            catch (error) {
                const message = error.error && error.error.message ? error.error.message : error.message || error;
                return { ok: false, error: { message } };
            }
        });
    }
    extendedRevenuePointsManually(keyCode, amount, extendedRevenueFactsId, idType, externalId) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.account
                    .createTransaction(keyCode, this.configService.getMountingConfig().workstationId, externalId, idType || "CARDCODE", {
                    amount,
                    transactionType: "EARNTRANSACTION",
                    externalId,
                })
                    .toPromise();
                return { ok: true, response: null };
            }
            catch (error) {
                const message = error.error && error.error.message ? error.error.message : error.message || error;
                return { ok: false, error: { message } };
            }
        });
    }
    readProgramPartners() {
        return new Promise(resolve => resolve({ ok: false, error: { message: "Method not implemented." } }));
    }
    readPartnerStores(partnerId) {
        return new Promise(resolve => resolve({ ok: false, error: { message: "Method not implemented." } }));
    }
    getAllClients() {
        return Promise.resolve({
            ok: true,
            response: [
                {
                    clientId: Number(this.configService.getMountingConfig().clientId),
                    showRegistrationButton: false,
                    pointsRoundingCode: this.deviceMountingService.profile.pointsRoundingCode,
                    revenueRoundingCode: this.deviceMountingService.profile.revenueRoundingCode,
                },
            ],
        });
    }
    getClientStore() {
        return {
            ok: true,
            response: {
                storeId: 1234,
                revenueModuleMode: this.deviceMountingService.profile.decimalRevenue ? "FLOAT" : "INT",
                usesPin: this.deviceMountingService.profile.pinRequired,
                pins: [{ pin: this.deviceMountingService.profile.pin }],
            },
        };
    }
    fetchStoresOfClient(clientId) {
        return null;
    }
    getDisplayAds(deviceKey) {
        try {
            return Promise.resolve({
                ok: true,
                response: this.deviceMountingService.images
                    .sort((a, b) => {
                    if (a.order < b.order) {
                        return -1;
                    }
                    if (a.order > b.order) {
                        return 1;
                    }
                    return 0;
                })
                    .map(i => ({
                    displayAdId: i.id,
                    displayAdUsageCode: "DEFAULT",
                    pictureFilePath: i.filePath,
                    width: null,
                    height: null,
                    displayDurationMsec: i.displayDurationMiliseconds,
                    displayAdType: null,
                    lastUpdate: null,
                    status: null,
                })),
            });
        }
        catch (e) {
            return Promise.resolve({
                ok: false,
                error: { message: e.message },
            });
        }
    }
    mountDevice(params) {
        return new Promise(resolve => resolve({ ok: false, error: { message: "Method not implemented." } }));
    }
    unMountDevice() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.deviceMountingService.unmount();
                return { ok: true, response: {} };
            }
            catch (error) {
                return { ok: false, error };
            }
        });
    }
    isValidPin(pin) {
        throw new Error("Method not implemented.");
    }
    adminLogin(email, organization, password) {
        return __awaiter(this, void 0, void 0, function* () {
            const loginResponse = yield this.p4m.login({
                email,
                organization,
                password,
            });
            if (loginResponse.ok === true) {
                this.p4m.setUser(email);
                this.p4m.setPassword(password);
                this.p4m.setToken(loginResponse.response);
                return {
                    ok: true,
                    response: {
                        mountingApiKey: null,
                        token: loginResponse.response,
                    },
                };
            }
            else {
                return loginResponse;
            }
        });
    }
    reLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.p4m.login({
                    email: this.p4m.getUser(),
                    organization: this.configService.getOrganization(),
                    password: this.p4m.getPassword(),
                });
            }
            catch (error) {
                this.sentry.handleError({ err: error, method: "admin.reLogin" });
                return { ok: false, error: { message: error.message || error } };
            }
        });
    }
    getPromotions() {
        return Promise.resolve({
            rmc1: {
                factor: 3,
                name: "Massage 3x",
                points: 0,
                revenue: 0,
                type: ActionType.RMC1,
            },
            rmc2: {
                factor: 5,
                name: "Shop 5x",
                points: 0,
                revenue: 0,
                type: ActionType.RMC2,
            },
            rmc3: {
                factor: 1,
                name: "Sonstiges",
                points: 0,
                revenue: 0,
                type: ActionType.RMC3,
            },
            rmt: {
                factor: 1,
                name: "TOTAL",
                points: 0,
                revenue: 0,
                type: ActionType.RMT,
            },
            rmb: {
                factor: 1,
                name: "BURN",
                points: 0,
                revenue: 0,
                type: ActionType.RMB,
            },
        });
    }
    logout() {
        this.sentry.handleError({
            status: 200,
            message: JSON.stringify(Object.assign({}, this.configService.getMountingConfig(), { messageType: "", messageData: "", function: "logout", file: "shared/lib/v2/services/admin", unmountDatetime: new Date().toISOString() })),
        }, { level: Severity.Log });
        localStorage.clear();
    }
    isAuthenticated() {
        if (this.p4m.getUser() && this.p4m.getPassword()) {
            return true;
        }
        else {
            return false;
        }
    }
    getPinCode() {
        return this.deviceMountingService.profile.pin;
    }
    getMountingApiKey() {
        throw new Error("Method not implemented.");
    }
    getApiKey() {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.APIKEY));
    }
    validateSaveNewPinParams(pin) {
        throw new Error("Method not implemented.");
    }
    parseGetPermissionUser(response) {
        throw new Error("Method not implemented.");
    }
    register(body) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.user
                    .addUserUsingPOST(this.configService.getMountingConfig().workstationId, Object.assign({}, body, { emailAddress: body.emailAddress.toLowerCase() }))
                    .toPromise();
                return { ok: true, response };
            }
            catch (error) {
                return { ok: false, error: { message: error.message || error } };
            }
        });
    }
}
AdminService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.DeviceMountingService), i0.ɵɵinject(i3.P4mService), i0.ɵɵinject(i4.ConfigService), i0.ɵɵinject(i5.SentryService), i0.ɵɵinject(i6.UtilsService), i0.ɵɵinject(i7.TransactionService), i0.ɵɵinject(i8.AccountService), i0.ɵɵinject(i9.UserService)); }, token: AdminService, providedIn: "root" });

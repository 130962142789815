var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Plugins } from "@capacitor/core";
import { AlertController, ModalController } from "@ionic/angular";
import { SharedRoutes } from "shared/lib/common/enums";
import { AdminService, P4mService, PosConnectionService, PrintService, ToastService, TranslateService, UtilsService, ConfigService, RoutingService, EnvironmentService, } from "shared/lib/common/services";
import { EnsureUnmountLoginModal } from "shared/lib/common/pages";
export class ConfigurationsPage {
    constructor(environment, modal, utils, p4m, fb, toast, routing, translate, print, admin, posConnectionService, alert, config) {
        this.environment = environment;
        this.modal = modal;
        this.utils = utils;
        this.p4m = p4m;
        this.fb = fb;
        this.toast = toast;
        this.routing = routing;
        this.translate = translate;
        this.print = print;
        this.admin = admin;
        this.posConnectionService = posConnectionService;
        this.alert = alert;
        this.config = config;
    }
    ngOnInit() {
        this.loading = false;
        this.form = this.fb.group({
            ip: [
                this.print.getIp(),
                [
                    Validators.required,
                    Validators.pattern(/\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b/),
                ],
            ],
        });
    }
    back() {
        this.routing.goForward([SharedRoutes.screenSaver]);
    }
    testConnection(ip) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            const response = yield this.print.testConnection(ip);
            if (response.ok === true) {
                this.print.setIp(ip);
                this.toast.show(this.translate._("PRINTER_CONNECTED"), "info");
            }
            else {
                this.toast.show(this.translate._(response.error.message), "error");
            }
            this.loading = false;
        });
    }
    removeIp() {
        this.form.reset();
        this.print.removeIp();
    }
    logOut() {
        return __awaiter(this, void 0, void 0, function* () {
            this.unmmountLoading = true;
            const { data } = yield this.presentLogin();
            if (data) {
                const resp = yield this.admin.unMountDevice();
                if (resp.ok === false)
                    this.toast.show(this.translate._(resp.error.message), "error");
                this.admin.logout();
                this.posConnectionService.closeSocketConnection();
                this.routing.resetConfig();
                this.p4m.setUser(data.email);
                this.p4m.setPassword(data.password);
                window.location.href = `/${SharedRoutes.login}`;
            }
            this.unmmountLoading = false;
        });
    }
    openBackOffice() {
        return __awaiter(this, void 0, void 0, function* () {
            const { Browser } = Plugins;
            yield Browser.open({ url: this.environment.getVariable("SELFSERVICE") });
        });
    }
    presentLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modal.create({ component: EnsureUnmountLoginModal });
            yield modal.present();
            return yield modal.onDidDismiss();
        });
    }
}

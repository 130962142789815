var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Location } from "@angular/common";
import { ModalController, Platform } from "@ionic/angular";
import { languages, languagesMap, posModes, posModesMap, } from "shared/lib/common/constants";
import { SharedRoutes } from "shared/lib/common/enums";
import { AdminService, PosConnectionService, RoutingService, ToastService, TranslateService, UtilsService, } from "shared/lib/common/services";
import { QRScanner } from "@ionic-native/qr-scanner/ngx";
import { ConfigService } from "shared/lib/common/services";
export class MountingSettingsPage {
    constructor(routing, admin, toast, location, translate, config, posConnectionService, modal, utils, platform, qrScanner) {
        this.routing = routing;
        this.admin = admin;
        this.toast = toast;
        this.location = location;
        this.translate = translate;
        this.config = config;
        this.posConnectionService = posConnectionService;
        this.modal = modal;
        this.utils = utils;
        this.platform = platform;
        this.qrScanner = qrScanner;
    }
    ionViewWillEnter() {
        return __awaiter(this, void 0, void 0, function* () {
            this.clients = [];
            this.stores = [];
            this.canShowModal = true;
            this.posOptions = posModes;
            this.languageOptions = languages;
            const resp = yield this.admin.getAllClients();
            if (resp.ok === true) {
                this.clients = resp.response;
                if (this.clients && this.clients.length === 1)
                    this.client = this.clients[0];
            }
            else {
                this.toast.show(this.translate._(resp.error.message), "error");
            }
        });
    }
    back() {
        this.location.back();
    }
    setClient(id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.invalidClient = false;
            this.client = this.clients.find(c => c.clientId === id);
            const resp = yield this.admin.fetchStoresOfClient(String(this.client.clientId));
            if (resp.ok === true) {
                this.stores = resp.response;
                if (this.stores.length === 1)
                    this.store = this.stores[0];
                else
                    this.store = null;
            }
            else {
                this.toast.show(this.translate._(resp.error.message), "error");
            }
        });
    }
    setStore(id) {
        this.invalidStore = false;
        this.store = this.stores.find(s => s.storeId === +id);
    }
    setCameraPosition(position) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.platform.is("capacitor")) {
                this.cameraPosition = position;
                try {
                    if (yield this.platform.ready()) {
                        yield this.qrScanner.prepare();
                        this.authorizedCamera = true;
                    }
                }
                catch (error) {
                    this.toast.show(this.translate._(error._message), "error");
                    this.authorizedCamera = false;
                }
            }
            else {
                this.invalidCameraPosition = false;
                this.cameraPosition = position;
                try {
                    if (!this.authorizedCamera) {
                        yield navigator.mediaDevices.getUserMedia({ video: true });
                        this.authorizedCamera = true;
                    }
                }
                catch (error) {
                    this.toast.show("Camera access denied", "error");
                    this.authorizedCamera = false;
                }
            }
        });
    }
    setPosMode(posMode) {
        this.invalidPosMode = false;
        this.posMode = posModesMap[posMode];
    }
    setLanguage(language) {
        this.invalidLanguage = false;
        this.language = languagesMap[language];
        this.translate.setSessionLanguage(languagesMap[language]);
    }
    mountDevice() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            const workstationId = this.workstationId || `anonymous-${this.utils.generateRandom()}`;
            if (this.validParams()) {
                const resp = yield this.admin.mountDevice({
                    workstationId,
                    authenticationKey: this.admin.getMountingApiKey(),
                    cameraPositionCode: this.cameraPosition,
                    clientId: String(this.client.clientId),
                    deviceInformation: this.deviceInformation,
                    deviceModel: "PAD",
                    language: this.language,
                    osCode: "SCK",
                    posModeCode: this.posMode,
                    storeId: this.store.storeId,
                });
                if (resp.ok === true) {
                    this.config.setMountingConfig({
                        workstationId: workstationId,
                        deviceId: resp.response.deviceId,
                        deviceKey: resp.response.deviceKey,
                        cameraPosition: this.cameraPosition,
                        clientId: String(this.client.clientId),
                        clientName: this.client.clientName,
                        posMode: this.posMode,
                        storeId: resp.response.store.storeId,
                        storeName: this.store.storeName,
                        deviceInformation: this.deviceInformation,
                        partner: null,
                        store: null,
                        legalName: this.client.legalName,
                        appInteractionId: null,
                        cashInteractionId: null,
                    });
                    this.config.setConfig();
                    this.posConnectionService.connectToSocket(this.config.getMountingConfig().deviceKey);
                    this.translate.setLanguage(this.language);
                    this.routing.goForward([SharedRoutes.screenSaver]);
                }
                else {
                    this.toast.show(this.translate._(resp.error.message), "error");
                }
            }
            else {
                this.toast.show(this.translate._("EMPTY_FIELDS"), "info");
            }
            this.loading = false;
        });
    }
    validParams() {
        let resp = true;
        if (!this.client) {
            this.invalidClient = true;
            resp = false;
        }
        if (!this.store) {
            this.invalidStore = true;
            resp = false;
        }
        if (!this.deviceInformation) {
            this.invaliddeviceInformation = true;
            resp = false;
        }
        if (!this.cameraPosition) {
            this.invalidCameraPosition = true;
            resp = false;
        }
        if (!this.posMode) {
            this.invalidPosMode = true;
            resp = false;
        }
        if (!this.language) {
            this.invalidLanguage = true;
            resp = false;
        }
        return resp;
    }
}

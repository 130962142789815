<div class="revenue-module">
  <div class="calculator" [class.--negative]="negativeMode">
    <div class="calculator__input">
      <p>
        <span *ngIf="revenueMode === 'INT'"> {{ negativeMode ? "-" : "" }} {{ revenue }} </span>
        <span *ngIf="revenueMode === 'FLOAT'"> {{ negativeMode ? "-" : "" }} {{ revenue | number : "1.2-2" :
          translate.getSessionLanguage() }} </span>
        {{ mode === "manual" ? translate._("POINTS") : translate._("REVENUE") }}
      </p>
    </div>
    <shared-keypad [disabled]="!revenue" [buttons]="modeButtons" (event)="press($event)"></shared-keypad>
  </div>
  <div *ngIf="mode === 'extended'" class="redeem-points">
    <div class="redeem-points__header">
      <span class="redeem-points__header__revenue">{{ translate._("REVENUE") }}</span>
      <span class="redeem-points__header__points">{{ translate._("POINTS") }}</span>
    </div>
    <ul class="redeem-points__promo-list">
      <li class="redeem-points__promo-list__promo" *ngFor="let promotion of promotions">
        <div *ngIf="promotion && promotion.type === 'RMB'; then rmb; else other">here is ignored</div>
        <ng-template #rmb>
          <ion-button class="redeem-points__promo-list__promo__button" [class.--disable]="!promotion"
            [disabled]="!promotion" [class.--negative]="true" (tap)="applyFactor(promotion)">
            <i-minus-circle class="redeem-points__promo-list__promo__button__icon"></i-minus-circle>
          </ion-button>
        </ng-template>
        <ng-template #other>
          <ion-button class="redeem-points__promo-list__promo__button" [class.--disable]="!promotion"
            [disabled]="!promotion" [class.--negative]="negativeMode" (tap)="applyFactor(promotion)">
            <i-minus-circle *ngIf="negativeMode" class="redeem-points__promo-list__promo__button__icon">
            </i-minus-circle>
            <i-plus-circle *ngIf="!negativeMode" class="redeem-points__promo-list__promo__button__icon">
            </i-plus-circle>
          </ion-button>
        </ng-template>
        <div class="redeem-points__promo-list__promo__description">
          <span class="redeem-points__promo-list__promo__description__name">{{ promotion ? promotion.name : "" }}</span>
          <span class="redeem-points__promo-list__promo__description__revenue">
            {{ promotion?.revenue | number: "1.2-2": translate.getSessionLanguage() }}
          </span>
          <span class="redeem-points__promo-list__promo__description__points">{{ promotion?.points | number: "1.0-0":
            translate.getSessionLanguage() }}</span>
        </div>
      </li>
    </ul>
    <div class="redeem-points__total-points">
      <span class="redeem-points__total-points__text">{{ translate._("TOTAL_POINTS") }} {{ totalPoints | number:
        "1.0-0": translate.getSessionLanguage() }}</span>
    </div>
    <ion-button class="redeem-points__assign-button" [class.--disable]="totalPoints === 0"
      [disabled]="totalPoints === 0" (tap)="assignPoints()">
      <span>{{ translate._("BOOK_NOW") }}</span>
      <ion-icon name="arrow-forward"></ion-icon>
    </ion-button>
  </div>
</div>
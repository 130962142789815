import { ICustomProperty, IIdentifierType, IMembership } from "shared/lib/common/interfaces/p4m-v2.interface";
import { ILanguage } from "shared/lib/common/i18n/translate";
import { Action } from "shared/lib/v2/apis/accounts";

/** Device of user: web (W), Android (A), iOS (I)  */
export type IChannelCode = "W" | "A" | "I";

export interface IGetCouponKeysResponse {
  coupon: ICoupon;
  couponKeys: ICouponKey[];
  requestInfo: IRequestInfo;
}

export interface IDeviceManagementAuthenticationResponse {
  unmountedDevice: boolean;
  authenticationKey: string;
  errorInfo?: IErrorInfo;
  successInfo?: ISuccessInfo;
  requestInfo: IRequestInfo;
}

export interface IExtendedRevenuePointsManuallyResponse {
  newPointBalance: number;
  pointsForRevenue: number;
  rewards: any[];
  noOfCheckins: number;
  totalPoints: number;
  totalPointsCollected: number;
  totalPointsUsed: number;
  totalRevenue: number;
  pointRelevant: boolean;
  bonDateTime: IDate;
  bonId: string;
  successInfo: ISuccessInfo;
  errorInfo: IErrorInfo;
  requestInfo: IRequestInfo;
}

export interface IDate {
  unixTimeStamp: number;
  timeAgo?: string;
  timeUntil?: string;
}

export interface IGetPermissionUserResponse {
  user?: IUser;
  errorInfo?: IErrorInfo;
  requestInfo: IRequestInfo;
}

export enum ActionType {
  RMT = "RMT",
  RMB = "RMB",
  RMC1 = "RMC1",
  RMC2 = "RMC2",
  RMC3 = "RMC3",
  PCI = "PCI",
  CKI = "CKI",
}

export interface IActionDefinition {
  campaignName: string;
  factor: number;
  actionType: ActionType;
}

export interface IGetActionDefinitions {
  actionDefinitions: Array<IActionDefinition>;
}

export interface IUpdateProfileExtendedRegistrationResponse {
  errorInfo: IErrorInfo;
  successInfo: ISuccessInfo;
  requestInfo: IRequestInfo;
}

export interface IRegisterUserResponse {
  user: IUser;
  errorInfo: IErrorInfo;
  successInfo: ISuccessInfo;
  requestInfo: IRequestInfo;
}

export interface IRegister {
  type: IRegistrationCode;
  simple: IRegisterParams;
  extended: IUpdateProfileExtendedRegistrationParams;
}

interface IPermissionClient {
  hasMountPermission: boolean;
  maxDisplayAdPerStore: number;
}

export interface IResponseP4M {
  errorInfo?: IErrorInfo;
  successInfo?: ISuccessInfo;
  requestInfo: IRequestInfo;
}

export interface IMountDeviceResponse {
  deviceId: number;
  deviceKey: string;
  client: IClientComplet;
  store: IStore;
  deviceInformation: string;
  osCode: string;
  mounted: boolean;
  mountDateTime: IDate;
  errorInfo?: IErrorInfo;
  successInfo?: ISuccessInfo;
  requestInfo: IRequestInfo;
}

interface IUnMountClient {
  clientId: number;
  emailOptin: boolean;
  dataOptin: boolean;
  whitemailOptin: boolean;
  newsletterOptin: boolean;
  smsOptin: boolean;
}

export interface IUnMountDeviceResponse {
  deviceId: number;
  deviceKey: string;
  client: IUnMountClient;
  store: IStore;
  deviceInformation: string;
  osCode: string;
  mounted: boolean;
  successInfo: ISuccessInfo;
  requestInfo: IRequestInfo;
}

interface ISuccessInfo {
  successCode: string;
}

export interface IDownloadDisplayAdsResponse {
  lastUpdateDisplayAds: IDate;
  displayAds: IDisplayAd[];
  requestInfo: IRequestInfo;
}

export interface IDisplayAd {
  displayAdId: string;
  displayAdUsageCode: string | "PRINTER_LOGO" | "DEFAULT";
  pictureFilePath: string;
  width: number;
  height: number;
  displayDurationMsec: number;
  displayAdType: string;
  lastUpdate: IDate;
  status: string;
}

export interface IGetStoresOfClientResponse {
  stores: IStore[];
  hasAllStoresPermission: boolean;
  errorInfo?: IErrorInfo;
  requestInfo: IRequestInfo;
}

export interface IStore {
  storeId: number;
  createDateTime?: IDate;
  client?: IClient;
  storeName?: string;
  slogan?: string;
  address?: IAddress;
  online?: boolean;
  usesPin?: boolean;
  useExtendedRevenueModule?: boolean;
  extendedRevenueModuleMode?: string;
  extendedRevenueModuleEncryptionKey?: string;
  extendedRevenueModuleEncryptionIv?: string;
  persistentKeyCodeIntervalMinutes?: number;
  revenueModuleMode?: "INT" | "FLOAT";
  startSocketServer?: boolean;
  socketSocketPort?: number;
  startHttpServer?: boolean;
  pins?: IPin[];
  externalReferenceCode?: string;
  visibleForUser?: boolean;
  businessHours?: any[];
  showInDiscover?: boolean;
  noOfUnreadCommunicationSubjects?: number;
  printedRewardValidDays?: number;
  scanAfterConfirm?: boolean;
}

interface IPin {
  pin: string;
}

interface IAddress {
  addressLine: string;
  longitude: number;
  latitude: number;
  street: string;
  houseNr: string;
  zipCode: string;
  city: string;
  countryCode: string;
  currencyCode: string;
}

export interface IGetAllClientsResponse {
  clients?: IClientComplet[];
  errorInfo?: IErrorInfo;
  requestInfo: IRequestInfo;
}

export enum RevenueRoundingCode {
  UP = "UP",
  DOWN = "DOWN",
  NO = "NO",
  COM = "COM",
}

export enum PointsRoundingCode {
  UP = "UP",
  DOWN = "DOWN",
  COM = "COM",
}

/** Property to know registration type. Simple/Only email ("SIM"), extended ("EXT"),
 * simple/extended ("SIMEXT") where only the email is required. And finally, no required register ("NO")*/
export type IRegistrationCode = IRegistrationCodeServiceStation | "NO";

/** Property to know registration type. Simple/Only email ("SIM"), extended ("EXT") or
 * simple/extended ("SIMEXT") where only the email is required.*/
export type IRegistrationCodeServiceStation = "SIM" | "SIMEXT" | "EXT";

export interface IClientComplet {
  clientId: number;
  clientName: string;
  legalName: string;
  showLogo: boolean;
  uploadPictures: boolean;
  pointsBasedOnClient: boolean;
  showRegistrationCode: IRegistrationCode;
  showRegistrationButton: boolean;
  alwaysShowRegistration: boolean;
  registrationModePosRegistrationTypeCode: IRegistrationCode;
  onlineRegistrationTypeCode: IRegistrationCode;
  postRegistrationRedirectType: string;
  postRegistrationRedirectUrl: string;
  hasRevenueModule: boolean;
  hasExtendedRevenueModule: boolean;
  hasExtendedNewsModule: boolean;
  hasExtendedCouponModule: boolean;
  hasLogoModule: boolean;
  hasMultiDeviceModule: boolean;
  statusPointsModuleCode: string;
  statusPointsExpirationDays: number;
  hasPushNewsModule: boolean;
  showP4mDisplayAds: boolean;
  hasCommunicationModule: boolean;
  hasCouponModule: boolean;
  hasCampaignModule: boolean;
  usePurchaseCheckIn: boolean;
  descreaseCheckinPointsInRevenueModule: boolean;
  isPosClient: boolean;
  createDateTime: IDate;
  namingConceptCode: string;
  exclusiveVisibility: boolean;
  officialStartDate: IDate;
  deviceStatus: string;
  revenueRoundingCode: RevenueRoundingCode;
  pointsRoundingCode: PointsRoundingCode;
}

export enum RedeemDependencyCode {
  NO = "NO",
  OPTIONAL_SIMPLE = "OPTSIM",
  OPTIONAL_EXTENDED = "OPTEXT",
  OPTIONAL_EASY = "OPTSIMEXT",
  REQUIRED_SIMPLE = "MANSIM",
  REQUIRED_EXTENDED = "MANEXT",
  REQUIRED_EASY = "MANSIMEXT",
}

type IFileTag = "contentImage" | "teaserImage" | "contentPicture" | "teaserPicture" | string;

export interface IFile {
  tag: IFileTag;
  pictureFilePath: string;
}

export interface IUserKeyResponse {
  keyCode: string;
  keyType: string;
  active: boolean;
  qrCodeContent: string;
  mergeDateTime: IDate;
  primaryKeyCode?: boolean;
}

export interface IRequestInfo {
  requestCode: string;
  executionDurationMSec: number;
  successful: boolean;
  responseSendTimestamp: number;
}

export interface IGetUserDetailsResponse {
  userReferenceCode?: "NAC" | string;
  userId: string;
  requestedKeyCodeActive?: boolean;
  userStatusCode: IUserStatus;
  emailAddress?: string;
  givenName?: string;
  familyName?: string;
  genderCode?: string;
  streetHouseNo?: string;
  zipCode?: string;
  city?: string;
  countryCode?: string;
  birthdate?: IDate;
  firstAction?: IDate;
  lastAction?: IDate;
  numberOfActions?: number;
  totalPoints: number;
  totalPointsCollected?: number;
  totalPointsUsed?: number;
  rewards?: IReward[];
  coupons: ICoupon[];
  totalRevenue?: number;
  userKeys?: IUserKeyResponse[];
  isAppUser: boolean;
  emailOptin?: boolean;
  smsOptin?: boolean;
  dataOptin?: boolean;
  newsletterOptin?: boolean;
  whitemailOptin?: boolean;
  customParameter?: string;
  convertFactor?: number;
  requestInfo?: IRequestInfo;
  errorInfo?: IErrorInfo;
  statusDefinitions?: IStatusDefinitions[];
  revenueAmountPayableWithPoints?: number;
}

export interface IStatusDefinitions {
  statusDefinitionId: number;
  statusName: string;
  pictureFilePath: string;
  points: number;
  pointsDisplay: string;
  unlocked: boolean;
}

export interface IClient {
  clientId: number;
  clientName?: string;
  showLogo: boolean;
  deviceStatus: string;
}

export interface IGetCouponsResponse {
  coupons: Array<ICoupon>;
  requestInfo?: IRequestInfo;
}

export type CouponTypeCode = "EARN" | "BURN" | "REWARD" | "DISCOUNT" | "MULTIPLIER" | "EXTRAPOINT" | "CAMPAIGN" | "INCENTIVE";

export interface ICoupon {
  couponId?: number | string;
  unlocked?: boolean;
  codeType?: string;
  validFromLocal?: IDate;
  unlockedForUser?: boolean;
  redeemRegistrationTypeCode?: string;
  userCouponKeyCodes?: string[];
  redeemedUserCouponKeyCodes?: string[];
  storeId?: number;
  pictureFilePath?: string;
  accountCouponId?: string;
  lastRedeemDateTime?: IDate;
  client?: IClient;
  couponTypeCode?: CouponTypeCode;
  title?: string;
  subtitle?: string;
  description?: string;
  points?: number;
  visibilityCode?: string;
  maxRedeemNumberGlobal?: number;
  maxRedeemNumberUser?: number;
  createDateTime?: IDate;
  validToDate?: IDate;
  validFromDate?: IDate;
  redeemDependencyCode?: RedeemDependencyCode;
  validToLocal?: IDate;
  noOfUsedCouponKeys?: number;
  maxRedeemNumberGlobalReached?: boolean;
  extendedDescription?: string;
  couponFiles?: Array<IFile>;
  bodyWithTemplate?: string;
  store?: IStore;
  sponsorStore?: IStore;
  externalCode?: string;
  externalCodeContent?: string;
  externalCodeBarcodeTypeCode?: IBarcodeType;
  customProperties?: ICustomProperty[];
  activation?: Activation;
  couponKeys?: ICouponKey[];
  redeemActions?: Action[];
}

export interface ICouponKey {
  batchId?: number;
  couponKeyId?: number;
  coupon?: ICoupon;
  keyCode?: string;
  keyType?: string;
  description?: string;
  noOfUsedCouponKeys?: number;
  qrCodeImagePath?: string;
  qrCodeContent?: string;
  createDateTime?: IDate;
  deleted?: boolean;
  noOfCouponKeys?: number;
  csvFilePath?: string;
  zipFilePath?: string;
  zipFileExists?: boolean;
  csvFileExists?: boolean;
}

export interface Activation {
  activatable: boolean;
  activated?: boolean;
}

export interface IGetRewardsResponse {
  rewards: Array<IReward>;
  requestInfo?: IRequestInfo;
}

export interface IReward {
  rewardId: number | string;
  client?: IClient;
  isRegistrationForRedeemMandatory?: boolean;
  registrationAttributes?: IRegistrationAttribute[];
  redeemRegistrationTypeCode?: string;
  sponsorStore?: IStore;
  pointsNeeded?: number;
  extendedDescription?: string;
  accountCouponId?: string;
  points?: number;
  title?: string;
  subtitle?: string;
  description?: string;
  decreasePoints?: boolean;
  unlocked?: boolean;
  pictureFilePath?: string;
  externalCode?: string;
  externalCodeContent?: string;
  createDateTime?: IDate;
  lastRedeemDateTime?: IDate;
  externalCodeBarcodeTypeCode?: IBarcodeType;
  redeemDependencyCode?: RedeemDependencyCode;
  rewardFiles?: IFile[];
  customProperties?: ICustomProperty[];
  validToDate?: IDate;
  validFromDate?: IDate;
  activation?: Activation;
  redeemActions?: Action[];
}

export type IBarcodeType = string | "QRCODE" | "INT25" | "CODE128" | "EAN8" | "EAN13" | "CUSTOM" | "P4M";

export interface IQrParams {
  keyCode: string;
  couponCode?: string;
  rewardId?: number;
}
export enum VoucherType {
  reward = "reward",
  coupon = "coupon",
}

export interface Picture {
  file: string;
  isCorrectPicture: boolean;
}

export interface VoucherReward {
  voucherId?: number | string;
  accountVoucherId?: string;
  unlocked?: boolean;
  title?: string;
  description?: string;
  extendedDescription?: string;
  voucherType?: VoucherType.reward;
  points?: number;
  decreasePoints?: boolean;
  contentPictureFilePath?: Picture;
  teaserPictureFilePath?: Picture;
  redeemDependencyCode?: RedeemDependencyCode;
  customProperties?: ICustomProperty[];
  validToDate?: IDate;
  validFromDate?: IDate;
  activation?: Activation;
  redeemActions?: Action[];
  couponKeys?: ICouponKey[];
}

export interface VoucherCoupon {
  voucherId?: number | string;
  accountVoucherId?: string;
  unlocked?: boolean;
  voucherType?: VoucherType.coupon;
  title?: string;
  description?: string;
  extendedDescription?: string;
  contentPictureFilePath?: Picture;
  teaserPictureFilePath?: Picture;
  userCouponKeyCodes?: string[];
  redeemedUserCouponKeyCodes?: string[];
  redeemDependencyCode?: RedeemDependencyCode;
  points?: number;
  validToDate?: IDate;
  validFromDate?: IDate;
  couponTypeCode?: CouponTypeCode;
  customProperties?: ICustomProperty[];
  activation?: Activation;
  redeemActions?: Action[];
  couponKeys?: ICouponKey[];
}

export type IVoucher = VoucherReward | VoucherCoupon;

export interface IGetUserHistoryResponse {
  transactions: ITransaction[];
  errorInfo?: IErrorInfo;
}

export interface ITransaction {
  transactionCode?: string;
  transactionDateTime: IDate;
  transactionTypeCode?: string;
  transactionSubTypeCode?: string;
  description: string;
  points: number;
  store?: {
    storeId: number;
    storeName: string;
  };
  amount?: number;
}

export type IUserStatus = "RGU" | "LOCKED" | "";

export interface IUser {
  userStatusCode?: IUserStatus;
  userReferenceCode?: string;
  emailOptin?: boolean;
  dataOptin?: boolean;
  newsletterOptin?: boolean;
  smsOptin?: boolean;
  whitemailOptin?: boolean;
  clientPoints?: number;
  clientPointsDisplay?: string;
  isRegistered?: boolean;
  userId?: string;
  totalPoints?: number;
  rewards?: IReward[];
  coupons?: ICoupon[];
  keyCode?: string;
  convertFactor?: number;
  emailAddress?: string;
  firstAction?: IDate;
  errorInfo?: IErrorInfo;
  statusDefinitions?: IStatusDefinitions[];
  registrationDateTime?: IDate;
  fullName?: string;
  genderCode?: string;
  newsletter?: boolean;
  language?: string;
  userKeys?: any[];
  userTransactions?: any[];
  createdByStore?: IStore;
  givenName?: string;
  familyName?: string;
  apiKey?: string;
  hasAllStoresPermission?: boolean;
  permissionClient?: IPermissionClient;
  permittedFunctions?: string[];
  idType?: IIdentifierType;
  revenueAmountPayableWithPoints?: number;
}

export interface IErrorInfo {
  errorCode: string;
  errorMessage?: string;
  exceptionCause?: string;
  exceptionMessage?: string;
  exceptionStackTrace?: string;
}

export interface IMount {
  workstationId: string;
  authenticationKey: string;
  cameraPositionCode: string;
  clientId: string;
  deviceInformation: string;
  deviceModel: "PAD";
  language: ILanguage;
  osCode: "SCK";
  posModeCode: string;
  storeId: number;
}

export interface IRevenuePoints {
  deviceKey: string;
  keyCode: string;
  idType: IIdentifierType;
  revenueRmt: number;
  revenueRmc1: number;
  revenueRmc2: number;
  revenueRmc3: number;
  revenueRmb: number;
  totalPoints: number;
}

export interface IRevenuePointsResponse {
  isNewUser: boolean;
  firstTimeInThisStore: boolean;
  firstTimeInAStoreOfThisClient: boolean;
  newPointBalance: number;
  pointsForCheckIn: number;
  pointsForRevenue: number;
  totalPoints: number;
  lastCheckIn: IDate;
  rewards: IReward[];
  coupons: ICoupon[];
  showRegistration: boolean;
  showRegistrationCode: IRegistrationCode;
  showRegistrationButtonCode: string;
  noOfCheckins: number;
  totalPointsCollected: number;
  totalPointsUsed: number;
  pointsOfUserForStatus: number;
  pointsOfUserForStatusDisplay: string;
  user: IUser;
  successInfo: ISuccessInfo;
  requestInfo: IRequestInfo;
}

export interface IPromotion {
  name: string;
  factor: number;
  points: number;
  revenue: number;
  type: ActionType;
}

export interface IPromotions {
  rmc1: IPromotion;
  rmc2: IPromotion;
  rmc3: IPromotion;
  rmb: IPromotion;
  rmt: IPromotion;
}

export interface IGetUserDetailsParams {
  keyCode: string;
  apiKey: string;
  clientId: string;
  idType?: IIdentifierType;
}

export interface IGetUserHistoryParams {
  keyCode: string;
  apiKey: string;
  clientId: string;
  idType?: IIdentifierType;
}

export interface ILoginParams {
  email: string;
  organization: string;
  password: string;
}

export interface IRedeemCouponParams {
  keyCode: string;
  apiKey: string;
  clientId: string;
  accountVoucherId: string;
  couponId?: number | string;
  couponCode?: string;
}

export interface IRedeemRewardParams {
  keyCode: string;
  rewardId: string;
  apiKey: string;
  clientId: string;
  accountVoucherId: string;
}
export interface IBookPointsParams {
  keyCode: string;
  points: string;
  apiKey: string;
  clientId: string;
  idType: IIdentifierType;
}

export interface IAssignCouponParams {
  couponId: number;
  keyCode: string;
  token: string;
  clientId: string;
}

export interface IGetCouponsParams {
  apiKey: string;
  clientId: string;
}

export interface IGetCouponKeysParams {
  organization: string;
  couponId: string;
}

export interface IGetRewardsParams {
  apiKey: string;
  clientId: string;
}

export interface IDeviceManagementAuthenticationParams {
  userName: string;
  organization: string;
  password: string;
}

export interface IGetAllClientsParams {
  organization: string;
  apiKey: string;
}

export interface IGetStoresOfClientParams {
  organization: string;
  apiKey: string;
  clientId: string;
}

export interface IDownloadDisplayAdsParams {
  organization: string;
  apiKey: string;
  deviceKey: string;
}

export interface IMountDeviceParams {
  organization: string;
  apiKey: string;
  workstationId: string;
  authenticationKey: string;
  cameraPositionCode: string;
  clientId: string;
  deviceInformation: string;
  deviceModel: "PAD";
  language: ILanguage;
  osCode: "SCK";
  posModeCode: string;
  storeId: number;
}

export interface IUnMountDeviceParams {
  organization: string;
  apiKey: string;
  deviceId: string;
  fromDevice: true;
}

export interface ILoginToGetApiKeyParams {
  userName: string;
  password: string;
  organization: string;
}

export interface IGetPermissionUserParams {
  clientId: string;
  organization: string;
}

export interface IExtendedRevenuePointsManuallyParams {
  organization: string;
  language: ILanguage;
  deviceKey: string;
  keyCode: string;
  extendedRevenueFactsId: number;
}

export interface IGetActionDefinitionsParams {
  organization: string;
  deviceKey: string;
}

export interface IRevenuePointsParams {
  organization: string;
  deviceKey: string;
  keyCode: string;
  idType: IIdentifierType;
  revenueRmt: number;
  revenueRmc1: number;
  revenueRmc2: number;
  revenueRmc3: number;
  revenueRmb: number;
  totalPoints: number;
}

export interface IReadBalanceParams {
  idType?: IIdentifierType;
  keyCode?: string;
  apiKey?: string;
  clientId?: string;
}

export interface IReadUserParams {
  idType?: IIdentifierType;
  userId?: string;
}

export interface IRegisterParams {
  organization: string;
  emailAddress: string;
  keyCode: string;
  channelCode: string;
  language: ILanguage;
  clientOptins: [{ clientId: number; dataOptin: boolean; emailOptin: boolean }];
  clientId: number;
}

export interface IUpdateProfileExtendedRegistrationParams {
  organization: string;
  emailAddress: string;
  givenName: string;
  familyName: string;
  streetHouseNo: string;
  zipCode: string;
  city: string;
  countryCode: string;
  genderCode: any;
  birthdate: string;
  phoneNumber: string;
  deviceKey: string;
  userReferenceCode: string;
  password?: string;
}

export interface IUpdateProfileExtendedRegistrationResponse {
  organization: string;
  emailAddress: string;
  givenName: string;
  familyName: string;
  streetHouseNo: string;
  zipCode: string;
  city: string;
  countryCode: string;
  genderCode: string;
  birthdate: string;
  phoneNumber: string;
  deviceKey: string;
  userReferenceCode: string;
}

export interface ICheckInParams {
  language: ILanguage;
  deviceKey: string;
  apiKey: string;
  keyCode: string;
  organization: string;
}

export interface IArtsParams {
  date: string;
  amount: number;
  apiKey: string;
  workstationId: string;
  vatId: string;
  keyCode: string;
}

export interface IArtsResponse {
  totalPoints: number;
  totalRevenue: number;
  pointRelevant: boolean;
  extendedRevenueFactsId: number;
  errorInfo?: IErrorInfo;
  successInfo?: ISuccessInfo;
  requestInfo: IRequestInfo;
}

export type IRequest =
  | { method: "getUserDetails"; params: IGetUserDetailsParams }
  | { method: "getUserHistory"; params: IGetUserHistoryParams }
  | { method: "login"; params: ILoginParams }
  | { method: "redeemCoupon"; params: IRedeemCouponParams }
  | { method: "redeemReward"; params: IRedeemRewardParams }
  | { method: "bookPoints"; params: IBookPointsParams }
  | { method: "assignCoupon"; params: IAssignCouponParams }
  | { method: "getCoupons"; params: IGetCouponsParams }
  | { method: "getRewards"; params: IGetRewardsParams }
  | { method: "deviceManagementLogin"; params: IDeviceManagementAuthenticationParams }
  | { method: "getAllClients"; params: IGetAllClientsParams }
  | { method: "getStoresOfClient"; params: IGetStoresOfClientParams }
  | { method: "downloadDisplayAds"; params: IDownloadDisplayAdsParams }
  | { method: "mountDevice"; params: IMountDeviceParams }
  | { method: "unMountDevice"; params: IUnMountDeviceParams }
  | { method: "loginToGetApiKey"; params: ILoginToGetApiKeyParams }
  | { method: "getPermissionUser"; params: IGetPermissionUserParams }
  | { method: "extendedRevenuePointsManually"; params: IExtendedRevenuePointsManuallyParams }
  | { method: "getActionDefinitions"; params: IGetActionDefinitionsParams }
  | { method: "revenuePoints"; params: IRevenuePointsParams }
  | { method: "register"; params: IRegisterParams }
  | { method: "updateProfileExtendedRegistration"; params: IUpdateProfileExtendedRegistrationParams };

export interface ICheckInResponse {
  isNewUser?: boolean;
  firstTimeInThisStore?: boolean;
  firstTimeInAStoreOfThisClient?: boolean;
  newPointBalance?: number;
  pointsForCheckIn?: number;
  lastCheckIn?: IDate;
  rewards?: IReward[];
  coupons?: ICoupon[];
  showRegistration?: boolean;
  showRegistrationCode?: IRegistrationCode;
  showRegistrationButtonCode?: string;
  noOfCheckins?: number;
  totalPointsCollected?: number;
  totalPointsUsed?: number;
  user?: IUser;
  successInfo?: ISuccessInfo;
  errorInfo?: IErrorInfo;
  requestInfo?: IRequestInfo;
}

export interface IRegistrationAttribute {
  attributeName: string;
  attributeVisibilityCode: string;
}

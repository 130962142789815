import { SharedRoutes } from "shared/lib/common/enums";
import { AdminService, RoutingService, TranslateService, UtilsService } from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";
import * as i0 from "@angular/core";
import * as i1 from "../../services/utils/utils.service";
import * as i2 from "../../services/admin/admin.service";
import * as i3 from "../../services/translate/translate.service";
import * as i4 from "../../services/config/config.service";
import * as i5 from "../../services/routing/routing.service";
export class IsRegisteredAdminGuard {
    constructor(utils, admin, translate, config, routing) {
        this.utils = utils;
        this.admin = admin;
        this.translate = translate;
        this.config = config;
        this.routing = routing;
    }
    canActivate(next, state) {
        const deviceMounting = this.config.getMountingConfig().deviceKey
            ? this.config.getMountingConfig()
            : JSON.parse(this.utils.decrypt(localStorage.getItem("mountingConfig")));
        const deviceKey = deviceMounting ? deviceMounting.deviceKey : null;
        const language = this.translate.getLanguage();
        if (this.admin.isAuthenticated() && deviceKey && deviceKey !== "" && language) {
            if (state.url === `/${SharedRoutes.login}`)
                this.routing.goForward([SharedRoutes.screenSaver]);
            return true;
        }
        else {
            if (state.url === `/${SharedRoutes.login}`) {
                return true;
            }
            else {
                this.routing.goForward([SharedRoutes.login]);
                return false;
            }
        }
    }
}
IsRegisteredAdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IsRegisteredAdminGuard_Factory() { return new IsRegisteredAdminGuard(i0.ɵɵinject(i1.UtilsService), i0.ɵɵinject(i2.AdminService), i0.ɵɵinject(i3.TranslateService), i0.ɵɵinject(i4.ConfigService), i0.ɵɵinject(i5.RoutingService)); }, token: IsRegisteredAdminGuard, providedIn: "root" });

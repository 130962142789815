var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ModalController, AlertController } from "@ionic/angular";
import { ConfigService, ToastService, TranslateService, UserService } from "shared/lib/common/services";
import { AccountService, DeviceMountingService } from "shared/lib/v2/services";
export class SummaryPage {
    constructor(deviceMounting, account, modal, t, alert, userService, config, toast) {
        this.deviceMounting = deviceMounting;
        this.account = account;
        this.modal = modal;
        this.t = t;
        this.alert = alert;
        this.userService = userService;
        this.config = config;
        this.toast = toast;
    }
    showAlert() {
        return __awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alert.create({
                header: this.t._("REVENUE_ALERT_TITLE"),
                message: this.t._("REVENUE_ALERT_MESSAGE"),
                buttons: [
                    {
                        role: "cancel",
                        text: this.t._("CANCEL"),
                    },
                    {
                        text: this.t._("CONFIRM"),
                    },
                ],
                backdropDismiss: false,
            });
            yield alert.present();
            const { role } = yield alert.onWillDismiss();
            if (!role || (role && role.toLowerCase() !== "cancel"))
                this.modal.dismiss(this.revenues);
        });
    }
    updatePreview() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            const user = this.userService.getUser();
            try {
                const resp = yield this.account
                    .createTransactionPrev(user.keyCode, this.config.getMountingConfig().workstationId, user.idType, this.account.getTransactionData(this.revenues, this.mode === "earn" ? "EARNTRANSACTION" : "PAYWITHPOINTSTRANSACTION"))
                    .toPromise();
                this.revenues = resp.lineItems.map(l => {
                    return {
                        name: l.description,
                        points: l.points,
                        money: l.extendedAmount,
                        id: l.itemID,
                        partnerId: this.config.getMountingConfig().partner.id,
                        program: this.config.getOrganization(),
                        groupCode: l.merchandiseGroupCode,
                        subGroupCode: l.merchandiseSubGroupCode,
                    };
                });
                this.amount = resp.amount;
                this.points = resp.points;
            }
            catch (error) {
                const e = error.error && error.error.message ? error.error.message : error.message || error;
                this.toast.show(this.t._(e), "error");
            }
            finally {
                this.loading = false;
            }
        });
    }
}

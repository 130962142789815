import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, Observable, throwError } from "rxjs";
import { catchError, switchMap, retry } from "rxjs/operators";
import { P4M, LOCAL_STORAGE } from "shared/lib/common/enums";
import { P4mService } from "shared/lib/common/services/p4m/p4m.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { EnvironmentService } from "shared/lib/common/services";

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(
    private config: ConfigService,
    private p4m: P4mService,
    private utils: UtilsService,
    private environmentService: EnvironmentService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(`${this.environmentService.getVariable("API_MOUNTING")}/api/device-management/pmm`)) {
      req = req.clone({ withCredentials: true });
    }
    if (
      req.url.includes(this.environmentService.getVariable("API_MOUNTING")) ||
      req.url.includes(this.environmentService.getVariable("API_V2"))
    ) {
      req = req.clone({ setHeaders: { authorization: this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.TOKEN)) } });
    }
    return next.handle(req).pipe(
      retry(2),
      catchError(error => {
        if (req.url.includes(P4M.LOGIN) || req.url.includes(P4M.LOGIN_V2)) return throwError(error);
        if (error.status === 0) return this.reLogin(req, next, error);
        return throwError(error);
      }),
    );
  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { authorization: token } });
  }

  private reLogin(req: HttpRequest<any>, next: HttpHandler, error: any): Observable<any> {
    return from(
      this.p4m.login({
        password: this.p4m.getPassword(),
        email: this.p4m.getUser(),
        organization: this.config.getOrganization(),
      }),
    ).pipe(switchMap(resp => (resp.ok === true ? next.handle(this.addToken(req, resp.response)) : throwError(error))));
  }
}

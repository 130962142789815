var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { formatDate } from "@angular/common";
import { Platform } from "@ionic/angular";
import * as Sentry from "@sentry/browser";
import { LOCAL_STORAGE } from "shared/lib/common/enums";
import { Align, Line } from "shared/lib/common/interfaces/epson.interface";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { TranslateService } from "shared/lib/common/services/translate/translate.service";
import { UserService } from "shared/lib/common/services/user/user.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { EVENT_LISTENERS } from "shared/lib/common/services/third-party-scripts/third-party-scripts.service";
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
import * as i2 from "../utils/utils.service";
import * as i3 from "../user/user.service";
import * as i4 from "../translate/translate.service";
import * as i5 from "@ionic/angular";
export class PrintService {
    constructor(config, utils, user, translate, platform) {
        this.config = config;
        this.utils = utils;
        this.user = user;
        this.translate = translate;
        this.platform = platform;
        if (window.epson) {
            this.epsonPOSDevice = new window.epson.ePOSDevice();
        }
        else {
            document.addEventListener(EVENT_LISTENERS.ON_LOAD_EPSON_SCRIPT, function (event) {
                this.epsonPOSDevice = new window.epson.ePOSDevice();
            });
        }
    }
    setIp(ip) {
        localStorage.setItem(LOCAL_STORAGE.IP, this.utils.encrypt(ip));
    }
    getIp() {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.IP));
    }
    removeIp() {
        localStorage.removeItem(LOCAL_STORAGE.IP);
    }
    printTicket(voucher) {
        return __awaiter(this, void 0, void 0, function* () {
            const connected = yield this.connect();
            if (connected.ok === true) {
                const created = yield this.createDevice();
                if (created.ok === true) {
                    const externalCodeResponse = this.user.getExternalCode(voucher);
                    if (externalCodeResponse.ok === true) {
                        this.printer = created.response;
                        this.printer.addTextLang("de");
                        this.print(this.config.getMountingConfig().storeName, Align.center, Line.title);
                        this.addBlankLine();
                        this.platform.is("capacitor")
                            ? this.print(this.config.getMountingConfig().clientName, Align.center, Line.title)
                            : yield this.addLogo();
                        this.addBlankLine();
                        this.print("Coupon", Align.left, Line.paragraph);
                        this.print(voucher.description, Align.left, Line.subtitle);
                        this.addBlankLine();
                        this.print(this.translate._("VALID_AT"), Align.left, Line.paragraph);
                        this.print(this.config.getMountingConfig().clientName, Align.left, Line.paragraph);
                        this.print(this.config.getMountingConfig().storeName, Align.left, Line.paragraph);
                        this.addBlankLine();
                        if (externalCodeResponse.response.code) {
                            if (["CUSTOM", "P4M"].includes(externalCodeResponse.response.type)) {
                                this.print(externalCodeResponse.response.code, Align.center, Line.paragraph);
                            }
                            else {
                                this.printCode(externalCodeResponse.response.type, externalCodeResponse.response.code);
                                this.addBlankLine();
                            }
                        }
                        else {
                            this.print(voucher.title, Align.center, Line.paragraph);
                        }
                        this.addBlankLine();
                        this.print(this.translate._("TICKET_PRINT1"), Align.center, Line.paragraph);
                        this.addBlankLine();
                        this.print(this.translate._("TICKET_PRINT2"), Align.center, Line.paragraph);
                        this.print(this.translate._("TICKET_PRINT3"), Align.center, Line.paragraph);
                        this.print(this.translate._("TICKET_PRINT4"), Align.center, Line.paragraph);
                        this.print(this.translate._("TICKET_PRINT5"), Align.center, Line.paragraph);
                        this.addBlankLine();
                        this.print(this.translate._("ISSUED_ON"), Align.center, Line.paragraph);
                        this.print(formatDate(new Date(), "dd.MM.yyyy hh:mm", this.translate.getSessionLanguage()), Align.center, Line.paragraph);
                        this.print(this.translate._("CUSTOMER_CARD") + ": " + (this.user.getUser() || {}).keyCode, Align.center, Line.paragraph);
                        this.addBlankLine();
                        this.print(this.translate._("REDEEMABLE_UNTIL") + formatDate(new Date(), "dd.MM.yyyy", this.translate.getSessionLanguage()), Align.center, Line.paragraph);
                        this.addBlankLine();
                        this.printer.addCut();
                        this.printer.send();
                        this.epsonPOSDevice.deleteDevice(this.printer, () => this.epsonPOSDevice.disconnect());
                        return { ok: true, response: null };
                    }
                    else {
                        this.epsonPOSDevice.disconnect();
                        return {
                            ok: false,
                            error: { message: externalCodeResponse.error.message },
                        };
                    }
                }
                else {
                    this.epsonPOSDevice.disconnect();
                    return {
                        ok: false,
                        error: { message: created.error.message },
                    };
                }
            }
            else {
                this.epsonPOSDevice.disconnect();
                return {
                    ok: false,
                    error: { message: connected.error.message },
                };
            }
        });
    }
    testConnection(ip) {
        return __awaiter(this, void 0, void 0, function* () {
            const connected = yield this.connect(ip);
            if (connected.ok === true) {
                return { ok: true, response: null };
            }
            else {
                return { ok: false, error: { message: connected.error.message } };
            }
        });
    }
    addLogo() {
        return new Promise(resolve => {
            const c = document.createElement("canvas");
            c.id = "canvas";
            document.body.appendChild(c);
            const canvas = document.getElementsByTagName("canvas")[0];
            const img = new Image();
            img.src = this.config.getPrinterLogo();
            img.crossOrigin = "Anonymous";
            img.onload = () => {
                c.width = img.width;
                c.height = img.height;
                canvas.getContext("2d").drawImage(img, 0, 0);
                this.printer.brightness = 1.5;
                this.printer.halftone = 0;
                this.printer.addImage(canvas.getContext("2d"), 0, 0, c.width, c.height, "color_1", "gray16");
                resolve();
            };
            img.onerror = () => {
                resolve();
            };
        });
    }
    print(text, align, type) {
        const isBold = type === Line.title || type === Line.subtitle;
        this.printer.addTextAlign(align);
        this.printer.addTextStyle(false, false, isBold);
        this.printer.addTextSize(type === Line.title ? 2 : 1, type === Line.title ? 2 : 1);
        this.printer.addText(`${this.utils.decodeHTMLEntity(text)}\n`);
    }
    printCode(type, code) {
        this.printer.addTextAlign(Align.center);
        let externalCode = code;
        switch (type) {
            case "QRCODE":
                this.printer.addSymbol(code, "qrcode_micro", "level_0", 10);
                break;
            case "CODE128":
                this.printer.addBarcode(code, "code128", "none", "font_a", 3, 162);
                if (code.match("{A") || code.match("{B") || code.match("{C")) {
                    externalCode = code.substr(2);
                }
                break;
            case "EAN13":
                this.printer.addBarcode(code, "ean13", "none", "font_a", 3, 162);
                break;
            case "EAN8":
                this.printer.addBarcode(code, "ean8", "none", "font_a", 3, 162);
                break;
            default:
                break;
        }
        this.printer.addText("\n");
        this.print(externalCode, Align.center, Line.paragraph);
    }
    addBlankLine() {
        this.printer.addText("\n\n");
    }
    connect(ip) {
        const port = this.platform.is("capacitor") ? 8008 : 8043;
        return new Promise(resolve => {
            let auxIp;
            if (!ip || ip === "") {
                const savedIp = this.getIp();
                if (!savedIp || savedIp === "") {
                    resolve({ ok: false, error: { message: "NOT_CONNECTED" } });
                }
                else {
                    auxIp = savedIp;
                }
            }
            else {
                auxIp = ip;
            }
            this.epsonPOSDevice.connect(auxIp, port, result => {
                if (result === "OK" || result === "SSL_CONNECT_OK") {
                    resolve({ ok: true, response: null });
                }
                Sentry.captureMessage(result, Sentry.Severity.Debug);
                resolve({
                    ok: false,
                    error: { message: "NOT_CONNECTED" },
                });
            });
        });
    }
    createDevice() {
        return new Promise(resolve => {
            this.epsonPOSDevice.createDevice("local_printer", "type_printer", { buffer: false, crypto: !this.platform.is("capacitor") }, (device, result) => {
                if (result && result !== "OK") {
                    Sentry.captureMessage(result, Sentry.Severity.Debug);
                    resolve({ ok: false, error: { message: "NO_PAPER" } });
                }
                else {
                    resolve({ ok: true, response: device });
                }
            });
        });
    }
}
PrintService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PrintService_Factory() { return new PrintService(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.UtilsService), i0.ɵɵinject(i3.UserService), i0.ɵɵinject(i4.TranslateService), i0.ɵɵinject(i5.Platform)); }, token: PrintService, providedIn: "root" });

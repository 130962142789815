<ion-content>
  <div class="page-wrapper login">
    <div class="login--content">
      <h1 class="login--title">{{ translate._("LOGIN") }}</h1>
      <form autocomplete="off" [formGroup]="form" class="login--form"
        (keyup.enter)="!form.invalid && login(form.get('email').value, form.get('password').value)">
        <ion-select class="login--select" *ngIf="isCapacitor"
          [placeholder]="'-- ' + translate._('ORGANIZATION') + ' --'" (ionChange)="onSelectOrganization($event)"
          [interfaceOptions]="{ header: translate._('SELECT_AN_OPTION') }">
          <ion-select-option *ngFor="let option of organizations" [value]="option">
            {{ option }}
          </ion-select-option>
        </ion-select>
        <input (focus)="updateKeyboardStatus(true)" (focusout)="updateKeyboardStatus(false)" autocomplete="off"
          type="email" formControlName="email" placeholder="Email" class="login--input"
          [class.--has-error]="checkErrors('email')" />
        <div class="login--form__password-container">
          <input (focus)="updateKeyboardStatus(true)" (focusout)="updateKeyboardStatus(false)" autocomplete="off"
            formControlName="password" [type]="showPassword ? 'text' : 'password'" placeholder="Password"
            class="login--form__password-container__input-password" [class.--has-error]="checkErrors('password')" />
          <ion-button *ngIf="!showPassword" (tap)="changeShowPassword()"
            class="login--form__password-container__button-password" fill="clear" color="dark">
            <i-eye class="login--form__password-container__button-password__icon"></i-eye>
          </ion-button>
          <ion-button *ngIf="showPassword" (tap)="changeShowPassword()"
            class="login--form__password-container__button-password" fill="clear" color="dark">
            <i-eye-off class="login--form__password-container__button-password__icon"></i-eye-off>
          </ion-button>
        </div>
        <shared-button class="login--button" [color]="'primary'" [fill]="'solid'" [disabled]="!form.valid"
          [loading]="loading" (clickEvent)="login(form.get('email').value, form.get('password').value)">
          {{ translate._("LOGIN") }}</shared-button>
      </form>
    </div>
  </div>
  <p class="login--version">version: {{ releaseVersion }}</p>
</ion-content>
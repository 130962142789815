/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./keypad.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i5 from "angular-feather";
import * as i6 from "@angular/common";
import * as i7 from "./keypad.component";
var styles_KeypadComponent = [i0.styles];
var RenderType_KeypadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KeypadComponent, data: {} });
export { RenderType_KeypadComponent as RenderType_KeypadComponent };
function View_KeypadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-button", [["class", "keypad__button --with-icons"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.onPress(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "i-plus", [], null, null, null, i4.View_ɵhe_0, i4.RenderType_ɵhe)), i1.ɵdid(3, 49152, null, 0, i5.ɵhe, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "i-minus", [], null, null, null, i4.View_ɵgb_0, i4.RenderType_ɵgb)), i1.ɵdid(5, 49152, null, 0, i5.ɵgb, [], null, null)], null, null); }
function View_KeypadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-button", [["class", "keypad__button --grey"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.onPress(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, [" C "]))], null, null); }
function View_KeypadComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-button", [["class", "keypad__button --yellow"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.onPress(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, [" AC "]))], null, null); }
function View_KeypadComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-button", [["class", "keypad__button"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.onPress(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_KeypadComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-button", [["class", "keypad__button"], ["color", "danger"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.onPress(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "i-delete", [], null, null, null, i4.View_ɵdf_0, i4.RenderType_ɵdf)), i1.ɵdid(3, 49152, null, 0, i5.ɵdf, [], null, null)], function (_ck, _v) { var currVal_0 = "danger"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_KeypadComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-button", [["class", "keypad__button --green"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.onPress(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "i-corner-down-right", [], null, null, null, i4.View_ɵct_0, i4.RenderType_ɵct)), i1.ɵdid(3, 49152, null, 0, i5.ɵct, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 1, 0, currVal_0); }, null); }
function View_KeypadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KeypadComponent_2)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KeypadComponent_3)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KeypadComponent_4)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KeypadComponent_5)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KeypadComponent_6)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KeypadComponent_7)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit === "plusMinus"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit === "c"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit === "ac"); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.isNumber(_v.context.$implicit); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_v.context.$implicit === "delete"); _ck(_v, 10, 0, currVal_4); var currVal_5 = (_v.context.$implicit === "enter"); _ck(_v, 12, 0, currVal_5); }, null); }
export function View_KeypadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "keypad"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KeypadComponent_1)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttons; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_KeypadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-keypad", [], null, null, null, View_KeypadComponent_0, RenderType_KeypadComponent)), i1.ɵdid(1, 49152, null, 0, i7.KeypadComponent, [], null, null)], null, null); }
var KeypadComponentNgFactory = i1.ɵccf("shared-keypad", i7.KeypadComponent, View_KeypadComponent_Host_0, { disabled: "disabled", buttons: "buttons" }, { event: "event" }, []);
export { KeypadComponentNgFactory as KeypadComponentNgFactory };

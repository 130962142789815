/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./check-pin-modal.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../v1/components/close/close.component.ngfactory";
import * as i5 from "../../../v1/components/close/close.component";
import * as i6 from "../../../v1/components/pin/pin.component.ngfactory";
import * as i7 from "../../../v1/components/pin/pin.component";
import * as i8 from "./check-pin-modal.page";
import * as i9 from "../../services/translate/translate.service";
import * as i10 from "../../services/toast/toast.service";
var styles_CheckPinModalPage = [i0.styles];
var RenderType_CheckPinModalPage = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckPinModalPage, data: {} });
export { RenderType_CheckPinModalPage as RenderType_CheckPinModalPage };
export function View_CheckPinModalPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-header", [["class", "check-pin-modal__header"]], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "shared-close", [], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CloseComponent_0, i4.RenderType_CloseComponent)), i1.ɵdid(3, 49152, null, 0, i5.CloseComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "ion-content", [["class", "check-pin-modal__content"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(5, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "h1", [["class", "check-pin-modal__content__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "shared-pin", [], null, [[null, "event"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("event" === en)) {
        var pd_0 = (_co.checkPin($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_PinComponent_0, i6.RenderType_PinComponent)), i1.ɵdid(9, 114688, null, 0, i7.PinComponent, [], { pinLength: [0, "pinLength"] }, { event: "event" })], function (_ck, _v) { var currVal_1 = 4; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate._("TYPE_PIN"); _ck(_v, 7, 0, currVal_0); }); }
export function View_CheckPinModalPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-check-pin-modal", [], null, null, null, View_CheckPinModalPage_0, RenderType_CheckPinModalPage)), i1.ɵdid(1, 114688, null, 0, i8.CheckPinModalPage, [i3.NavParams, i3.ModalController, i9.TranslateService, i10.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CheckPinModalPageNgFactory = i1.ɵccf("shared-check-pin-modal", i8.CheckPinModalPage, View_CheckPinModalPage_Host_0, { params: "params" }, {}, []);
export { CheckPinModalPageNgFactory as CheckPinModalPageNgFactory };

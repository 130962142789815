import { Component, Input } from "@angular/core";
import { Revenue } from "../revenue/revenue.page";
import { ModalController, AlertController } from "@ionic/angular";
import { ConfigService, ToastService, TranslateService, UserService } from "shared/lib/common/services";
import { AccountService, DeviceMountingService } from "shared/lib/v2/services";
import { AccountTransaction } from "../../apis/accounts";

export interface SummaryPageInputs {
  revenues: Revenue[];
  points: number;
  amount: number;
  mode: "earn" | "burn";
}

export interface SummaryPageOutputs {
  data?: Revenue[];
}

@Component({
  selector: "shared-v2-summary-page",
  templateUrl: "./summary.page.html",
  styleUrls: ["./summary.page.scss"],
})
export class SummaryPage {
  @Input() public revenues: Revenue[];
  @Input() public points: number;
  @Input() public amount: number;
  @Input() public mode: "earn" | "burn";
  public loading: boolean;

  constructor(
    public deviceMounting: DeviceMountingService,
    public account: AccountService,
    public modal: ModalController,
    public t: TranslateService,
    private alert: AlertController,
    private userService: UserService,
    private config: ConfigService,
    private toast: ToastService,
  ) {}

  public async showAlert(): Promise<void> {
    const alert: HTMLIonAlertElement = await this.alert.create({
      header: this.t._("REVENUE_ALERT_TITLE"),
      message: this.t._("REVENUE_ALERT_MESSAGE"),
      buttons: [
        {
          role: "cancel",
          text: this.t._("CANCEL"),
        },
        {
          text: this.t._("CONFIRM"),
        },
      ],
      backdropDismiss: false,
    });
    await alert.present();
    const { role } = await alert.onWillDismiss();
    if (!role || (role && role.toLowerCase() !== "cancel")) this.modal.dismiss(this.revenues);
  }

  public async updatePreview(): Promise<void> {
    this.loading = true;
    const user = this.userService.getUser();
    try {
      const resp: AccountTransaction = await this.account
        .createTransactionPrev(
          user.keyCode,
          this.config.getMountingConfig().workstationId,
          user.idType,
          this.account.getTransactionData(this.revenues, this.mode === "earn" ? "EARNTRANSACTION" : "PAYWITHPOINTSTRANSACTION"),
        )
        .toPromise();
      this.revenues = resp.lineItems.map(l => {
        return {
          name: l.description,
          points: l.points,
          money: l.extendedAmount,
          id: l.itemID,
          partnerId: this.config.getMountingConfig().partner.id,
          program: this.config.getOrganization(),
          groupCode: l.merchandiseGroupCode,
          subGroupCode: l.merchandiseSubGroupCode,
        };
      });
      this.amount = resp.amount;
      this.points = resp.points;
    } catch (error) {
      const e = error.error && error.error.message ? error.error.message : error.message || error;
      this.toast.show(this.t._(e), "error");
    } finally {
      this.loading = false;
    }
  }
}

<ion-content scroll-y="false">
  <div ion-button class="page-wrapper burn-page">
    <div class="burn-page__status">
      <div
        class="burn-page__status__logo"
        [class.--is-disabled]="!status"
        [style.backgroundImage]="status?.pictureFilePath ? 'url(' + status?.pictureFilePath + ')' : ''"
      ></div>
      <div class="burn-page__status__balance">
        <h1 class="burn-page__status__balance__title">
          {{ translate._("YOUR_BALANCE") }}
        </h1>
        <p class="burn-page__status__balance__number">
          {{ (totalPoints | number : "" : translate.getSessionLanguage()) + " " + translate._("POINTS") }}
        </p>
        <div *ngIf="config.displayRevenue" class="burn-page__status__balance__number">
          {{ revenueAmountPayableWithPoints | currency: 'EUR' : 'symbol' : '1.2-2' : translate.getSessionLanguage() }}
        </div>
      </div>
    </div>

    <div class="burn-page--buttons-wrapper">
      <ion-button (tap)="openRedeemCoupon()" class="button button--secondary burn-page--button-redeem" color="secondary" fill="solid">
        <ion-spinner *ngIf="getCouponKeysLoading" class="burn-page--button-redeem--loading"></ion-spinner>
        <div *ngIf="!getCouponKeysLoading" class="button-flex">
          <i-gift></i-gift>
          <span class="burn-page--button-redeem--wrapped">{{ translate._("REDEEM_COUPON") }}</span>
        </div>
      </ion-button>
      <ion-button (tap)="goHistoryPage()" class="button button--secondary burn-page--button-history" color="secondary" fill="solid">
        <div class="button-flex">
          <i-clock></i-clock>
          <span class="burn-page--button-history--wrapped"> {{ translate._("HISTORY") }}</span>
        </div>
      </ion-button>
      <ion-button (tap)="logOutUser()" class="button button--danger burn-page--button-logout" color="danger" fill="solid">
        <div class="button-flex">
          <i-log-out></i-log-out>
          <span class="burn-page--button-logout--wrapped"> {{ translate._("LOG_OUT") }}</span>
        </div>
      </ion-button>
    </div>

    <div class="burn-page__wrapper">
      <div *ngIf="!loading && !mobileVersion" class="burn-page__wrapper__column">
        <shared-v1-coupon-card
          [ticketSelected]="ticketSelected"
          [isCouponLocked]="!ticketSelected?.unlocked"
          [showRegisterButton]="showRegisterButton"
          [isCouponRegisterMandatory]="needToShowRegisterModal(ticketSelected)"
          [loading]="registerLoading"
          (registerEvent)="goToRegister()"
          (redeemEvent)="askToRedeem($event)"
        ></shared-v1-coupon-card>
      </div>
      <ion-spinner *ngIf="loading" class="burn-page__wrapper__spinner"></ion-spinner>
      <div *ngIf="!loading && tickets?.length > 0" class="burn-page__wrapper__column">
        <ion-list lines="none" class="burn-page__wrapper__column__ticket-list">
          <ion-item
            no-padding
            class="burn-page__wrapper__column__ticket-list__ticket"
            [disabled]="!ticket.unlocked"
            [class.--disabled]="!ticket.unlocked"
            [class.--active]="ticketSelected?.voucherId === ticket.voucherId"
            *ngFor="let ticket of tickets"
            (click)="selectTicket(ticket)"
          >
            <div *ngIf="ticket.voucherType === 'reward'" class="burn-page__wrapper__column__ticket-list__ticket__points">
              <p>
                {{ ticket["points"] | number: "":translate.getSessionLanguage() }}
                <span>{{ translate._("POINTS") }}</span>
              </p>
            </div>
            <div *ngIf="ticket.voucherType === 'coupon'" class="burn-page__wrapper__column__ticket-list__ticket__points --free">
              <p *ngIf="!ticket['points']">{{ translate._("FREE") }}</p>
              <p *ngIf="ticket['points']">
                {{ ticket["points"] | number: "":translate.getSessionLanguage() }}
                <span>{{ translate._("EARN") }}</span>
              </p>
            </div>
            <div class="burn-page__wrapper__column__ticket-list__ticket__title">
              <p>{{ ticket?.title }}</p>
              <div
                class="burn-page__wrapper__column__ticket-list__ticket__title__date"
                *ngIf="!ticket.unlocked && utils.lockedDueToDates(ticket)"
              >
                <span *ngIf="ticket?.validFromDate && ticket?.validToDate">
                  {{ ticket?.voucherType === "coupon" ? translate._( "VALID_BETWEEN", ticket?.validFromDate.unixTimeStamp | date:
                  "dd.MM.yy", ticket?.validToDate.unixTimeStamp | date: "dd.MM.yy" ) : "" }}
                </span>
                <span *ngIf="ticket?.validFromDate && !ticket.validToDate">
                  {{ ticket?.voucherType === "coupon" ? translate._("VALID_IN_DAYS", utils.getNumberOfDays(ticket)) : "" }}
                </span>
              </div>
            </div>
            <div
              class="burn-page__wrapper__column__ticket-list__ticket__image"
              [style.backgroundImage]="ticket?.teaserPictureFilePath?.file ? 'url(' + ticket?.teaserPictureFilePath?.file + ')' : ''"
            ></div>
          </ion-item>
        </ion-list>
      </div>
    </div>
  </div>
</ion-content>
<shared-loading *ngIf="redeemLoading"></shared-loading>

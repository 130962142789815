var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Plugins } from "@capacitor/core";
import { ModalController } from "@ionic/angular";
import { COUNTRIES } from "shared/lib/common/constants";
import { AdminService, ToastService, TranslateService, UserService } from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";
export class RegisterPage {
    constructor(t, modal, fb, admin, toast, config, user) {
        this.t = t;
        this.modal = modal;
        this.fb = fb;
        this.admin = admin;
        this.toast = toast;
        this.config = config;
        this.user = user;
        this.countries = [];
    }
    ngOnInit() {
        this.isLoading = false;
        this.countries = COUNTRIES;
        this.client = this.config.getMountingConfig().legalName;
        const SimForm = {
            emailAddress: ["", [Validators.required, Validators.email]],
            declarationOfConsent: [false],
            acceptConsent: [false],
        };
        const ExtForm = Object.assign({}, SimForm, { givenName: ["", Validators.required], familyName: ["", Validators.required], streetHouseNo: ["", Validators.required], zipCode: ["", Validators.required], city: ["", Validators.required], countryCode: ["", Validators.required], genderCode: ["", Validators.required], birthdate: ["", Validators.required], phoneNumber: [""] });
        const SimExtForm = Object.assign({}, SimForm, { givenName: [""], familyName: [""], streetHouseNo: [""], zipCode: [""], city: [""], countryCode: [""], genderCode: [""], birthdate: [""], phoneNumber: [""] });
        switch (this.registrationType) {
            case "SIMEXT":
                this.form = this.fb.group(SimExtForm);
                break;
            case "EXT":
                this.form = this.fb.group(ExtForm);
                break;
            default:
                this.form = this.fb.group(SimForm);
        }
    }
    register() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            if (this.form.valid) {
                const data = {
                    type: this.registrationType,
                    simple: Object.assign({}, this.form.value, { clientOptins: [
                            {
                                clientId: this.config.getMountingConfig().clientId,
                                emailOptin: this.form.get("declarationOfConsent").value,
                                dataOptin: this.form.get("acceptConsent").value,
                            },
                        ], keyCode: this.keyCode, language: this.t.getSessionLanguage(), channelCode: "A", clientId: this.config.getMountingConfig().clientId }),
                    extended: Object.assign({}, this.form.value, { userReferenceCode: this.userReferenceCode, deviceKey: this.config.getMountingConfig().deviceKey }),
                };
                const resp = yield this.admin.register(data);
                if (resp.ok === true) {
                    this.form.reset();
                    const details = yield this.user.getDetails(this.keyCode);
                    if (details.ok === true)
                        this.user.setUser(details.response);
                    else
                        this.toast.show(this.t._(details.error.message), "error");
                    this.dismiss("SUCCESS");
                }
                else {
                    if (resp.error.message === "REGISTRATION_USER_EXISTS_NOT_ACTIVATED") {
                        this.form.reset();
                        this.dismiss("SUCCESS");
                    }
                    this.toast.show(resp.error.message, "error");
                }
            }
            this.isLoading = false;
        });
    }
    dismiss(state) {
        this.modal.dismiss(state);
    }
    open(url) {
        return __awaiter(this, void 0, void 0, function* () {
            const { Browser } = Plugins;
            yield Browser.open({ url });
        });
    }
    buildName(literal, isAlwaysRequired = false) {
        return `${this.t._(literal)}${this.registrationType === "EXT" || isAlwaysRequired ? " *" : ""}`;
    }
    isEnable() {
        return this.registrationType === "EXT" || this.registrationType === "SIMEXT";
    }
}

import { Component, Input } from "@angular/core";

@Component({
  selector: "shared-close",
  templateUrl: "./close.component.html",
  styleUrls: ["./close.component.scss"],
})
export class CloseComponent {
  @Input() public lightColor: false;

  constructor() {}
}

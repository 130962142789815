var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { TranslateService } from "shared/lib/common/services";
import { NavParams, ModalController } from "@ionic/angular";
export class ConfirmationPage {
    constructor(navParams, modal, translate) {
        this.navParams = navParams;
        this.modal = modal;
        this.translate = translate;
    }
    ngOnInit() {
        this.params = this.navParams.data;
    }
    cancelation() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.modal.dismiss(false);
        });
    }
    confirmation() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.modal.dismiss(true);
        });
    }
}

<ion-content scroll-y="false">
  <div
    ion-button
    class="screen-saver__content --{{ config.screenSaver?.message.horizontal }} --{{ config.screenSaver?.message.vertical }}"
    (tap)="config.haveBurnPage() ? goQrScanner() : null"
  >
    <img
      class="screen-saver__content__background"
      [class.--cover]="config.screenSaver?.backgroundCover"
      [class.--show]="actualImage === background"
      [class.--hide]="actualImage !== background"
      [src]="background"
    />
    <img
      *ngFor="let image of images"
      class="screen-saver__content__background"
      [class.--cover]="config.screenSaver?.backgroundCover"
      [class.--show]="actualImage === image.url"
      [class.--hide]="actualImage !== image.url"
      [src]="image.url"
    />
    <div *ngIf="showButtons && config.haveBurnPage()" class="screen-saver__content__text-wrapper">
      <p class="screen-saver__content__text-wrapper__text">
        {{ translate._("BENEFITS_WORLD") }}
      </p>
      <div class="screen-saver__content__text-wrapper__icon">
        <shared-tap></shared-tap>
      </div>
    </div>
  </div>
  <div *ngIf="config?.screenSaver?.showSelectLanguage" class="screen-saver--language-wrapper">
    <div (tap)="translate.setSessionLanguage(translate.getSessionLanguage() === 'en' ? 'de' : 'en')" class="screen-saver--language-switch">
      {{ translate.getSessionLanguage() === "en" ? translate._("GERMAN") : translate._("ENGLISH") }}
    </div>
  </div>
  <img
    *ngFor="let image of images"
    [src]="image.url"
    alt="image"
    class="screen-saver--bg-image"
    (error)="onImageError(image.url)"
    [hidden]="true"
  />
</ion-content>

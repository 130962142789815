var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { SharedRoutes } from "shared/lib/common/enums";
import { RedeemDependencyCode, VoucherType, } from "shared/lib/common/interfaces";
import { ConfirmationPage, } from "shared/lib/common/pages/confirmation/confirmation.page";
import { CouponCardPage } from "shared/lib/v1/pages/coupon-card/coupon-card.page";
import { PrintPage } from "shared/lib/v1/pages/print/print.page";
import { AdminService, PrintService, RoutingService, ToastService, TranslateService, UserService, UtilsService, } from "shared/lib/common/services";
import { RegisterPage } from "shared/lib/common/pages/register/register.page";
import { ConfigService } from "shared/lib/common/services";
import { SuccessPage } from "shared/lib/common/pages/success/success.page";
export class BurnPage {
    constructor(translate, user, utils, modal, toast, config, print, admin, routing) {
        this.translate = translate;
        this.user = user;
        this.utils = utils;
        this.modal = modal;
        this.toast = toast;
        this.config = config;
        this.print = print;
        this.admin = admin;
        this.routing = routing;
    }
    ngOnInit() {
        this.loading = true;
        this.params = this.routing.getUrlParams();
        const width = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue("--max-width-mobile")
            .split("px")[0];
        this.mobileVersion = window.innerWidth < +width;
    }
    ionViewDidEnter() {
        this.status = this.getStatus();
        this.totalPoints = this.user.getUser() ? this.user.getUser().totalPoints : 0;
        this.revenueAmountPayableWithPoints = this.user.getUser() ? this.user.getUser().revenueAmountPayableWithPoints : 0;
        this.coupons = this.user.getCoupons();
        this.tickets = this.sortTickets(this.user.getVouchers());
        this.loading = false;
        this.redeemLoading = false;
        if (this.params) {
            if (this.params.couponId || this.params.rewardId) {
                const ticket = this.findTicket(this.tickets);
                if (ticket) {
                    if (ticket.unlocked) {
                        if (!this.mobileVersion)
                            this.selectTicket(ticket);
                        this.askToRedeem(ticket);
                    }
                    else
                        this.toast.show(this.showUnlockedReason(ticket, this.totalPoints), "info");
                }
                else {
                    this.logOutUser();
                    this.toast.show(this.translate._("VOUCHER_CODE_NOT_FOUND"), "error");
                }
            }
            if (this.params.couponKey) {
                const coupon = this.findCoupon(this.coupons);
                if (coupon) {
                    if (!this.mobileVersion)
                        this.selectTicket(coupon);
                    this.askToRedeem(coupon);
                }
                else {
                    this.logOutUser();
                    this.toast.show(this.translate._("VOUCHER_CODE_NOT_FOUND"), "error");
                }
            }
        }
        this.isRegisterButtonRequired().then(isRequired => (this.showRegisterButton = isRequired));
    }
    isRegisterButtonRequired() {
        return __awaiter(this, void 0, void 0, function* () {
            const resp = yield this.admin.getAllClients();
            if (resp.ok === true) {
                const client = resp.response.find(c => String(c.clientId) === this.config.getMountingConfig().clientId);
                const user = this.user.getUser();
                return client.showRegistrationButton && !!user && !user.isRegistered;
            }
            else {
                this.toast.show(this.translate._(resp.error.message), "error");
                return false;
            }
        });
    }
    askToRedeem(voucher) {
        return __awaiter(this, void 0, void 0, function* () {
            const componentProps = {
                voucher,
                title: this.translate._("SHOW_THIS_COUPON_TO_A_SALES_REP"),
                confirmText: this.translate._("REDEEM_NOW"),
                dismissText: this.translate._("CANCEL"),
            };
            const confirmationModal = yield this.modal.create({
                component: ConfirmationPage,
                cssClass: "modal",
                componentProps,
            });
            yield confirmationModal.present();
            const { data } = (yield confirmationModal.onDidDismiss());
            if (data)
                this.redeem(voucher);
        });
    }
    goHistoryPage() {
        return __awaiter(this, void 0, void 0, function* () {
            this.routing.goForward([SharedRoutes.history]);
        });
    }
    selectTicket(ticket) {
        return __awaiter(this, void 0, void 0, function* () {
            this.ticketSelected = ticket;
            if (this.mobileVersion) {
                const componentProps = {
                    ticketSelected: this.ticketSelected,
                    showRegisterButton: this.showRegisterButton,
                    registerMandatory: this.needToShowRegisterModal(this.ticketSelected),
                };
                const couponModal = yield this.modal.create({
                    component: CouponCardPage,
                    cssClass: "modal",
                    componentProps,
                });
                yield couponModal.present();
                const { data } = (yield couponModal.onDidDismiss());
                if (data)
                    this.askToRedeem(data);
            }
        });
    }
    logOutUser() {
        this.user.removeUser();
        this.routing.goForward([SharedRoutes.screenSaver]);
    }
    showPrintModal(voucher) {
        return __awaiter(this, void 0, void 0, function* () {
            const componentProps = { voucher };
            const printModal = yield this.modal.create({
                component: PrintPage,
                cssClass: "modal",
                componentProps,
                backdropDismiss: false,
            });
            yield printModal.present();
            const { data } = (yield printModal.onDidDismiss());
            return data;
        });
    }
    redeem(voucher) {
        return __awaiter(this, void 0, void 0, function* () {
            this.redeemLoading = true;
            let resp;
            try {
                if (this.config.havePrinter() &&
                    this.print.getIp() &&
                    ((voucher.voucherType === VoucherType.coupon && voucher.couponTypeCode !== "EARN") || voucher.voucherType !== VoucherType.coupon)) {
                    const ok = yield this.showPrintModal(voucher);
                    if (ok) {
                        if (voucher.voucherType === VoucherType.reward)
                            resp = yield this.user.redeemReward(voucher);
                        else
                            resp = yield this.user.redeemCoupon(voucher);
                    }
                }
                else {
                    if (voucher.voucherType === VoucherType.reward)
                        resp = yield this.user.redeemReward(voucher);
                    else
                        resp = yield this.user.redeemCoupon(voucher);
                }
                if (resp && resp.ok === true) {
                    yield this.success(voucher);
                }
                else if (resp && resp.ok === false) {
                    this.toast.show(this.translate._(resp.error.message), "error");
                }
            }
            catch (e) {
                this.toast.show(this.translate._(e.message), "error");
            }
            finally {
                this.redeemLoading = false;
            }
        });
    }
    goToRegister() {
        return __awaiter(this, void 0, void 0, function* () {
            this.registerLoading = true;
            const user = this.user.getUser();
            const resp = yield this.admin.getRegistrationType();
            if (resp.ok === true) {
                yield this.showRegisterModal(user.keyCode, user.userReferenceCode, resp.response);
            }
            else {
                this.toast.show(this.translate._(resp.error.message), "error");
            }
            this.registerLoading = false;
        });
    }
    sortTickets(tickets) {
        const rewards = tickets.filter(t => t.voucherType === VoucherType.reward);
        const coupons = tickets.filter(t => t.voucherType === VoucherType.coupon);
        return [...this.orderByPoints(coupons), ...this.orderByPoints(rewards)];
    }
    orderByPoints(tickets) {
        return tickets.sort((t1, t2) => {
            if (t1.points < t2.points)
                return -1;
            if (t1.points > t2.points)
                return 1;
            return 0;
        });
    }
    needToShowRegisterModal(voucher) {
        const user = this.user.getUser();
        if (!voucher)
            return true;
        return ((voucher.redeemDependencyCode === RedeemDependencyCode.REQUIRED_EASY ||
            voucher.redeemDependencyCode === RedeemDependencyCode.REQUIRED_EXTENDED ||
            voucher.redeemDependencyCode === RedeemDependencyCode.REQUIRED_SIMPLE) &&
            (!!user && !user.isRegistered));
    }
    openRedeemCoupon() {
        return __awaiter(this, void 0, void 0, function* () {
            this.getCouponKeysLoading = true;
            yield this.user.getCouponKeys();
            this.getCouponKeysLoading = false;
            const queryParams = { type: "burn", keycode: (this.user.getUser() || {}).keyCode };
            this.routing.goForward([SharedRoutes.qrScanner], { queryParams });
        });
    }
    success(voucher) {
        return __awaiter(this, void 0, void 0, function* () {
            let points;
            if (voucher.voucherType === VoucherType.coupon && voucher.couponTypeCode === "EARN")
                points = voucher.points;
            else
                points = voucher.points * -1;
            const componentProps = {
                voucher,
                idType: this.params.idType,
                keyCode: (this.user.getUser() || {}).keyCode,
                points,
                title: voucher.voucherType === VoucherType.reward ? this.translate._("REWARD_REDEEMED") : this.translate._("COUPON_REDEEMED"),
            };
            const successModal = yield this.modal.create({
                component: SuccessPage,
                cssClass: "modal",
                componentProps,
                backdropDismiss: false,
            });
            yield successModal.present();
            this.redeemLoading = false;
        });
    }
    showRegisterModal(keyCode, userReferenceCode, registrationType) {
        return __awaiter(this, void 0, void 0, function* () {
            const componentProps = {
                registrationType,
                keyCode,
                userReferenceCode,
            };
            const registerModal = yield this.modal.create({
                component: RegisterPage,
                cssClass: "modal modal--full-screen",
                componentProps,
            });
            yield registerModal.present();
            return (yield registerModal.onDidDismiss());
        });
    }
    getStatus() {
        let resp;
        const user = this.user.getUser();
        if (user && user.statusDefinitions) {
            user.statusDefinitions.forEach(status => {
                if (!resp || (resp.points < status.points && status.unlocked)) {
                    resp = status;
                }
            });
        }
        return resp;
    }
    showUnlockedReason(ticket, neededPoints) {
        if (ticket.points > neededPoints)
            return this.translate._("NEED_MORE_POINTS");
        const now = new Date().getTime();
        if (ticket.voucherType === VoucherType.coupon) {
            if (ticket.validFromDate && now < new Date(ticket.validFromDate.unixTimeStamp).getTime()) {
                return this.translate._("VALID_FROM_ERROR", new Date(ticket.validFromDate.unixTimeStamp).toLocaleDateString());
            }
            if (ticket.validToDate && now > new Date(ticket.validToDate.unixTimeStamp).getTime()) {
                return this.translate._("VALID_TO_ERROR", new Date(ticket.validToDate.unixTimeStamp).toLocaleDateString());
            }
        }
        return this.translate._("LOCKED_VOUCHER");
    }
    findTicket(tickets) {
        return tickets.find(({ voucherId, accountVoucherId }) => {
            if (voucherId && accountVoucherId) {
                return (String(voucherId) === String(this.params.couponId) ||
                    String(voucherId) === String(this.params.rewardId) ||
                    accountVoucherId === String(this.params.couponId) ||
                    accountVoucherId === String(this.params.rewardId));
            }
            if (accountVoucherId) {
                return accountVoucherId === String(this.params.couponId) || accountVoucherId === String(this.params.rewardId);
            }
            if (voucherId) {
                return String(voucherId) === String(this.params.couponId) || String(voucherId) === String(this.params.rewardId);
            }
        });
    }
    findCoupon(coupons) {
        return (coupons || []).find(c => {
            return !!(c.couponKeys || []).find(couponKeys => {
                return couponKeys.keyCode === this.params.couponKey;
            });
        });
    }
}

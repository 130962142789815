export enum VERSION {
  v1 = "v1",
  v2 = "v2",
}

export enum LOCAL_STORAGE {
  PIN_CODE = "pinCode",
  APIKEY = "ak",
  EMAIL = "email",
  PASSWORD = "password",
  ORGANIZATION = "organization",
  LANGUAGE = "language",
  SESSION_LANGUAGE = "sessionLanguage",
  KEY = "k",
  MOUNTING_APIKEY = "mak",
  MOUNTING_CONFIG = "mountingConfig",
  IP = "ip",
  MANUAL_ALLOWED = "manualAllowed",
  TOKEN = "token",
  STORE = "store",
  STORE2 = "store2",
  PARTNER = "partner",
  MOUNTING_ID = "mountingId",
  DEVICE_KEY = "deviceKey",
}

export enum P4M {
  GET_USER_DETAILS = "GetUserDetails",
  GET_USER_HISTORY = "GetUserHistory",
  VALIDATE_LOGIN = "ValidateLogin",
  REDEEM_COUPON = "RedeemCoupon",
  REDEEM_REWARD = "RedeemReward",
  BOOK_POINTS = "BookPoints",
  LOGIN = "auth-admin/login",
  ASSIGN_COUPONS = "assign-coupon/coupon/assign",
  GET_COUPONS = "GetCoupons",
  GET_COUPON_KEYS = "GetCouponKeys",
  GET_REWARDS = "GetRewards",
  GET_ALL_CLIENTS = "GetAllClients",
  DEVICE_MANAGEMENT = "DeviceManagementAuthentication",
  GET_STORES = "GetStoresOfClient",
  DOWNLOAD_DISPLAY_ADS = "DownloadDisplayAds",
  MOUNT_DEVICE = "MountDevice",
  UN_MOUNT_DEVICE = "UnMountDevice",
  LOGIN_APIKEY = "Login",
  GET_PERMISSION_USER = "GetPermissionUser",
  GET_ACTION_DEFINITIONS = "GetActionDefinitions",
  EXTENDED_REVENUE_POINTS_MANUALLY = "ExtendedRevenuePointsManually",
  REVENUE_POINTS = "RevenuePoints",
  REGISTER = "Register",
  UPDATE_PROFILE_EXTENDED_REGISTRATION = "UpdateProfileExtendedRegistration",
  ACCOUNTS = "accounts",
  BOOKINGS = "bookings",
  ACCOUNT_COUPONS = "accountcoupons",
  BALANCE = "balance",
  USERS = "users",
  COUPONS = "coupons",
  MEMBERSHIPS = "memberships",
  ACCOUNT_DETAILS = "accountdetails",
  LOGIN_V2 = "auth/login",
  PROGRAMS = "programs",
  PARTNERS = "partners",
  STORES = "stores",
  IDENTIFIERS = "identifiers",
  LEVEL = "level",
  CHECK_IN = "CheckIn",
}

export type REQUEST =
  | "getUserDetails"
  | "getUserHistory"
  | "login"
  | "redeemCoupon"
  | "redeemReward"
  | "bookPoints"
  | "assignCoupon"
  | "getCoupons"
  | "getRewards"
  | "deviceManagementLogin"
  | "getAllClients"
  | "getStoresOfClient"
  | "downloadDisplayAds"
  | "mountDevice"
  | "unMountDevice"
  | "loginToGetApiKey"
  | "getPermissionUser"
  | "extendedRevenuePointsManually"
  | "getActionDefinitions"
  | "revenuePoints"
  | "register"
  | "updateProfileExtendedRegistration"
  | "readAccountBalance"
  | "checkIn"
  | "readUser";

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { PrintService, TranslateService, UtilsService } from "shared/lib/common/services";
export class PrintPage {
    constructor(translate, modal, print, navParams, utils) {
        this.translate = translate;
        this.modal = modal;
        this.print = print;
        this.navParams = navParams;
        this.utils = utils;
    }
    ngOnInit() {
        this.params = this.navParams.data;
        this.printerStatus = "printing";
        this.printTicket();
    }
    dismiss() {
        this.modal.dismiss(this.printerStatus === "success");
    }
    printTicket() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.print.printTicket(this.params.voucher);
            if (response.ok === true) {
                this.printerStatus = "success";
            }
            else {
                if (response.error.message === "NOT_CONNECTED") {
                    this.printerStatus = "no_conection";
                }
                else {
                    this.printerStatus = "no_paper";
                }
            }
            yield this.utils.waiting(10000);
            this.dismiss();
        });
    }
}

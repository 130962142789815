var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EnvironmentService, P4mService } from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";
export class DeviceTestPage {
    constructor(config, p4m, environment) {
        this.config = config;
        this.p4m = p4m;
        this.environment = environment;
    }
    ionViewDidEnter() {
        return __awaiter(this, void 0, void 0, function* () {
            this.releaseVersion = this.environment.getVariable("RELEASE");
            this.organisation = this.config.getOrganization();
            this.mountingUser = this.p4m.getUser();
            navigator.getUserMedia({ video: true }, _ => (this.cameraPermissionState = "granted"), _ => (this.cameraPermissionState = "denied"));
        });
    }
}

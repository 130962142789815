import { Component } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { AppComponent as SharedAppComponent } from "shared/lib/v1/components";
import {
  InactivityService,
  PosConnectionService,
  RoutingService,
  SentryService,
  ThirdPartyScriptsService,
  TranslateService,
  UserService,
  UtilsService,
} from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";

@Component({
  selector: "app-root",
  templateUrl: "../../../../shared/lib/v1/components/app-component/app.component.html",
})
export class AppComponent extends SharedAppComponent {
  constructor(
    protected readonly _inactivity: InactivityService,
    protected readonly _modalCtrl: ModalController,
    protected readonly _user: UserService,
    protected readonly _routing: RoutingService,
    protected readonly _config: ConfigService,
    protected readonly _posConnectionService: PosConnectionService,
    protected readonly _thirdParty: ThirdPartyScriptsService,
    protected readonly _utils: UtilsService,
    protected readonly _sentry: SentryService,
    protected readonly _translate: TranslateService,
    protected readonly _platform: Platform,
  ) {
    super(_inactivity, _modalCtrl, _user, _routing, _config, _posConnectionService, _thirdParty, _utils, _sentry, _translate, _platform);
  }
}

import * as crypto from "crypto-js";
import * as i0 from "@angular/core";
const ONE_DAY_MILI_SECONDS = 1000 * 60 * 60 * 24;
const ENCRYPT_KEY = "76d44500-e9be-48d4-86be-9236308fc57c";
export class UtilsService {
    constructor() { }
    waiting(time = 2000) {
        return new Promise(resolve => {
            const timeout = setTimeout(() => {
                clearTimeout(timeout);
                resolve("");
            }, time);
        });
    }
    encrypt(text) {
        return crypto.AES.encrypt(JSON.stringify(text), ENCRYPT_KEY).toString();
    }
    decrypt(text) {
        if (text && text !== "") {
            try {
                return JSON.parse(crypto.AES.decrypt(text, ENCRYPT_KEY).toString(crypto.enc.Utf8));
            }
            catch (e) {
                return crypto.AES.decrypt(text, ENCRYPT_KEY).toString(crypto.enc.Utf8);
            }
        }
        else {
            return null;
        }
    }
    generateRandom() {
        return crypto.MD5(Date.now().toString()).toString();
    }
    parseDate(date) {
        return date ? Math.round(new Date(date).getTime()) : null;
    }
    getNumberOfDays(coupon) {
        const miliSecondsToUnlock = coupon.validFromDate.unixTimeStamp - new Date().getTime();
        return Math.ceil(miliSecondsToUnlock / ONE_DAY_MILI_SECONDS);
    }
    lockedDueToDates(ticket) {
        return ticket.validFromDate
            ? this.isAfterNow(ticket.validFromDate.unixTimeStamp)
            : false || ticket.validToDate
                ? this.isBeforeNow(ticket.validToDate.unixTimeStamp)
                : false;
    }
    isBeforeNow(date) {
        return new Date().getTime() >= date;
    }
    isAfterNow(date) {
        return new Date().getTime() <= date;
    }
    isBetweenDates(start, end) {
        const now = new Date().getTime();
        return now >= start && now <= end;
    }
    decodeHTMLEntity(str) {
        const element = document.createElement("p");
        element.innerHTML = str;
        return element.textContent;
    }
    getReceiptFormatDate(date) {
        const [day, month, year] = date.toLocaleDateString("DE").split(".");
        const [hour, minutes] = date.toLocaleTimeString("DE").split(":");
        return `${year}${month.padStart(2, "0")}${day.padStart(2, "0")}-${hour.padStart(2, "0")}${minutes.padStart(2, "0")}`;
    }
}
UtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(); }, token: UtilsService, providedIn: "root" });

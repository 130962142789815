var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ToastController } from "@ionic/angular";
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export class ToastService {
    constructor(toastController) {
        this.toastController = toastController;
    }
    show(message, kind, duration = 3000, position = "top") {
        return __awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                message,
                duration,
                color: kind === "error" ? "danger" : "primary",
                cssClass: `-is-${kind}-toast`,
                translucent: true,
                position,
            });
            toast.present();
        });
    }
}
ToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToastService_Factory() { return new ToastService(i0.ɵɵinject(i1.ToastController)); }, token: ToastService, providedIn: "root" });

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { CheckPinModalPage, ICheckPinModalPageInput, ICheckPinModalPageOutput } from "shared/lib/common/pages";
import { AdminService, ToastService, TranslateService } from "shared/lib/common/services";

@Injectable({
  providedIn: "root",
})
export class IsPinAddedGuard implements CanActivate {
  constructor(
    private modal: ModalController,
    private admin: AdminService,
    private toast: ToastService,
    private translate: TranslateService,
  ) {}

  public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const resp = this.admin.getClientStore();
    if (resp.ok === true) {
      if (resp.response && resp.response.usesPin) {
        return await this.showCheckPinModal(resp.response.pins[0] ? resp.response.pins[0].pin : "");
      } else {
        return true;
      }
    } else {
      this.toast.show(this.translate._(resp.error.message as any), "error");
      return false;
    }
  }

  private async showCheckPinModal(pin: string): Promise<boolean> {
    const componentProps: ICheckPinModalPageInput = {
      pin,
    };
    const checkPinModal = await this.modal.create({
      component: CheckPinModalPage,
      cssClass: "modal modal--full-screen",
      componentProps,
    });
    await checkPinModal.present();
    const { data } = (await checkPinModal.onDidDismiss()) as ICheckPinModalPageOutput;
    return data;
  }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { AdminService, ToastService, TranslateService } from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";
export class EnsureUnmountLoginModal {
    constructor(translate, fb, admin, config, toast, modal) {
        this.translate = translate;
        this.fb = fb;
        this.admin = admin;
        this.config = config;
        this.toast = toast;
        this.modal = modal;
    }
    ngOnInit() {
        this.form = this.fb.group({
            email: ["", [Validators.required]],
            password: ["", [Validators.required]],
        });
    }
    ionViewWillEnter() {
        this.form.reset();
        this.loading = false;
        this.showPassword = false;
    }
    changeShowPassword() {
        this.showPassword = !this.showPassword;
    }
    updateKeyboardStatus(isActive) {
        this.isKeyboardActive = isActive;
    }
    back() {
        this.modal.dismiss();
    }
    login(email, password) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            const resp = yield this.admin.adminLogin(email, this.config.getOrganization(), password);
            if (resp.ok === true) {
                this.modal.dismiss({ email, password });
            }
            else {
                this.toast.show(this.translate._(resp.error.message), "error");
            }
            this.loading = false;
        });
    }
    checkErrors(formControlName) {
        const control = this.form.get(formControlName);
        return control.invalid && control.touched;
    }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { SharedRoutes } from "shared/lib/common/enums";
import { AdminService, RoutingService, TranslateService, UserService, UtilsService } from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";
export class ScreenSaverPage {
    constructor(user, translate, config, admin, utils, routing) {
        this.user = user;
        this.translate = translate;
        this.config = config;
        this.admin = admin;
        this.utils = utils;
        this.routing = routing;
    }
    ngOnInit() {
        this.images = [];
        this.initSlide();
        this.background = "assets/screen-saver/default.jpg";
        this.actualImage = "assets/screen-saver/default.jpg";
    }
    ionViewDidEnter() {
        return __awaiter(this, void 0, void 0, function* () {
            this.admin.fetchStoresOfClient(this.config.getMountingConfig().clientId);
            const images = yield this.getImages();
            this.images = [...this.images, ...images.filter(({ url }) => this.images.findIndex(img => img.url === url) < 0)];
            this.translate.setSessionLanguage();
            this.user.removeUser();
            this.languageOptions = { header: "Select language" };
            this.showButtons = !this.config.screenSaver || (this.config.screenSaver && this.config.screenSaver.message.show);
        });
    }
    goQrScanner() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParams = { type: "burn" };
            this.routing.goForward([SharedRoutes.qrScanner], { queryParams });
        });
    }
    onImageError(erroredUrl) {
        this.images = this.images.filter(({ url }) => url !== erroredUrl);
    }
    initSlide() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.slideActive) {
                this.slideActive = true;
                this.backgroundSlide();
            }
        });
    }
    backgroundSlide() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.images.length > 0) {
                for (let i = 0; i < this.images.length; i++) {
                    this.actualImage = this.images[i].url;
                    yield this.utils.waiting(this.images[i].duration);
                }
            }
            else {
                this.actualImage = this.background;
                yield this.utils.waiting(5000);
            }
            this.backgroundSlide();
        });
    }
    getImages() {
        return __awaiter(this, void 0, void 0, function* () {
            const resp = [];
            const response = yield this.admin.getDisplayAds(this.config.getMountingConfig().deviceKey);
            if (response.ok === true) {
                response.response.forEach(displayAd => {
                    if (displayAd.displayAdUsageCode === "PRINTER_LOGO") {
                        this.config.setPrinterLogo(displayAd.pictureFilePath);
                    }
                    else {
                        resp.push({
                            url: displayAd.pictureFilePath,
                            duration: displayAd.displayDurationMsec,
                        });
                    }
                });
            }
            return resp;
        });
    }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "../../components/close/close.component.ngfactory";
import * as i5 from "../../components/close/close.component";
import * as i6 from "../../components/revenue-module/revenue-module.component.ngfactory";
import * as i7 from "../../components/revenue-module/revenue-module.component";
import * as i8 from "../../../common/services/translate/translate.service";
import * as i9 from "../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i10 from "angular-feather";
import * as i11 from "./admin.page";
import * as i12 from "../../../common/services/admin/admin.service";
import * as i13 from "../../../common/services/user/user.service";
import * as i14 from "../../../common/services/config/config.service";
import * as i15 from "../../../common/services/toast/toast.service";
import * as i16 from "../../../common/services/routing/routing.service";
import * as i17 from "../../../common/services/environment/environment.service";
var styles_AdminPage = [i0.styles];
var RenderType_AdminPage = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminPage, data: {} });
export { RenderType_AdminPage as RenderType_AdminPage };
export function View_AdminPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "ion-content", [["scroll-y", "false"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 13, "div", [["class", "page-wrapper admin"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "shared-close", [], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.goToScreenSaver() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CloseComponent_0, i4.RenderType_CloseComponent)), i1.ɵdid(4, 49152, null, 0, i5.CloseComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "admin__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "shared-revenue-module", [], null, [[null, "backEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backEvent" === en)) {
        var pd_0 = (_co.goToQrScanner($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_RevenueModuleComponent_0, i6.RenderType_RevenueModuleComponent)), i1.ɵdid(7, 114688, null, 0, i7.RevenueModuleComponent, [i8.TranslateService], { promotions: [0, "promotions"], revenueRounding: [1, "revenueRounding"], pointsRounding: [2, "pointsRounding"], revenueMode: [3, "revenueMode"], mode: [4, "mode"] }, { backEvent: "backEvent" }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [["class", "admin__content__version"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "ion-button", [["class", "button--dark admin__button-configurations"], ["color", "text"], ["fill", "clear"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.goToConfigurations() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(12, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], fill: [1, "fill"] }, null), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "i-settings", [["class", "admin__button-configurations__icon"]], null, null, null, i9.View_ɵhv_0, i9.RenderType_ɵhv)), i1.ɵdid(14, 49152, null, 0, i10.ɵhv, [], null, null), (_l()(), i1.ɵted(15, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.promotions; var currVal_1 = _co.revenueRounding; var currVal_2 = _co.pointsRounding; var currVal_3 = _co.revenueMode; var currVal_4 = "extended"; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = "text"; var currVal_7 = "clear"; _ck(_v, 12, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.releaseVersion; _ck(_v, 9, 0, currVal_5); var currVal_8 = _co.translate._("SETTINGS"); _ck(_v, 15, 0, currVal_8); }); }
export function View_AdminPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-v1-admin", [], null, null, null, View_AdminPage_0, RenderType_AdminPage)), i1.ɵdid(1, 114688, null, 0, i11.AdminPage, [i8.TranslateService, i3.Platform, i12.AdminService, i13.UserService, i14.ConfigService, i15.ToastService, i16.RoutingService, i17.EnvironmentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminPageNgFactory = i1.ɵccf("shared-v1-admin", i11.AdminPage, View_AdminPage_Host_0, {}, {}, []);
export { AdminPageNgFactory as AdminPageNgFactory };

import { AccountService as ConvercusAccountService, Configuration, TransactionData, LineItemData, } from "shared/lib/v2/apis/accounts";
import { HttpClient } from "@angular/common/http";
import { UtilsService, ConfigService, EnvironmentService } from "shared/lib/common/services";
import * as i0 from "@angular/core";
import * as i1 from "../../../common/services/config/config.service";
import * as i2 from "../../../common/services/utils/utils.service";
import * as i3 from "@angular/common/http";
import * as i4 from "../../apis/accounts/variables";
import * as i5 from "../../apis/accounts/configuration";
import * as i6 from "../../../common/services/environment/environment.service";
export class AccountService extends ConvercusAccountService {
    constructor(config, utils, httpClient, basePath, configuration, environmentService) {
        super(httpClient, environmentService.getVariable("API_V2"), configuration);
        this.config = config;
        this.utils = utils;
        this.httpClient = httpClient;
        this.environmentService = environmentService;
    }
    incentivateTransaction(accountId, interactionId, transactionId, idType) {
        return super.incentivateTransaction(accountId, interactionId, transactionId, idType || "CARDCODE");
    }
    createTransaction(accountId, interactionId, externalId, idType, body) {
        const store = this.config.getMountingConfig().store;
        return super.addTransaction(accountId, interactionId, idType || "CARDCODE", Object.assign({ externalId: externalId || `${Number(body.amount).toFixed(2)}_${store ? store.vatId : store}_${this.utils.getReceiptFormatDate(new Date())}` }, body));
    }
    createTransactionPrev(accountId, interactionId, idType, body) {
        return super.createTransactionPreview(accountId, interactionId, idType || "CARDCODE", body);
    }
    getTransactionData(revenues, type) {
        return {
            amount: this.get(revenues, "money"),
            transactionType: type,
            lineItems: revenues.map((r, i) => {
                return {
                    extendedAmount: r.money,
                    itemID: r.id,
                    merchandiseGroupCode: r.groupCode || undefined,
                    merchandiseSubGroupCode: r.subGroupCode || undefined,
                    description: r.name,
                    sequenceNumber: i + 1,
                    type: r.money > 0 ? LineItemData.TypeEnum.SALE : LineItemData.TypeEnum.RETURN,
                };
            }),
        };
    }
    filterRevenues(revenues, type) {
        return revenues.filter(r => (type === "EARNTRANSACTION" ? !r.unitType : r.unitType === type));
    }
    get(revenues, type) {
        let total = 0;
        (revenues || []).forEach(r => {
            total += r[type];
        });
        return total;
    }
}
AccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.UtilsService), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.BASE_PATH, 8), i0.ɵɵinject(i5.Configuration, 8), i0.ɵɵinject(i6.EnvironmentService)); }, token: AccountService, providedIn: "root" });

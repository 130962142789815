import { VERSION } from "shared/lib/common/enums";
import { IConfig } from "shared/lib/common/interfaces";

export const CONFIGURATION: IConfig = {
  screen_saver: {
    showSelectLanguage: true,
    backgroundCover: true,
    message: { show: true, horizontal: "center", vertical: "bottom" },
  },
  logo_url: "/assets/app-logo/pfm.svg",
  register_form: "",
  organization: "pfm",
  p4m_api: { default: VERSION.v1 },
  showSelfServiceButton: true,
  ionic_app_id: "5ab44987",
  inactivity_time: 60,
  is_custom_app: true,
};

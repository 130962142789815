/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Action { 
    actionType: Action.ActionTypeEnum;
    value: string;
}
export namespace Action {
    export type ActionTypeEnum = 'FIXEDPOINTACTION' | 'MULTIPLIERACTION' | 'DISCOUNTACTION' | 'NOMINALDISCOUNTACTION';
    export const ActionTypeEnum = {
        FIXEDPOINTACTION: 'FIXEDPOINTACTION' as ActionTypeEnum,
        MULTIPLIERACTION: 'MULTIPLIERACTION' as ActionTypeEnum,
        DISCOUNTACTION: 'DISCOUNTACTION' as ActionTypeEnum,
        NOMINALDISCOUNTACTION: 'NOMINALDISCOUNTACTION' as ActionTypeEnum
    };
}



/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./close.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "angular-feather";
import * as i5 from "./close.component";
var styles_CloseComponent = [i0.styles];
var RenderType_CloseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CloseComponent, data: {} });
export { RenderType_CloseComponent as RenderType_CloseComponent };
export function View_CloseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "i-x", [["class", "close__icon"]], null, null, null, i2.View_ɵkk_0, i2.RenderType_ɵkk)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "--light": 0 }), i1.ɵdid(5, 49152, null, 0, i4.ɵkk, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "close__icon"; var currVal_1 = _ck(_v, 4, 0, _co.lightColor); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_CloseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-close", [], null, null, null, View_CloseComponent_0, RenderType_CloseComponent)), i1.ɵdid(1, 49152, null, 0, i5.CloseComponent, [], null, null)], null, null); }
var CloseComponentNgFactory = i1.ɵccf("shared-close", i5.CloseComponent, View_CloseComponent_Host_0, { lightColor: "lightColor" }, {}, []);
export { CloseComponentNgFactory as CloseComponentNgFactory };

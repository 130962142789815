import { ErrorHandler } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { CONFIGURATION } from "shared/lib/common/constants";
import { ConfigService } from "../config/config.service";
import { Platform } from "@ionic/angular";
import { EnvironmentService } from "../environment/environment.service";
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
import * as i2 from "@ionic/angular";
import * as i3 from "../environment/environment.service";
const SENTRY_DSN = "https://6c69381642e9499cb7cb8aa23b1d5aa9@sentry.io/1364464";
export class SentryService extends ErrorHandler {
    constructor(config, platform, environment) {
        super();
        this.config = config;
        this.platform = platform;
        this.environment = environment;
        Sentry.init({
            release: `${this.platform.is("capacitor") ? "ios" : "web"}-point4more-pos-app@${this.environment.getVariable("RELEASE")}`,
            dsn: SENTRY_DSN,
            environment: this.environment.getVariable("BUILD_ENV"),
            attachStacktrace: true,
            enabled: this.environment.getVariable("BUILD_ENV") !== "local",
        });
        Sentry.configureScope(scope => {
            scope.setTag("app", this.config.getOrganization());
            scope.setTag("api-version", CONFIGURATION.p4m_api.login ? CONFIGURATION.p4m_api.login : CONFIGURATION.p4m_api.default);
        });
    }
    handleError(error, captureContext) {
        super.handleError(error);
        try {
            if (/Loading chunk [\d]+ failed/.test(error.message))
                window.location.reload();
            else
                Sentry.captureException(error.originalError || error, captureContext);
        }
        catch (e) {
            console.error(e);
        }
    }
    info(data, level = Sentry.Severity.Info) {
        Sentry.captureMessage(JSON.stringify(data), level);
    }
    breadcrumb(message = "", type = "navigation", level = Sentry.Severity.Log, data = {}) {
        Sentry.addBreadcrumb({ message, level, type, data });
    }
    addTag(key, value) {
        Sentry.configureScope(scope => {
            scope.setTag(key, value);
        });
    }
    removeTag(key) {
        Sentry.configureScope(scope => {
            scope.setTag(key, null);
        });
    }
}
SentryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SentryService_Factory() { return new SentryService(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.Platform), i0.ɵɵinject(i3.EnvironmentService)); }, token: SentryService, providedIn: "root" });

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpClient } from "@angular/common/http";
import { SharedRoutes, LOCAL_STORAGE } from "shared/lib/common/enums";
import { UtilsService, ConfigService, EnvironmentService, SentryService } from "shared/lib/common/services";
import { UAParser } from "ua-parser-js";
import * as uuid from "uuid";
import { ProgramService } from "../program/program.service";
import { MountingsService } from "../mounting/mounting.service";
import { DevicesService } from "../devices/devices.service";
import { Severity } from "@sentry/browser";
import * as i0 from "@angular/core";
import * as i1 from "../program/program.service";
import * as i2 from "../mounting/mounting.service";
import * as i3 from "../devices/devices.service";
import * as i4 from "../../../common/services/utils/utils.service";
import * as i5 from "@angular/common/http";
import * as i6 from "../../../common/services/config/config.service";
import * as i7 from "../../../common/services/environment/environment.service";
import * as i8 from "../../../common/services/sentry/sentry.service";
export class DeviceMountingService {
    constructor(programService, mountingService, deviceService, utils, httpClient, config, environment, sentry) {
        this.programService = programService;
        this.mountingService = mountingService;
        this.deviceService = deviceService;
        this.utils = utils;
        this.httpClient = httpClient;
        this.config = config;
        this.environment = environment;
        this.sentry = sentry;
    }
    get profile() {
        if (!this.mounting)
            this.unmount();
        return this.mounting ? this.mounting.profile : null;
    }
    get images() {
        return this.deviceImages;
    }
    set mountingId(mountingId) {
        localStorage.setItem(LOCAL_STORAGE.MOUNTING_ID, this.utils.encrypt(mountingId.toString()));
    }
    get mountingId() {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MOUNTING_ID));
    }
    set deviceKey(deviceKey) {
        localStorage.setItem(LOCAL_STORAGE.DEVICE_KEY, this.utils.encrypt(deviceKey));
    }
    get deviceKey() {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.DEVICE_KEY));
    }
    mountingControllerCreateOneBase(mounting) {
        return this.mountingService.mountingControllerCreateOneBase(mounting);
    }
    unmount() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.mountingService
                    .mountingControllerUpdateOne(this.mountingId, {
                    deviceKey: null,
                    organization: this.config.getOrganization(),
                })
                    .toPromise();
                yield this.deviceService.deviceControllerDeleteOne(this.deviceKey).toPromise();
                this.sentry.handleError({
                    status: 200,
                    message: JSON.stringify(Object.assign({}, this.config.getMountingConfig(), { messageType: "", messageData: "", function: "unmount", file: "shared/lib/v2/services/device-mounting", unmountDatetime: new Date().toISOString() })),
                }, { level: Severity.Log });
                localStorage.clear();
                window.location.href = SharedRoutes.login;
            }
            catch (error) { }
        });
    }
    mount(appInteractionId, deviceInformation = "") {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const mounting = yield this.getMountingFromApi(appInteractionId);
                if (mounting) {
                    if (!mounting.deviceKey) {
                        const parser = new UAParser();
                        const { deviceKey } = yield this.deviceService
                            .deviceControllerCreateOneBase({
                            os: parser.getOS().name,
                            osVersion: parser.getOS().version,
                            browser: `${parser.getBrowser().name} ${parser.getBrowser().version}`,
                            deviceInformation,
                            connected: false,
                            version: this.environment.getVariable("RELEASE"),
                            deviceKey: uuid.v1(),
                            organization: this.config.getOrganization(),
                        })
                            .toPromise();
                        const resp = yield this.mountingService
                            .mountingControllerUpdateOne(mounting.id, { deviceKey, organization: this.config.getOrganization() })
                            .toPromise();
                        this.mountingId = mounting.id;
                        this.mounting = mounting;
                        this.deviceKey = deviceKey;
                        return {
                            ok: true,
                            response: Object.assign({}, mounting, { deviceKey, appInteractionId: resp.appInteractionId, cashInteractionId: resp.cashInteractionId }),
                        };
                    }
                    else
                        return { ok: false, error: "MOUNTING_IN_USE" };
                }
                else
                    return { ok: false, error: "MOUNTING_NOT_FOUND" };
            }
            catch (error) {
                return { ok: false, error: error };
            }
        });
    }
    getMounting() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const mounting = yield this.getMountingFromApi();
                if (mounting) {
                    this.mountingId = mounting.id;
                    this.mounting = mounting;
                    this.deviceKey = mounting.deviceKey;
                    return { ok: true, response: mounting };
                }
                else
                    return { ok: false, error: "MOUNTING_NOT_FOUND" };
            }
            catch (error) {
                return { ok: false, error: error.message || error };
            }
        });
    }
    parseMountingConfigV1ToV2(mounting) {
        return __awaiter(this, void 0, void 0, function* () {
            let store;
            if (mounting.store) {
                store = yield this.programService.getStore(mounting.partnerId, mounting.organization, mounting.store).toPromise();
            }
            return {
                workstationId: mounting.appInteractionId,
                deviceId: null,
                deviceKey: mounting.deviceKey,
                cameraPosition: mounting.profile.cameraPosition,
                clientId: "1234",
                clientName: null,
                posMode: mounting.profile.mode,
                storeId: Number(mounting.store),
                storeName: null,
                deviceInformation: null,
                partner: { id: mounting.partnerId, name: "", status: "" },
                store,
                legalName: null,
                appInteractionId: mounting.appInteractionId,
                cashInteractionId: mounting.cashInteractionId,
            };
        });
    }
    getMountingFromApi(appInteractionId) {
        return __awaiter(this, void 0, void 0, function* () {
            let baseUrl = `${this.environment.getVariable("API_MOUNTING")}/mounting-service/mountings`;
            let params = null;
            let mounting;
            if (this.mountingId) {
                baseUrl += `/${this.mountingId}`;
            }
            if (appInteractionId) {
                params = { filter: `appInteractionId||$eq||${appInteractionId}` };
            }
            mounting = appInteractionId
                ? (yield this.httpClient.get(baseUrl, { params }).toPromise())[0]
                : yield this.httpClient.get(baseUrl, { params }).toPromise();
            if (mounting && mounting.profileId)
                mounting.profile = yield this.getMountingProfile(mounting.profileId);
            if (mounting)
                this.deviceImages = yield this.getDeviceImages(mounting);
            return mounting;
        });
    }
    getMountingProfile(profileId) {
        return this.httpClient
            .get(`${this.environment.getVariable("API_MOUNTING")}/mounting-service/profiles/${profileId}`)
            .toPromise();
    }
    getDeviceImages(mounting) {
        const or = [];
        if (mounting.organization)
            or.push(`programs.id||$eq||${mounting.organization}`);
        if (mounting.partnerId)
            or.push(`partners.id||$eq||${mounting.partnerId}`);
        if (mounting.store)
            or.push(`stores.id||$eq||${mounting.store}`);
        if (mounting.profileId)
            or.push(`profiles.id||$eq||${mounting.profileId}`);
        return this.httpClient
            .get(`${this.environment.getVariable("API_MOUNTING")}/mounting-service/images`, {
            params: { join: ["programs", "partners", "stores", "profiles"], or },
        })
            .toPromise();
    }
}
DeviceMountingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeviceMountingService_Factory() { return new DeviceMountingService(i0.ɵɵinject(i1.ProgramService), i0.ɵɵinject(i2.MountingsService), i0.ɵɵinject(i3.DevicesService), i0.ɵɵinject(i4.UtilsService), i0.ɵɵinject(i5.HttpClient), i0.ɵɵinject(i6.ConfigService), i0.ɵɵinject(i7.EnvironmentService), i0.ɵɵinject(i8.SentryService)); }, token: DeviceMountingService, providedIn: "root" });

<div class="coupon-card">
  <div *ngIf="!ticketSelected" class="coupon-card__empty-content">
    <div class="coupon-card__empty-content__description">
      <span class="coupon-card__empty-content__description__text">{{ translate._("SELECT_VOUCHER") }}</span>
      <i-corner-down-right class="coupon-card__empty-content__description__icon"></i-corner-down-right>
    </div>
    <div class="coupon-card__empty-content__register" *ngIf="showRegisterButton">
      <div class="coupon-card__empty-content__register__line"></div>
      <span class="coupon-card__empty-content__register__text">{{ translate._("REGISTER_NOW_DESCRIPTION") }}</span>
      <ion-button class="button button--primary coupon-card__empty-content__register__button" expand="block" (click)="goToRegister()">
        <ion-spinner *ngIf="loading" name="dots"></ion-spinner>
        <i-user-check *ngIf="!loading"></i-user-check>
        <p *ngIf="!loading" class="coupon-card__empty-content__register__button__text">{{ translate._("REGISTER_NOW") }}</p>
      </ion-button>
    </div>
  </div>
  <div *ngIf="ticketSelected" class="coupon-card__content">
    <img
      class="coupon-card__content__image"
      [src]="ticketSelected?.contentPictureFilePath?.file"
      [class.--teaser-image]="ticketSelected?.contentPictureFilePath?.file && !ticketSelected?.contentPictureFilePath?.isCorrectPicture"
      [alt]="''"
    />
    <div class="coupon-card__content__description">
      <h3 class="coupon-card__content__description__title">
        {{ ticketSelected?.title }}
      </h3>
      <p
        class="coupon-card__content__description__validation"
        *ngIf="
          ticketSelected?.validFromDate &&
          ticketSelected?.validFromDate.unixTimeStamp &&
          ticketSelected?.validToDate &&
          ticketSelected?.validToDate.unixTimeStamp
        "
      >
        {{
          ticketSelected?.voucherType === "coupon"
            ? translate._(
                "VALID_BETWEEN",
                ticketSelected?.validFromDate.unixTimeStamp | date: "dd.MM.yy",
                ticketSelected?.validToDate.unixTimeStamp | date: "dd.MM.yy"
              )
            : ""
        }}
      </p>
      <p
        class="coupon-card__content__description__validation"
        *ngIf="
          ticketSelected?.validToDate &&
          ticketSelected?.validToDate.unixTimeStamp &&
          (!ticketSelected?.validFromDate || !ticketSelected?.validFromDate.unixTimeStamp) &&
          ticketSelected.unlocked
        "
      >
        {{
          ticketSelected?.voucherType === "coupon"
            ? translate._("VALID_UNTIL", ticketSelected?.validToDate?.unixTimeStamp | date: "dd.MM.yy")
            : ""
        }}
      </p>
      <p
        class="coupon-card__content__description__validation"
        *ngIf="
          ticketSelected?.validFromDate &&
          ticketSelected?.validFromDate.unixTimeStamp &&
          (!ticketSelected.validToDate || !ticketSelected?.validToDate.unixTimeStamp) &&
          !ticketSelected.unlocked &&
          utils.lockedDueToDates(ticketSelected)
        "
      >
        {{ ticketSelected?.voucherType === "coupon" ? translate._("VALID_IN_DAYS", utils.getNumberOfDays(ticketSelected)) : "" }}
      </p>
      <p class="coupon-card__content__description__text" [class.--no-description]="!ticketSelected?.description">
        {{ ticketSelected?.description || translate._("NO_DESCRIPTION") }}
      </p>
    </div>
    <div class="coupon-card__content__button-wrapper">
      <p *ngIf="isCouponLocked && !isCouponRegisterMandatory" class="coupon-card__content__button-wrapper__text">
        {{ translate._("VOUCHER_DISABLED") }}
      </p>
      <p *ngIf="isCouponRegisterMandatory" class="coupon-card__content__button-wrapper__text">
        {{ translate._("ONLY_REGISTERED") }}
      </p>
      <ion-button
        *ngIf="ticketSelected?.voucherType === 'reward' || ticketSelected?.couponTypeCode === 'DISCOUNT'"
        class="button button--primary coupon-card__content__button-wrapper__button"
        expand="block"
        (click)="redeem(ticketSelected)"
        [disabled]="buttonDisabled || isCouponLocked || isCouponRegisterMandatory"
      >
        {{ translate._("REDEEM_NOW") }}
      </ion-button>
      <ion-button
        *ngIf="
          ticketSelected?.activation?.activatable &&
          (ticketSelected?.couponTypeCode === 'MULTIPLIER' || ticketSelected?.couponTypeCode === 'EXTRAPOINT')
        "
        [color]="ticketSelected?.activation?.activated ? 'secondary' : 'primary'"
        class="button button--primary coupon-card__content__button-wrapper__button"
        expand="block"
        (click)="activateEvent.emit(ticketSelected)"
        [disabled]="buttonDisabled || isCouponLocked || isCouponRegisterMandatory"
      >
        {{ ticketSelected?.activation?.activated ? translate._("DEACTIVE_COUPON") : translate._("ACTIVE_COUPON") }}
      </ion-button>
    </div>
  </div>
</div>

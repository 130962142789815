/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./information-modal.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./information-modal.page";
var styles_InformationModalPage = [i0.styles];
var RenderType_InformationModalPage = i1.ɵcrt({ encapsulation: 0, styles: styles_InformationModalPage, data: {} });
export { RenderType_InformationModalPage as RenderType_InformationModalPage };
export function View_InformationModalPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ion-content", [["class", "modal--inner infomodal"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["class", "infomodal--header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "modal--title infomodal--title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "div", [["class", "infomodal--content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, 0, 3, "div", [["class", "modal--footer infomodal--footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "ion-button", [["class", "button button--secondary"], ["color", "secondary"], ["expand", "block"], ["fill", "solid"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(10, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], expand: [1, "expand"], fill: [2, "fill"] }, null), (_l()(), i1.ɵted(11, 0, [" ", " "]))], function (_ck, _v) { var currVal_2 = "secondary"; var currVal_3 = "block"; var currVal_4 = "solid"; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.description; _ck(_v, 7, 0, currVal_1); var currVal_5 = _co.buttonText; _ck(_v, 11, 0, currVal_5); }); }
export function View_InformationModalPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "generic-information-modal", [], null, null, null, View_InformationModalPage_0, RenderType_InformationModalPage)), i1.ɵdid(1, 114688, null, 0, i4.InformationModalPage, [i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InformationModalPageNgFactory = i1.ɵccf("generic-information-modal", i4.InformationModalPage, View_InformationModalPage_Host_0, { title: "title", description: "description", buttonText: "buttonText" }, {}, []);
export { InformationModalPageNgFactory as InformationModalPageNgFactory };

<ion-app>
  <shared-connection-banner
    [isDeviceMounted]="isDeviceMounted"
    [connection]="connection"
    [socketConnection]="socketConnection"
    [loading]="loading"
    (reconnectSocket)="connectToSocket(true)"
  ></shared-connection-banner>
  <shared-header></shared-header>
  <ion-router-outlet></ion-router-outlet>
</ion-app>

import { Configuration } from "shared/lib/v2/apis/mounting-service";
import { HttpClient } from "@angular/common/http";
import { EnvironmentService } from "shared/lib/common/services";
import { DevicesService as SharedDevicesService } from "shared/lib/v2/apis/mounting-service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../apis/mounting-service/variables";
import * as i3 from "../../apis/mounting-service/configuration";
import * as i4 from "../../../common/services/environment/environment.service";
export class DevicesService extends SharedDevicesService {
    constructor(httpClient, basePath, configuration, environmentService) {
        super(httpClient, environmentService.getVariable("API_V2"), configuration);
        this.httpClient = httpClient;
        this.environmentService = environmentService;
    }
}
DevicesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DevicesService_Factory() { return new DevicesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.BASE_PATH, 8), i0.ɵɵinject(i3.Configuration, 8), i0.ɵɵinject(i4.EnvironmentService)); }, token: DevicesService, providedIn: "root" });

import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslateService, UtilsService, P4mService, RoutingService } from "shared/lib/common/services";
import { AdminService } from "shared/lib/v2/services";
import { SuccessPage as SharedSuccessPage } from "shared/lib/common/pages";
import { ConfigService } from "shared/lib/common/services";

@Component({
  selector: "shared-v2-success",
  templateUrl: "../../../common/pages/success/success.page.html",
  styleUrls: ["../../../common/pages/success/success.page.scss"],
})
export class SuccessPage extends SharedSuccessPage {
  constructor(
    private _routing: RoutingService,
    private _modal: ModalController,
    private _p4m: P4mService,
    private _admin: AdminService,
    private _config: ConfigService,
    public _utils: UtilsService,
    public _translate: TranslateService,
  ) {
    super(_routing, _modal, _p4m, _admin, _config, _utils, _translate);
  }
}

import { enableProdMode } from "@angular/core";
import "hammerjs";
import { environment } from "shared/lib/common/environments/environment";
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production)
    enableProdMode();
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .then(() => {
    if ("serviceWorker" in navigator && environment.production) {
        navigator.serviceWorker.register("/ngsw-worker.js");
    }
})
    .catch(err => console.log(err));

/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomProperty } from './custom-property';


export interface UserUpdateData { 
    birthDate?: string;
    city?: string;
    /**
     * Country Code (iso 3166-1 alpha-2)
     */
    countryCode?: string;
    customProperties?: Array<CustomProperty>;
    emailAddress: string;
    familyName?: string;
    genderCode?: UserUpdateData.GenderCodeEnum;
    givenName?: string;
    phone?: string;
    streetHouseNo?: string;
    zipCode?: string;
}
export namespace UserUpdateData {
    export type GenderCodeEnum = 'DIVERSE' | 'FEMALE' | 'MALE';
    export const GenderCodeEnum = {
        DIVERSE: 'DIVERSE' as GenderCodeEnum,
        FEMALE: 'FEMALE' as GenderCodeEnum,
        MALE: 'MALE' as GenderCodeEnum
    };
}



/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NotificationRequestData { 
    /**
     * id of the receiving the new notification
     */
    login: string;
    /**
     * Program id of the user\'s program
     */
    program: string;
    type: NotificationRequestData.TypeEnum;
}
export namespace NotificationRequestData {
    export type TypeEnum = 'NEW_REGISTRATION';
    export const TypeEnum = {
        NEWREGISTRATION: 'NEW_REGISTRATION' as TypeEnum
    };
}



var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as js2xmlparser from "js2xmlparser";
import { LOCAL_STORAGE, P4M } from "shared/lib/common/enums";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { SentryService } from "shared/lib/common/services/sentry/sentry.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { EnvironmentService } from "shared/lib/common/services/environment/environment.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../sentry/sentry.service";
import * as i3 from "../../config/config.service";
import * as i4 from "../../utils/utils.service";
import * as i5 from "../../environment/environment.service";
export class P4mV1Service {
    constructor(http, sentry, config, utils, environment) {
        this.http = http;
        this.sentry = sentry;
        this.config = config;
        this.utils = utils;
        this.environment = environment;
    }
    checkIn(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.CHECK_IN}?ac=${params.organization}&ak=${params.apiKey}&p=${JSON.stringify(Object.assign({}, params, { channelCode: "P", _transactionDateTime: new Date().getTime(), fromOfflineQueue: false, secondsSinceAction: 0 }))}`), "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    arts(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(`${this.environment.getVariable("API_V0")}/arts`, this.getArtsXml(params), {
                    headers: new HttpHeaders({
                        "Content-Type": "application/xml",
                        "API-Key": params.apiKey,
                    }),
                })
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    getUserDetails(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(`${this.getApiV1Url()}/${P4M.GET_USER_DETAILS}?ak=${params.apiKey}&p={clientId:${params.clientId};keyCode:${params.keyCode}}`, "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    getUserHistory(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(`${this.getApiV1Url()}/${P4M.GET_USER_HISTORY}?ak=${params.apiKey}&p={clientId:${params.clientId};keyCode:${params.keyCode}}`, "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    login(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.http
                    .post(`${this.environment.getVariable("API_V1")}/api/${P4M.LOGIN}`, {
                    email: params.email,
                    organization: params.organization,
                    password: params.password,
                }, {
                    responseType: "text",
                    headers: { "Content-Type": "application/json; charset=utf-8" },
                })
                    .toPromise();
                this.setToken(response);
                return { ok: true, response };
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    redeemCoupon(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(`${this.getApiV1Url()}/${P4M.REDEEM_COUPON}?ak=${params.apiKey}&p=${JSON.stringify(params)}`, "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    redeemReward(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(`${this.getApiV1Url()}/${P4M.REDEEM_REWARD}?ak=${params.apiKey}&p={keyCode:${params.keyCode};rewardId:${params.rewardId};clientId:${params.clientId}}`, "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    bookPoints(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const userDetailsResponse = yield this.getUserDetails({ keyCode: params.keyCode, apiKey: params.apiKey, clientId: params.clientId });
                if (userDetailsResponse.ok === true) {
                    return this.parseResponseV1(yield this.http
                        .post(`${this.getApiV1Url()}/${P4M.BOOK_POINTS}?ak=${params.apiKey}&p={userReferenceCode:${userDetailsResponse.response.userReferenceCode};totalRevenue:${params.points};clientId:${params.clientId}}`, "")
                        .toPromise());
                }
                else {
                    return userDetailsResponse;
                }
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    assignCoupon(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(`${this.environment.getVariable("API_V1")}/api/${P4M.ASSIGN_COUPONS}`, { couponId: params.couponId, keyCode: params.keyCode, clientId: params.clientId }, { headers: new HttpHeaders({ Authorization: params.token }) })
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    getCoupons(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(`${this.getApiV1Url()}/${P4M.GET_COUPONS}?ak=${params.apiKey}&p={clientId:${params.clientId}}`, "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    getCouponKeys(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.GET_COUPON_KEYS}?ac=${params.organization}&p={couponId:${params.couponId}}`), "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    getRewards(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(`${this.getApiV1Url()}/${P4M.GET_REWARDS}?ak=${params.apiKey}&p={clientId:${params.clientId}}`, "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    deviceManagementLogin(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.DEVICE_MANAGEMENT}?p=${JSON.stringify({
                    userName: params.userName,
                    password: params.password,
                })}&ac=${params.organization}`), "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    getAllClients(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.GET_ALL_CLIENTS}?ac=${params.organization}&ak=${params.apiKey}`), "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    getStoresOfClient(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.GET_STORES}?ac=${params.organization}&ak=${params.apiKey}&p=${JSON.stringify({
                    clientId: params.clientId,
                })}`), "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    downloadDisplayAds(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.DOWNLOAD_DISPLAY_ADS}?ac=${params.organization}&ak=${params.apiKey}&p=${JSON.stringify({
                    deviceKey: params.deviceKey,
                    withoutBase64: true,
                    displayAdIds: [],
                })}`), "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    mountDevice(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = this.parseResponseV1(yield this.http
                    .post(encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.MOUNT_DEVICE}?ac=${params.organization}&ak=${params.apiKey}&p=${JSON.stringify(params)}`), "")
                    .toPromise());
                if (response.ok === true)
                    this.sentry.addTag("device-id", String(response.response.deviceId));
                return response;
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    unMountDevice(params) {
        return __awaiter(this, void 0, void 0, function* () {
            this.sentry.removeTag("device-id");
            this.sentry.removeTag("email");
            try {
                return this.parseResponseV1(yield this.http
                    .post(encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.UN_MOUNT_DEVICE}?ac=${params.organization}&ak=${params.apiKey}&p=${JSON.stringify(params)}`), "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    loginToGetApiKey(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.LOGIN_APIKEY}?p=${JSON.stringify({
                    userName: params.userName,
                    password: params.password,
                    credentialTypeCode: "PM",
                    channelCode: "W",
                })}&ac=${params.organization}`), "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    getPermissionUser(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.GET_PERMISSION_USER}?p=${JSON.stringify({
                    clientId: params.clientId,
                })}&ac=${params.organization}`), "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    extendedRevenuePointsManually(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.EXTENDED_REVENUE_POINTS_MANUALLY}?p=${JSON.stringify({
                    language: params.language,
                    deviceKey: params.deviceKey,
                    keyCode: params.keyCode,
                    extendedRevenueFactsId: params.extendedRevenueFactsId,
                    channelCode: "P",
                    loaderText: "Punkte buchen",
                })}&ac=${params.organization}`), "")
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    getActionDefinitions(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.GET_ACTION_DEFINITIONS}`), null, {
                    params: {
                        ac: params.organization,
                        p: JSON.stringify({ deviceKey: params.deviceKey }),
                    },
                })
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    revenuePoints(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.REVENUE_POINTS}`), null, {
                    params: {
                        ac: params.organization,
                        p: JSON.stringify(params),
                    },
                })
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    register(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.REGISTER}`), null, {
                    params: {
                        ac: params.organization,
                        p: JSON.stringify(params).replace("+", "%2B"),
                    },
                })
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    updateProfileExtendedRegistration(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return this.parseResponseV1(yield this.http
                    .post(encodeURI(`${this.environment.getVariable("API_MOUNTING")}/api/device-management/pmm/${P4M.UPDATE_PROFILE_EXTENDED_REGISTRATION}`), null, {
                    params: {
                        ac: params.organization,
                        p: JSON.stringify(params),
                    },
                })
                    .toPromise());
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    readAccountBalance(params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const resp = yield this.getUserDetails({ apiKey: params.apiKey, clientId: params.clientId, keyCode: params.keyCode });
                if (resp.ok === true)
                    return this.parseBalance(this.parseResponseV1(resp.response));
                else
                    return resp;
            }
            catch (error) {
                return this.parseError(error);
            }
        });
    }
    getPassword() {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.PASSWORD));
    }
    setPassword(password) {
        localStorage.setItem(LOCAL_STORAGE.PASSWORD, this.utils.encrypt(password));
    }
    getUser() {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.EMAIL));
    }
    setUser(email) {
        localStorage.setItem(LOCAL_STORAGE.EMAIL, this.utils.encrypt(email));
    }
    getToken() {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.TOKEN));
    }
    setToken(token) {
        localStorage.setItem(LOCAL_STORAGE.TOKEN, this.utils.encrypt(token));
    }
    getArtsXml(params) {
        try {
            return js2xmlparser.parse("DigitalReceipt", {
                Transaction: {
                    "@": { MajorVersion: 6, MinorVersion: 0, FixVersion: 0, TypeCode: "SaleTransaction" },
                    ReceiptDateTime: params.date,
                    WorkstationID: params.workstationId,
                    ReceiptNumber: `${params.amount.toFixed(2)}_${params.vatId}_${this.utils.getReceiptFormatDate(new Date(params.date))}`,
                    RetailTransaction: {
                        Total: {
                            "@": {
                                TotalType: "TransactionGrandAmount",
                            },
                            "#": params.amount.toFixed(2),
                        },
                        LoyaltyAccount: { LoyaltyCard: { PrimaryAccountNumber: params.keyCode } },
                    },
                },
            });
        }
        catch (err) {
            this.sentry.handleError({ err, method: "getArtsXml" });
            return "";
        }
    }
    parseError(error) {
        if (error) {
            return {
                ok: false,
                error: {
                    message: error.message || error.error || error,
                    code: error.status || 0,
                },
            };
        }
        else {
            return {
                ok: false,
                error: {
                    message: "APP_UNHANDLED_ERROR",
                    code: 0,
                },
            };
        }
    }
    parseResponseV1(response) {
        if (response && response["errorInfo"]) {
            return {
                ok: false,
                error: { message: response["errorInfo"].errorCode },
            };
        }
        else {
            return { ok: true, response: response ? this.ensureValidTimestamp(response) : response };
        }
    }
    ensureValidTimestamp(obj) {
        Object.keys(obj).forEach(k => {
            if (obj[k] && typeof obj[k] === "object")
                return this.ensureValidTimestamp(obj[k]);
            if (k === "unixTimeStamp" && String(obj[k]).length === 10)
                obj[k] *= 1000;
        });
        return obj;
    }
    parseBalance(response) {
        if (response.ok === true) {
            return { ok: true, response: { points: response.response.totalPoints, lockedPoints: response.response.totalPointsCollected } };
        }
        else {
            return response;
        }
    }
    readUser(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return { ok: true, response: {} };
        });
    }
    getApiV1Url() {
        let resp = `${this.environment.getVariable("API_V0")}/api`;
        if (this.config.getMountingConfig().workstationId) {
            resp += `/workstationId=${this.config.getMountingConfig().workstationId}`;
        }
        return resp;
    }
}
P4mV1Service.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function P4mV1Service_Factory() { return new P4mV1Service(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SentryService), i0.ɵɵinject(i3.ConfigService), i0.ɵɵinject(i4.UtilsService), i0.ɵɵinject(i5.EnvironmentService)); }, token: P4mV1Service, providedIn: "root" });

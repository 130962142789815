import { ConfigService } from "shared/lib/common/services/config/config.service";
import { environment } from "shared/lib/common/environments/environment";
import { environmentVariables, org } from "shared/lib/common/environments/environment.constant";
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
export class EnvironmentService {
    constructor(config) {
        this.config = config;
    }
    getVariable(variable) {
        const organization = this.config.getOrganization();
        const variables = Object.assign({}, environment, environmentVariables[`${environment.BUILD_ENV}_${organization}`]);
        return organization === "pfm" ? variables[variable].replace(org, "") : variables[variable].replace(org, `${organization}.`);
    }
}
EnvironmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnvironmentService_Factory() { return new EnvironmentService(i0.ɵɵinject(i1.ConfigService)); }, token: EnvironmentService, providedIn: "root" });

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "shared-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  @Input() public loading: boolean;
  @Input() public disabled: boolean;
  @Input() public outline: false;
  @Input() public color: string;
  @Input() public fill: string;
  @Output() public clickEvent: EventEmitter<undefined> = new EventEmitter();
  constructor() {}

  public ngOnInit(): void {}

  public event(): void {
    this.clickEvent.emit();
  }
}

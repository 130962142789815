<ion-button
  (tap)="event()"
  class="button button-component"
  [ngClass]="{ '--outline': outline }"
  [disabled]="disabled || loading"
  color="{{ color }}"
  fill="{{ fill }}"
>
  <h6 *ngIf="!loading"><ng-content></ng-content></h6>
  <ion-spinner *ngIf="loading" class="button-component__spinner" name="dots"></ion-spinner>
</ion-button>

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "shared/lib/common/services";

@Component({
  selector: "shared-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
})
export class SelectComponent implements OnInit {
  @Input()
  public options: any[];
  @Input()
  public optionId: string;
  @Input()
  public optionDisplay: string;
  @Input()
  public value: string;
  @Input()
  public disabled: boolean;
  @Input()
  public error: boolean;
  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter();

  constructor(public translate: TranslateService) {}

  public ngOnInit(): void {}

  public select(value: any): void {
    this.valueChange.emit(value.target.value);
  }

  public trackId(index: number, object: any): number {
    if (object) {
      return object[this.optionId] || index;
    } else {
      return undefined;
    }
  }
}

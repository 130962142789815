<ion-content scroll-y="false">
  <div class="page-wrapper mounting">
    <div class="mounting--header">
      <h1 class="mounting--title">{{ translate._("MOUNTING_PROCESS") }}</h1>
      <shared-close class="mounting--close" (tap)="back()"></shared-close>
    </div>
    <div class="mounting--content-wrapper">
      <form class="mounting--content">
        <h2 class="mounting--content-title mounting--content-title__small">
          {{ translate._("CASH_CONFIGURATION") }}
        </h2>
        <div class="mounting--content-row">
          <div class="mounting--content-col">
            <h3 class="mounting--content-subtitle">1. {{ translate._("SELECT_YOUR_COMPANY") }}</h3>
            <shared-select
              [error]="invalidClient"
              [value]="client?.clientId"
              [options]="clients"
              [optionId]="'clientId'"
              [optionDisplay]="'clientName'"
              (valueChange)="setClient($event)"
            ></shared-select>
          </div>
        </div>
        <div class="mounting--content-row">
          <div class="mounting--content-col">
            <h3 class="mounting--content-subtitle">2. {{ translate._("SELECT_SHOP") }}</h3>
            <shared-select
              [disabled]="client === undefined"
              [error]="invalidStore"
              [options]="stores"
              [optionId]="'storeId'"
              [optionDisplay]="'storeName'"
              [value]="store?.storeId"
              (valueChange)="setStore($event)"
            ></shared-select>
          </div>
        </div>
        <div class="mounting--content-row">
          <div class="mounting--content-col">
            <h3 class="mounting--content-subtitle">3. {{ translate._("DEVICE_NAME") }}</h3>
            <input
              autocomplete="off"
              class="mounting--content-subtitle__description-input"
              placeholder="Cash register 1"
              [class.--has-error]="invaliddeviceInformation"
              [(ngModel)]="deviceInformation"
              name="deviceInformation"
              (change)="invaliddeviceInformation = false"
            />
          </div>
        </div>
        <div class="mounting--content-row">
          <div class="mounting--content-col">
            <h3 class="mounting--content-subtitle">4. {{ translate._("WORKSTATION_ID") }}</h3>
            <input
              autocomplete="off"
              class="mounting--content-subtitle__description-input"
              placeholder="192-2485-10"
              [(ngModel)]="workstationId"
              name="workstationId"
            />
          </div>
        </div>
        <h2 class="mounting--content-title">{{ translate._("DEVICE_CONFIGURATION") }}</h2>
        <h3 class="mounting--content-subtitle">
          {{ translate._("FRONT_CAMERA_POSITION_QUESTION") }}
        </h3>
        <ion-radio-group class="mounting--content-row --devices">
          <div class="mounting--content-col mounting--content-col__cam">
            <div class="mounting--radio-wrapper">
              <ion-radio
                (click)="setCameraPosition('TR')"
                class="mounting--cam-radio"
                [class.mounting--cam-img__error]="invalidCameraPosition"
                value="left"
              ></ion-radio>
              <div class="mounting--cam-img mounting--cam-img__left" style="background-image: url(/assets/cam-left.png);">
                <ion-img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPAQMAAADeVn0WAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAADUExURUdwTIL60tIAAAABdFJOUwBA5thmAAAADElEQVQI12NgIB8AAAA8AAE+8fENAAAAAElFTkSuQmCC"
                >
                </ion-img>
              </div>
            </div>
            <p class="mounting--cam-label">{{ translate._("LEFT") }}</p>
          </div>
          <div class="mounting--content-col mounting--content-col__cam">
            <div class="mounting--radio-wrapper">
              <ion-radio
                (click)="setCameraPosition('BC')"
                class="mounting--cam-radio"
                [class.mounting--cam-img__error]="invalidCameraPosition"
                value="bottom"
              ></ion-radio>
              <div
                class="mounting--cam-img mounting--cam-img__bottom"
                style="background-image: url(/assets/cam-top.png); background-position: bottom;"
              >
                <ion-img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPAQMAAADeVn0WAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAADUExURUdwTIL60tIAAAABdFJOUwBA5thmAAAADElEQVQI12NgIB8AAAA8AAE+8fENAAAAAElFTkSuQmCC"
                >
                </ion-img>
              </div>
            </div>
            <p class="mounting--cam-label">{{ translate._("TOP") }}</p>
          </div>
          <div class="mounting--content-col mounting--content-col__cam">
            <div class="mounting--radio-wrapper">
              <ion-radio
                (click)="setCameraPosition('RC')"
                class="mounting--cam-radio"
                [class.mounting--cam-img__error]="invalidCameraPosition"
                value="right"
              ></ion-radio>
              <div class="mounting--cam-img mounting--cam-img__right" style="background-image: url(/assets/cam-right.png);">
                <ion-img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPAQMAAADeVn0WAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAADUExURUdwTIL60tIAAAABdFJOUwBA5thmAAAADElEQVQI12NgIB8AAAA8AAE+8fENAAAAAElFTkSuQmCC"
                >
                </ion-img>
              </div>
            </div>
            <p class="mounting--cam-label">{{ translate._("RIGHT") }}</p>
          </div>
        </ion-radio-group>
        <div class="mounting--content-row --double">
          <div class="mounting--content-col">
            <h3 class="mounting--content-subtitle">{{ translate._("MODE_QUESTION") }}</h3>
            <shared-select [error]="invalidPosMode" [options]="posOptions" (valueChange)="setPosMode($event)"> </shared-select>
          </div>
          <div class="mounting--content-col">
            <h3 class="mounting--content-subtitle">
              {{ translate._("DEFAULT_LANGUAGE_QUESTION") }}
            </h3>
            <shared-select [error]="invalidLanguage" [options]="languageOptions" (valueChange)="setLanguage($event)"> </shared-select>
          </div>
        </div>
        <div class="mounting__content-footer">
          <shared-button
            class="mounting__content-footer__button"
            [color]="'primary'"
            [fill]="'solid'"
            [loading]="loading"
            (clickEvent)="mountDevice()"
          >
            {{ translate._("SAVE") }}
          </shared-button>
        </div>
      </form>
    </div>
  </div>
</ion-content>

import { HammerGestureConfig } from "@angular/platform-browser";
export class MyHammerGestureConfig extends HammerGestureConfig {
    constructor() {
        super(...arguments);
        this.overrides = {
            tap: { threshold: 70, posTreshold: 10, time: 2500 },
            pan: { threshold: 1000 },
            swipe: { threshold: 1000 },
        };
    }
}

/**
 * Convercus API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LinkedTransaction } from './linked-transaction';
import { LineItem } from './line-item';
import { Store } from './store';
import { TenderItem } from './tender-item';
import { Partner } from './partner';


export interface Transaction { 
    /**
     * If transaction is incentivated this will contain the accountId, in case of a merged transaction, this value would correspond the original accountId. Defaults to null if transaction is not incentivated.
     */
    accountId?: string;
    /**
     * Amount (of currency) that has been passed to this transaction
     */
    amount?: number;
    currencyCode?: string;
    /**
     * External reference to uniquely identify this transaction in external systems. E.g. bon_id or webshop_transactionID
     */
    externalId?: string;
    interactionId?: string;
    lineItems?: Array<LineItem>;
    linkedTransaction?: LinkedTransaction;
    /**
     * True if valuetime is in the future, otherwise false
     */
    locked?: boolean;
    partner?: Partner;
    /**
     * Amount (of currency) that has been passed to this transaction
     */
    pointRelevantAmount?: number;
    /**
     * Amount (of points) that has been booked by this transaction. For Earn transactions, this amount only includes basic points directly generated from revenue. This does not include any extra points.
     */
    points?: number;
    reason?: string;
    /**
     * The URI containing the image resource
     */
    receiptImageUrl?: string;
    store?: Store;
    taxRate?: number;
    tenderItems?: Array<TenderItem>;
    transactionId?: string;
    /**
     * Date and time when the transaction is created.
     */
    transactionTime?: string;
    transactionType?: Transaction.TransactionTypeEnum;
    /**
     * Value date of the transaction. Is in future for locked transactions.
     */
    valueTime?: string;
}
export namespace Transaction {
    export type TransactionTypeEnum = 'EARNTRANSACTION' | 'PAYWITHPOINTSTRANSACTION';
    export const TransactionTypeEnum = {
        EARNTRANSACTION: 'EARNTRANSACTION' as TransactionTypeEnum,
        PAYWITHPOINTSTRANSACTION: 'PAYWITHPOINTSTRANSACTION' as TransactionTypeEnum
    };
}



<ion-content class="modal--inner success page-wrapper">
  <div class="success__content">
    <h1 class="modal--title success__content__title">{{ title }}</h1>
    <i-check-circle class="success__content__check"></i-check-circle>
    <p *ngIf="!voucher || voucher['couponTypeCode'] !== 'DISCOUNT'" class="success__content__points">
      {{ points | number: "":translate.getSessionLanguage() }}
    </p>
    <span *ngIf="!voucher || voucher['couponTypeCode'] !== 'DISCOUNT'" class="success__content__text">{{ translate._("POINTS") }}</span>
    <p *ngIf="!loading && (!voucher || voucher['couponTypeCode'] !== 'DISCOUNT')" class="success__content__total-points">
      {{ totalPoints | number: "":translate.getSessionLanguage() }}
      <span class="success__content__total-points__text">{{ translate._("NEW_BALANCE") }}</span>
    </p>
    <ion-spinner *ngIf="loading" class="success__content__loading"></ion-spinner>
    <ion-button (tap)="success()" class="button button--primary success__content__button" expand="block">
      {{ translate._("CLOSE") }}
    </ion-button>
  </div>
</ion-content>

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LOCAL_STORAGE } from "shared/lib/common/enums";
import { LANGUAGE } from "shared/lib/common/i18n/translate";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import * as i0 from "@angular/core";
import * as i1 from "../utils/utils.service";
export class TranslateService {
    constructor(utils) {
        this.utils = utils;
        this.translations = LANGUAGE;
    }
    setLanguage(language) {
        localStorage.setItem(LOCAL_STORAGE.LANGUAGE, this.utils.encrypt(language));
    }
    getLanguage() {
        return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.LANGUAGE));
    }
    setSessionLanguage(sessionLanguage = this.getLanguage()) {
        this.sessionLanguage = sessionLanguage;
    }
    getSessionLanguage() {
        return this.sessionLanguage || this.getLanguage() || "en";
    }
    setTranslations(remoteTraslations = { en: {}, de: {} }) {
        return __awaiter(this, void 0, void 0, function* () {
            if (remoteTraslations) {
                this.translations = {
                    en: Object.assign({}, LANGUAGE.en, remoteTraslations.en),
                    de: Object.assign({}, LANGUAGE.de, remoteTraslations.de),
                };
            }
            else {
                this.translations = {
                    en: LANGUAGE.en,
                    de: LANGUAGE.de,
                };
            }
        });
    }
    _(key, ...args) {
        let translate = this.translations[this.getSessionLanguage()][key] || key;
        if (args && args.length > 0 && translate !== "") {
            args.forEach((argument, index) => {
                translate = translate.replace(`$${index}`, argument);
            });
        }
        return translate;
    }
}
TranslateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TranslateService_Factory() { return new TranslateService(i0.ɵɵinject(i1.UtilsService)); }, token: TranslateService, providedIn: "root" });

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./summary-footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-feather/angular-feather.ngfactory";
import * as i3 from "angular-feather";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i6 from "@ionic/angular";
import * as i7 from "./summary-footer.component";
import * as i8 from "../../../common/services/translate/translate.service";
var styles_SummaryFooterComponent = [i0.styles];
var RenderType_SummaryFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SummaryFooterComponent, data: {} });
export { RenderType_SummaryFooterComponent as RenderType_SummaryFooterComponent };
function View_SummaryFooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i-chevron-down", [["class", "summary-footer__down"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickEvent.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ɵbu_0, i2.RenderType_ɵbu)), i1.ɵdid(1, 49152, null, 0, i3.ɵbu, [], null, null)], null, null); }
function View_SummaryFooterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i-chevron-up", [["class", "summary-footer__up"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickEvent.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ɵbx_0, i2.RenderType_ɵbx)), i1.ɵdid(1, 49152, null, 0, i3.ɵbx, [], null, null)], null, null); }
export function View_SummaryFooterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "summary-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SummaryFooterComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SummaryFooterComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "summary-footer__info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickEvent.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h1", [["class", "summary-footer__info__money"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(8, 5), (_l()(), i1.ɵeld(9, 0, null, null, 0, "p", [["class", "summary-footer__info__points"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "ion-button", [["class", "summary-footer__button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.checkOutEvent.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_IonButton_0, i5.RenderType_IonButton)), i1.ɵdid(11, 49152, null, 0, i6.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], disabled: [1, "disabled"], fill: [2, "fill"] }, null), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, 0, 1, "ion-icon", [["name", "cart"]], null, null, null, i5.View_IonIcon_0, i5.RenderType_IonIcon)), i1.ɵdid(15, 49152, null, 0, i6.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.buttonDirection === "DOWN"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.buttonDirection === "UP"); _ck(_v, 5, 0, currVal_1); var currVal_4 = (!_co.revenue ? "text-disable" : "primary"); var currVal_5 = !_co.revenue; var currVal_6 = "solid"; _ck(_v, 11, 0, currVal_4, currVal_5, currVal_6); var currVal_8 = "cart"; _ck(_v, 15, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.revenue, "EUR", "symbol", (_co.decimalRevenue ? "1.2-2" : "1.0-0"), "de")); _ck(_v, 7, 0, currVal_2); var currVal_3 = ((_co.points + " ") + _co.t._("POINTS_TO_EXCHANGE")); _ck(_v, 9, 0, currVal_3); var currVal_7 = _co.t._("REVENUE_CHECK_OUT"); _ck(_v, 13, 0, currVal_7); }); }
export function View_SummaryFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "shared-v2-summary-footer", [], null, null, null, View_SummaryFooterComponent_0, RenderType_SummaryFooterComponent)), i1.ɵdid(1, 49152, null, 0, i7.SummaryFooterComponent, [i8.TranslateService], null, null)], null, null); }
var SummaryFooterComponentNgFactory = i1.ɵccf("shared-v2-summary-footer", i7.SummaryFooterComponent, View_SummaryFooterComponent_Host_0, { revenue: "revenue", points: "points", buttonDirection: "buttonDirection", decimalRevenue: "decimalRevenue" }, { clickEvent: "clickEvent", checkOutEvent: "checkOutEvent" }, []);
export { SummaryFooterComponentNgFactory as SummaryFooterComponentNgFactory };
